import dsStyles from "../styles/index.css";
import {isMac} from "./device-utils";

export const makeScrollable = (
  opts: {dir?: "vertical" | "horizontal" | "both"; mode?: "thin" | "wide"} = {}
) => {
  const {dir = "vertical", mode = "thin"} = opts;
  const onMac = isMac();
  const list: string[] = [];
  if (dir === "vertical") {
    list.push(dsStyles.overflowX.hidden, dsStyles.overflowY.auto);
  } else if (dir === "horizontal") {
    list.push(dsStyles.overflowY.hidden, dsStyles.overflowX.auto);
  } else {
    list.push(dsStyles.overflowY.auto, dsStyles.overflowX.auto);
  }
  if (!onMac) {
    list.push(dsStyles.prettyNonMacScrollBar["true"]);
    if (mode === "wide") list.push(dsStyles.prettyNonMacScrollBar.setWide);
  }
  return list.join(" ");
};
