import type {I18n} from "@lingui/core";
import {createRepo} from "../db/Repo";
import type {CategoryId} from "../features/categories/CategoryEntry";
import type {Nominal} from "../types";
import {createDateBasedIdWithHash} from "../utils";
import {DB_NAMES} from "./DbList";
import type {NoPrefixGroupId} from "./GroupEntry";

export type ItemId = Nominal<string, "ItemId">;

export type ItemEntry = {
  _id: ItemId;
  type: "Item";
  name: string;
  categoryId: CategoryId | null;
  quantity: number;
  tags: string[];
  tagHistory: string[];
  done: boolean;
  lastDoneAt: Date | null;
  createdAt: Date;
  custom: boolean;
  emoji: null | string;
  // sources "e.g. Schmonzi Wraps Recipe, Lasagne Recipe"
};

export type ItemRepoType = ReturnType<typeof createItemRepo>;

export const createItemRepo = (id: NoPrefixGroupId) =>
  createRepo<
    ItemEntry,
    {
      pending_item: {sortBy: ["done"]; isPresent: (al: any) => boolean};
      last_done_item: {sortBy: ["done", "lastDoneAt"]; isPresent: (al: any) => boolean};
    }
  >({
    dbInfo: DB_NAMES.Group(id),
    idPrefix: "itm:",
    type: "Item",
    withAutoId: true,
    generateId: () => createDateBasedIdWithHash<ItemId>(),
    hydrate: (fromDb) => {
      const {createdAt, _id, type, categoryId, lastDoneAt, ...rest} = fromDb;
      return {
        _id: _id as ItemId,
        type: "Item",
        createdAt: new Date(createdAt),
        lastDoneAt: lastDoneAt ? new Date(lastDoneAt) : null,
        categoryId: categoryId as CategoryId,
        ...rest,
      };
    },
    dehydrate: (entry) => {
      const {createdAt, lastDoneAt, ...rest} = entry;
      return {
        createdAt: createdAt.toISOString(),
        lastDoneAt: lastDoneAt && lastDoneAt.toISOString(),
        ...rest,
      };
    },
    views: {
      pending_item: {sortBy: ["done"], isPresent: (el) => !el.done},
      last_done_item: {sortBy: ["done", "lastDoneAt"], isPresent: (el) => el.done},
    },
    hooks: [],
  });

export const getItemName = (i: ItemEntry, i18n: I18n): string =>
  i.custom ? i.name : i18n.t({id: `item.${i.name}`});
