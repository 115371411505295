import {forwardRef, useState, useRef} from "react";
import type {ReactNode, ComponentProps} from "react";
import {Link} from "react-router-dom";
import cx from "../utils/cx";
import {
  activeInteractionTheme,
  errorInteractionTheme,
  successInteractionTheme,
} from "../styles/themes/color-themes.css";
import {addMessage} from "../features/messenger/messenger";
import {RawSpinner} from "../shared/ui/Spinner";
import {useMyTransition} from "../utils/use-my-transition";
import dsStyles from "../styles/index.css";
import {transitions} from "../styles/decoration.css";
import {buttonStyles as styles} from "./Button.css";
import {Col, StyleChild} from "./Box";
import Icon from "./Icon";

type ButtonStyles = typeof styles;
type ButtonState = "initial" | "loading" | "success" | "error";

const FadeIcon = ({
  icon,
  shown,
  withDelay,
}: {
  shown: boolean;
  icon: ReactNode;
  withDelay?: boolean;
}) => {
  const renderFn = useMyTransition(shown, shown, {timeoutMs: 500});
  return renderFn((present, leaving) =>
    present ? (
      <Col align="center" justify="center" absolute inset="0">
        <StyleChild
          applyTransforms
          setAnimDelay={withDelay ? "0.25s" : undefined}
          className={cx(
            transitions.transformAndColors,
            leaving ? styles.movedUp : styles.animations.fadeIn
          )}
        >
          {icon}
        </StyleChild>
      </Col>
    ) : null
  );
};

const usePending = (opts: Pick<FullProps<unknown>, "onClick">) => {
  const [state, setIsPending] = useState<ButtonState>("initial");
  const currClickRef = useRef({});
  const handleClick: React.MouseEventHandler<HTMLElement> = (e) => {
    const clickFn = opts.onClick;
    if (!clickFn) return;
    const res = clickFn(e as any) as any as Promise<unknown>;
    if (typeof res?.then === "function") {
      const currClickObj = (currClickRef.current = {});
      setIsPending("loading");
      const setter = (val: ButtonState) => {
        if (currClickRef.current === currClickObj) setIsPending(val);
      };
      res.then(
        () => {
          setter("success");
          setTimeout(() => {
            setter("initial");
          }, 500);
        },
        (error: any) => {
          setter("error");
          setTimeout(() => {
            setter("initial");
          }, 2000);
          addMessage(error, {type: "error"});
        }
      );
    }
  };
  return {
    handleClick,
    state,
    icons: (
      <>
        <FadeIcon shown={state === "loading"} icon={<RawSpinner size="20" />} withDelay />
        <FadeIcon shown={state === "success"} icon={<Icon name="check" size="20" />} />
        <FadeIcon shown={state === "error"} icon={<Icon name="close" size="20" />} />
      </>
    ),
  };
};

type FullProps<T> =
  | (Omit<JSX.IntrinsicElements["button"], keyof T> & T & {href?: undefined; to?: undefined})
  | (Omit<JSX.IntrinsicElements["a"], keyof T> &
      T & {href: string; to?: undefined; disabled?: boolean})
  | (Omit<ComponentProps<typeof Link>, keyof T> &
      T & {href?: undefined; to: ComponentProps<typeof Link>["to"]; disabled?: boolean});

type SelfRawButtonProps = {
  variant?: keyof ButtonStyles["variant"];
  size?: keyof ButtonStyles["size"];
  // contentPadding?: null | "both" | "left" | "right";
  // iconColor?: null | "primary" | "secondary";
  active?: boolean;
  state?: ButtonState;
  // negatePadding?: boolean;
  // onMetaClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const RawButton = forwardRef<HTMLElement, FullProps<SelfRawButtonProps>>((props, ref) => {
  const {className, disabled, size = "md", variant = "secondary", active, state, ...rest} = props;
  const getVariant = (): keyof ButtonStyles["variant"] => {
    if (active || state === "error" || state === "success") return "primary";
    return variant;
  };
  const getTheme = () => {
    if (state === "error") return errorInteractionTheme;
    if (state === "success") return successInteractionTheme;
    if (active) return activeInteractionTheme;
  };
  const classes = cx(
    styles.base,
    styles.size[size],
    styles.variant[getVariant()],
    (rest.to || rest.href) && styles.isLink,
    getTheme(),
    disabled && dsStyles.opacity["50%"],
    className
  );
  if (rest.to) {
    return (
      <Link
        className={classes}
        {...(rest as any)}
        {...(disabled ? {onClick: (e) => e.preventDefault()} : {})}
        ref={ref as any}
      />
    );
  } else if (rest.href) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a className={classes} target="_blank" rel="noopener noreferrer" {...rest} ref={ref as any} />
    );
  } else {
    return (
      <button
        className={classes}
        type="button"
        disabled={disabled || state === "loading"}
        {...(rest as any)}
        ref={ref}
      />
    );
  }
});

type SelfButtonProps = Omit<SelfRawButtonProps, never>;

export const Button = forwardRef<HTMLElement, FullProps<SelfButtonProps>>((props, ref) => {
  const {onClick, children, ...rest} = props;
  const {handleClick, icons, state} = usePending({
    onClick: props.onClick,
  });
  return (
    <RawButton state={state} {...rest} onClick={handleClick} ref={ref}>
      {icons}
      <div
        className={cx(
          dsStyles.applyTransforms.true,
          transitions.transformAndColors,
          state !== "initial" && styles.movedDown
        )}
      >
        {children}
      </div>
    </RawButton>
  );
});

export default Button;

export const FloatingButton = forwardRef<HTMLElement, FullProps<SelfButtonProps>>((props, ref) => {
  return (
    <Col absolute inset="0" pointerEvents="none" justify="end" align="end" pr="16">
      <StyleChild position="sticky" bottom="16px" pointerEvents="auto">
        <Button variant="primary" size="floating" {...props} ref={ref} />
      </StyleChild>
    </Col>
  );
});
