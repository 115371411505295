import {useVirtualizer} from "@tanstack/react-virtual";
import type {MutableRefObject} from "react";
import {useEffect, useLayoutEffect, useRef} from "react";
import {useDrag, useDragStore, useDrop} from "./use-dnd";

const useMakeSpaceForDragItem = (opts: {
  key: number | string;
  index: number;
  startPx: number;
  nodeRef: MutableRefObject<HTMLElement | null>;
  offsetRef: MutableRefObject<number>;
}) => {
  const {nodeRef, offsetRef} = opts;
  const optsRef = useRef(opts);
  useLayoutEffect(() => {
    optsRef.current = opts;
  });
  useLayoutEffect(() => {
    let hasMoved = false;
    return useDragStore.subscribe(
      ({dragInfo}) => dragInfo,
      (dragInfo) => {
        // meh code, logic for setting/resetting style.transtion could be improved
        const getNextOffset = () => {
          if (!dragInfo) return 0;
          const {key, index} = optsRef.current;
          const {currentIndex, startIndex, gapRect} = dragInfo;
          if (dragInfo.key === key || currentIndex === null) return 0;
          if (currentIndex <= index && startIndex > index) {
            return gapRect.height;
          } else if (currentIndex >= index && startIndex < index) {
            return -gapRect.height;
          }
          return 0;
        };
        const node = nodeRef.current!;
        if (!dragInfo && hasMoved) {
          hasMoved = false;
          setTimeout(() => {
            node.style.transition = "";
          });
        }
        const nextOffset = getNextOffset();
        if (nextOffset === offsetRef.current) return;
        offsetRef.current = nextOffset;
        if (dragInfo && !hasMoved) {
          hasMoved = true;
          node.style.transition = "transform 0.2s ease-in-out";
        }
        node.style.transform = `translateY(${optsRef.current.startPx + offsetRef.current}px)`;
      },
      {fireImmediately: true}
    );
  }, [nodeRef, offsetRef]);
};

type DndItemProps = {
  nodeRef: MutableRefObject<HTMLDivElement | null>;
  startPx: number;
  index: number;
  key: string | number;
};

export const useDndItem = (props: DndItemProps) => {
  const {nodeRef, startPx, key, index} = props;
  const {handlers} = useDrag({
    key,
    index,
    direction: "vertical",
    nodeRef,
    startPx,
  });
  const offsetRef = useRef(0);
  useMakeSpaceForDragItem({key, index, nodeRef, offsetRef, startPx});
  return {
    handlers,
    transform: `translateY(${startPx + offsetRef.current}px)`,
  };
};

const useModifyStyles = (props: Pick<DndListProps, "getScrollElement">) => {
  const propsRef = useRef(props);
  useEffect(() => {
    propsRef.current = props;
  });
  useEffect(() => {
    let prevStyles: {overflow: string; overscroll: string} | null = null;
    const unsub = useDragStore.subscribe(
      (s) => Boolean(s.dragInfo),
      (isDragging) => {
        const parentNode = propsRef.current.getScrollElement();
        if (isDragging) {
          // TODO: only on touch
          if (parentNode) {
            prevStyles = {
              overflow: parentNode.style.overflow,
              overscroll: document.body.style.overscrollBehavior,
            };
            parentNode.style.overflow = "hidden";
            document.body.style.overscrollBehavior = "contain";
          }
        } else {
          if (prevStyles && parentNode) {
            parentNode.style.overflow = prevStyles.overflow;
            document.body.style.overscrollBehavior = prevStyles.overscroll;
            prevStyles = null;
          }
        }
      }
    );
    return () => {
      if (prevStyles) {
        const parentNode = propsRef.current.getScrollElement();
        if (parentNode) {
          parentNode.style.overflow = prevStyles.overflow;
          document.body.style.overscrollBehavior = prevStyles.overscroll;
        }
      }
      unsub();
    };
  }, []);
};

type DndListProps = {
  count: number;
  getScrollElement: () => HTMLElement | null;
  height: number;
  gap: number;
  onDrop: (opts: {startIndex: number; targetIndex: number; key: string | number}) => void;
};

export const useDndList = (props: DndListProps) => {
  const {count, getScrollElement, height, gap, onDrop} = props;
  const rowHeight = height + gap;

  const dragItemIdxRef = useRef<null | number>(null);

  // HINT: calls onChange on initial render, which causes a second render :(
  const rows = useVirtualizer({
    count,
    getScrollElement,
    estimateSize: () => rowHeight,
    rangeExtractor: (range) => {
      const start = Math.max(range.startIndex - range.overscan, 0);
      const end = Math.min(range.endIndex + range.overscan, range.count - 1);

      const arr: number[] = [];

      for (let i = start; i <= end; i++) arr.push(i);

      let dragIdx = dragItemIdxRef.current;
      if (dragIdx !== null && (dragIdx < start || dragIdx > end)) {
        arr.push(dragIdx);
      }

      return arr;
    },
  });

  useModifyStyles({getScrollElement});

  useDrop({
    getElement: () => getScrollElement()!,
    onModeChange: (mode, key, index) => {
      dragItemIdxRef.current = mode !== null ? index : null;
    },
    getTargetIdx: ({pos, node}) => {
      // FIXME: consider gap as well?
      const val = Math.round((pos.y + node.scrollTop - node.offsetTop) / rowHeight - 0.5);
      return Math.max(0, Math.min(count - 1, val));
    },
    onDrop,
  });

  return {
    height: rows.getTotalSize() - gap,
    virtualItems: rows.getVirtualItems(),
  };
};
