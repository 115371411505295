import {createRepo} from "../db/Repo";
import type {Nominal} from "../types";
import {DB_NAMES} from "./DbList";
import type {NoPrefixGroupId} from "./GroupEntry";
import type {NoPrefixUserId} from "./UserRepo";

export type GroupMemberId = Nominal<string, "GroupMemberId">;

export type GroupMemberEntry = {
  _id: GroupMemberId;
  userId: NoPrefixUserId;
  groupId: NoPrefixGroupId;
  type: "GroupMember";
  memberName: string;
  createdAt: Date;
};

export type GroupMemberRepoType = ReturnType<typeof createGroupMemberRepo>;

export const createGroupMemberRepo = (id: NoPrefixGroupId) =>
  createRepo<GroupMemberEntry>({
    dbInfo: DB_NAMES.Group(id),
    idPrefix: "grpMemb:",
    type: "GroupMember",
    withAutoId: true,
    generateId: ({userId}) => `${userId}` as GroupMemberId,
    hydrate: (fromDb) => {
      const {createdAt, _id, type, userId, groupId, ...rest} = fromDb;
      return {
        _id: _id as GroupMemberId,
        userId: userId as NoPrefixUserId,
        groupId: groupId as NoPrefixGroupId,
        type: "GroupMember",
        createdAt: new Date(createdAt),
        ...rest,
      };
    },
    dehydrate: (entry) => {
      const {createdAt, ...rest} = entry;
      return {createdAt: createdAt.toISOString(), ...rest};
    },
    views: {},
  });

export const getGroupMemberId = (userId: NoPrefixUserId) => `grpMemb:${userId}` as GroupMemberId;
