import {t} from "@lingui/macro";
import {Trans} from "@lingui/react";
import {Link} from "react-router-dom";
import {Box, Col, css, Row} from "../../ui/Box";
import type {GroupEntry} from "../../models/GroupEntry";

type TabKey = "list" | "cook";
type TabInfo = {key: TabKey; icon: null; route: string};

// only for extracting keys, will be dead-code eliminated
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const labels = [t({id: "nav.list"}), t({id: "nav.cook"})];

const tabList: TabInfo[] = [
  {
    key: "list",
    icon: null,
    route: "",
  },
  {
    key: "cook",
    icon: null,
    route: "/cook",
  },
];

type TabProps = {info: TabInfo; shortId: string; current: boolean};
const Tab = ({info, current, shortId}: TabProps) => {
  const {key, route} = info;
  return (
    <Link
      to={`/g/${shortId}${route}`}
      className={css({
        display: "flex",
        flexDir: "column",
        flex: "auto",
        align: "center",
        px: "4",
        py: "12",
      })}
    >
      <Box
        textAlign="center"
        size="12"
        textTransform="uppercase"
        bold
        relative
        color={current ? "primary" : "secondary"}
      >
        <Trans id={`nav.${key}`}>{key}</Trans>
        {current && (
          <Col absolute top="100%" left="0" right="0" align="center" pt="8">
            <Box style={{width: 12, height: 12}} rounded="full" bg="brand" />
          </Col>
        )}
      </Box>
    </Link>
  );
};

type MainNavProps = {
  current: TabKey | null;
  group: GroupEntry;
};
const GroupFooterNav = ({current, group}: MainNavProps) => {
  return (
    <Row surface elevation="100" relative overflow="hidden">
      {tabList.map((info) => (
        <Tab key={info.key} info={info} current={current === info.key} shortId={group.shortId} />
      ))}
    </Row>
  );
};

export default GroupFooterNav;
