import {createContext, useContext} from "react";
import type {NoPrefixUserId} from "./UserRepo";
import type {NoPrefixGroupId} from "./GroupEntry";

const UserRemoteDbContext = createContext<string | null>(null);
export const ProvideUserRemoteDb = UserRemoteDbContext.Provider;

export const GroupRemoteDbContext = createContext<string | null>(null);
export const ProvideGroupRemoteDb = GroupRemoteDbContext.Provider;

export type SchemaVersion =
  | "user:2023-12-22"
  | "local:2023-12-22"
  | "group:2023-12-22"
  | "local:2023-12-23"
  | "group:2023-12-23"
  | "group:2024-01-02";
export type CurrentSchemaVersion = "user:2023-12-22" | "local:2023-12-23" | "group:2024-01-02";

export type DbInfo = {
  dbName: string;
  useRemoteDb: () => string | null;
  schemaVersion: CurrentSchemaVersion;
  type: "User" | "Local" | "Group";
};

export type DbType = DbInfo["type"];

export const useIsUserSynced = () => Boolean(useContext(UserRemoteDbContext));

export const DB_NAMES = {
  User: (id: NoPrefixUserId) => ({
    dbName: `user-${id}`,
    useRemoteDb: () => useContext(UserRemoteDbContext),
    schemaVersion: "user:2023-12-22",
    type: "User",
  }),
  Local: (id: NoPrefixUserId) => ({
    dbName: `local-${id}`,
    useRemoteDb: () => null,
    schemaVersion: "local:2023-12-23",
    type: "Local",
  }),
  Group: (id: NoPrefixGroupId) => ({
    dbName: `group-${id}`,
    useRemoteDb: () => useContext(GroupRemoteDbContext),
    schemaVersion: "group:2024-01-02",
    type: "Group",
  }),
} satisfies {[name: string]: (id: any) => DbInfo};
