import {useState, type ReactNode} from "react";
import {useLingui} from "@lingui/react";
import {Trans} from "@lingui/macro";
import AppShell from "../../shared/ui/AppShell";
import {Col, Row, Text} from "../../ui/Box";
import Header from "../../shared/ui/Header";
import ThemePicker from "../../ThemePicker";
import {useSetLang} from "../../i18n";
import Button from "../../ui/Button";
import {useLogout} from "../../WithAuth";
import UserSyncSection from "../../features/auth/UserSyncSection";
import Spinner from "../../shared/ui/Spinner";
import {StorageKey, storageWrapper} from "../../storage";

const Block = ({children, title}: {children: ReactNode; title: ReactNode}) => (
  <Col rounded="4" surface pa="16" sp="12">
    <Text as="h2" size="20">
      {title}
    </Text>
    {children}
  </Col>
);

const langs = [
  {key: "de", flag: "🇩🇪"},
  {key: "en", flag: "🇺🇸"},
] as const;

const LangPicker = () => {
  const [pending, setLang] = useSetLang();
  const {i18n} = useLingui();
  return (
    <Col sp="8">
      <Row sp="4">
        <Text as="h3" color="secondary" size="14_tight" weight="bold">
          <Trans>Language</Trans> {pending && <Spinner size="20" inline />}
        </Text>
      </Row>
      <Row sp="4">
        {langs.map(({key, flag}) => (
          <Button key={key} onClick={() => setLang(key)} active={i18n.locale === key}>
            {flag}
          </Button>
        ))}
      </Row>
    </Col>
  );
};

const PillCols = () => {
  const [value, setValue] = useState(storageWrapper.storageGet(StorageKey.PILL_SPACING) || "wide");
  const handleChange = (nextCols: "compact" | "wide") => {
    setValue(nextCols);
    storageWrapper.storageSet(StorageKey.PILL_SPACING, nextCols);
  };
  return (
    <Col sp="8">
      <Row sp="4">
        <Text as="h3" color="secondary" size="14_tight" weight="bold">
          <Trans>Item Spacing</Trans>
        </Text>
      </Row>
      <Row sp="24">
        <Row as="label" sp="4">
          <input
            type="radio"
            name="pillSpacing"
            value="compact"
            checked={value === "compact"}
            onChange={() => handleChange("compact")}
          />
          <Text as="h3" color="primary" size="14_tight" weight="bold">
            <Trans>Compact</Trans>
          </Text>
        </Row>
        <Row as="label" sp="4">
          <input
            type="radio"
            name="pillSpacing"
            value="wide"
            checked={value === "wide"}
            onChange={() => handleChange("wide")}
          />
          <Text as="h3" color="primary" size="14_tight">
            <Trans>Wide</Trans>
          </Text>
        </Row>
      </Row>
    </Col>
  );
};

const Settings = () => (
  <Block title={<Trans>Settings</Trans>}>
    <Col sp="8">
      <Text as="h3" color="secondary" size="14_tight" weight="bold">
        <Trans>Theme</Trans>
      </Text>
      <ThemePicker />
    </Col>
    <LangPicker />
    <PillCols />
  </Block>
);

const LogoutButton = () => {
  const logout = useLogout();
  return <Button onClick={logout}>log out</Button>;
};

const Auth = () => (
  <Block title={<Trans>Your user account</Trans>}>
    <UserSyncSection />
    <div>
      <LogoutButton />
    </div>
  </Block>
);

const Profile = () => {
  return (
    <AppShell header={<Header currentGroupId={null} title={<Trans>Profile</Trans>} />}>
      <Col px="12" sp="24" py="12">
        <Settings />
        <Auth />
      </Col>
    </AppShell>
  );
};

export default Profile;
