import AppShell from "../../shared/ui/AppShell";
import GroupFooterNav from "../../shared/ui/GroupFooterNav";
import {Col} from "../../ui/Box";
import Header from "../../shared/ui/Header";
import type {GroupId} from "../../models/GroupEntry";
import {useGroupRepos} from "../../models/GroupRepos";

const GroupRecipes = () => {
  const {GroupRepo, groupId} = useGroupRepos();
  const group = GroupRepo.useGetById(`grp:${groupId}` as GroupId);

  if (!group) return <div>{404}</div>;
  return (
    <AppShell
      header={<Header currentGroupId={groupId} title="Cook" />}
      footer={<GroupFooterNav current="cook" group={group} />}
    >
      <Col px="12" sp="24" py="24">
        COOK
      </Col>
    </AppShell>
  );
};

export default GroupRecipes;
