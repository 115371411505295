import PouchDb from "pouchdb-browser";
import {i18n} from "@lingui/core";
import * as v from "valibot";
import {authServerRequest} from "../../authServerRequest";
import type {NoPrefixUserId, UserEntry, UserId} from "../../models/UserRepo";
import {getUserRepos} from "../../models/UserRepo";
import {getRandomLowerCaseOnlyId} from "../../utils";
import type {Lang} from "../../i18n";
import type {PouchWrap} from "../../db/Repo";
import {getLocalRepos} from "../../models/LocalRepo";
import {getGroupRepos} from "../../models/GroupRepos";
import {getGroupMemberId} from "../../models/GroupMemberRepo";
import {useForm} from "../form/Form";
import {TextField} from "../form/fields";
import {css} from "../../ui/Box";
import {Trans} from "@lingui/macro";

type SignUpData = {
  name: string;
  email: string;
  password: string;
};

const signupFormSchema = v.object({
  name: v.pipe(v.string(), v.nonEmpty()),
  email: v.pipe(v.string(), v.nonEmpty(), v.email()),
  password: v.pipe(v.string(), v.nonEmpty(), v.minLength(8)),
});

export const createRemoteUserDb = async (
  data: {userId: NoPrefixUserId} & SignUpData
): Promise<{dbName: string}> => {
  const {dbName} = await authServerRequest({
    path: "/create-user",
    data,
  });
  return {dbName};
};

const SignUpForm = ({setUserId}: {setUserId: (val: null | NoPrefixUserId) => void}) => {
  const {field, Form} = useForm({
    initial: {name: "", email: "", password: ""},
    schema: signupFormSchema,
    onSubmit: async ({name, email, password}) => {
      const userId = getRandomLowerCaseOnlyId(24) as NoPrefixUserId;
      const {dbName} = await createRemoteUserDb({email, password, userId, name});
      const {MeRepo} = getUserRepos(userId);
      await MeRepo.create(`user:${userId}` as UserId, {
        createdAt: new Date(),
        name,
        remoteDbName: dbName,
        lang: (i18n.locale as Lang) || "en",
      });
      await PouchDb.replicate(
        getUserRepos(userId).MeRepo.getDb(),
        `${import.meta.env.VITE_COUCHDB_HOST}/${dbName}`
      ).on("error", function (err: any) {
        throw new Error(err);
      });
      setUserId(userId);
    },
  });
  return (
    <Form
      buttonLabel={<Trans>Create account</Trans>}
      className={css({sp: "16", display: "flex", flexDir: "column"})}
    >
      <TextField field={field("name")} label={<Trans>Name</Trans>} />
      <TextField field={field("email")} label={<Trans>Email</Trans>} />
      <TextField type="password" field={field("password")} label={<Trans>Password</Trans>} />
    </Form>
  );
};

export const SyncForm = ({
  userId,
  me,
  onDone,
}: {
  userId: NoPrefixUserId;
  me: PouchWrap<UserEntry>;
  onDone?: () => Promise<unknown>;
}) => {
  const {MeRepo} = getUserRepos(userId);
  const {LocalGroupPreviewRepo} = getLocalRepos(userId);
  const {field, Form} = useForm({
    initial: {name: "", email: "", password: ""},
    schema: signupFormSchema,
    onSubmit: async ({name, email, password}) => {
      const {dbName} = await createRemoteUserDb({email, password, userId, name});
      const previews = await LocalGroupPreviewRepo.getAllPromise();
      for (const group of previews) {
        const memberId = getGroupMemberId(userId);
        const {GroupMemberRepo} = getGroupRepos(group.groupId);
        const meMember = await GroupMemberRepo.getByIdPromise(memberId);
        if (meMember) {
          await GroupMemberRepo.update({...meMember, memberName: name});
        }
      }
      await MeRepo.update({...me, remoteDbName: dbName});
      if (onDone) await onDone();
    },
  });
  return (
    <Form
      buttonLabel={<Trans>Create account</Trans>}
      className={css({sp: "16", display: "flex", flexDir: "column"})}
    >
      <TextField field={field("name")} label={<Trans>Name</Trans>} />
      <TextField field={field("email")} label={<Trans>Email</Trans>} />
      <TextField type="password" field={field("password")} label={<Trans>Password</Trans>} />
    </Form>
  );
};

export default SignUpForm;
