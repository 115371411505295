import * as ToastPrimitive from "@radix-ui/react-toast";
import type {ReactNode} from "react";
import {create} from "zustand";
import type {Nominal} from "../../types";
import {messengerStyles} from "./messenger.css";

type MessageKey = Nominal<number, "Message">;
type Message = {
  content: ReactNode;
  type: "info" | "error";
  durationMs: number;
  key: MessageKey;
  open: boolean;
};

type Store = {
  messageMap: {map: Map<MessageKey, Message>};
  addMessage: (m: Message) => void;
  removeMessage: (key: MessageKey) => void;
};
const useMessageStore = create<Store>((set, get) => ({
  messageMap: {map: new Map()},
  addMessage: (message: Message) => {
    const {map} = get().messageMap;
    map.set(message.key, message);
    set({messageMap: {map}});
  },
  removeMessage: (key: MessageKey) => {
    const {map} = get().messageMap;
    map.delete(key);
    set({messageMap: {map}});
  },
}));

const MAX_SHOWN = 5;

export const ShowMessages = () => {
  const {map: rawMessages} = useMessageStore((s) => s.messageMap);
  const messages = [...rawMessages.values()].slice(-MAX_SHOWN);
  return (
    <ToastPrimitive.Provider swipeDirection="right">
      <ToastPrimitive.Viewport className={messengerStyles.viewport} />
      {messages.map((m) => (
        <ToastPrimitive.Root
          key={m.key}
          duration={m.durationMs}
          onOpenChange={(open) => {
            if (!open) setTimeout(() => useMessageStore.getState().removeMessage(m.key), 200);
            if (open !== m.open) {
              m.open = open;
              useMessageStore.setState({messageMap: {map: rawMessages}});
            }
          }}
          className={messengerStyles.root}
          open={m.open}
        >
          <ToastPrimitive.Description className={messengerStyles.description}>
            {m.content}
          </ToastPrimitive.Description>
          <ToastPrimitive.Close>Dismiss</ToastPrimitive.Close>
        </ToastPrimitive.Root>
      ))}
    </ToastPrimitive.Provider>
  );
};

let nextKey = 0;
export const addMessage = (
  content: Message["content"],
  opts?: Partial<Omit<Message, "content" | "key">>
) => {
  const m: Message = {
    type: "info",
    durationMs: 2000,
    ...opts,
    content,
    key: (nextKey += 1) as MessageKey,
    open: true,
  };
  useMessageStore.getState().addMessage(m);
};
