import {Suspense, useEffect, useMemo, useRef, useState} from "react";
import uFuzzy from "@leeoniya/ufuzzy";
import {useLingui} from "@lingui/react";
import type {I18n} from "@lingui/core";
import {useNavigate} from "react-router";
import {useGroupRepos} from "../../models/GroupRepos";
import {Box, Col, Row} from "../../ui/Box";
import {getItemName} from "../../models/ItemEntry";
import type {ItemEntry, ItemId, ItemRepoType} from "../../models/ItemEntry";
import type {PouchWrap} from "../../db/Repo";
import Icon from "../../ui/Icon";
import {Input} from "../../ui/Input";
import Button from "../../ui/Button";
import dsStyles from "../../styles/index.css";
import Spinner from "../../shared/ui/Spinner";
import RefineItem from "./RefineItem";
import {AddItemPill, CreateItemPill, ModifyPill} from "./ItemPill";

const useSuggestions = <T extends any>(
  searchVal: string,
  list: T[],
  getSearchTerm: (el: T) => string
) => {
  const [uf] = useState(new uFuzzy());
  const refs = useRef({getSearchTerm});
  useEffect(() => {
    refs.current = {getSearchTerm};
  });
  const haystack = useMemo(() => list.map(refs.current.getSearchTerm), [list]);

  const idxs = uf.filter(haystack, searchVal);
  if (!idxs || idxs?.length === 0) return [];
  // idxs can be null when the needle is non-searchable (has no alpha-numeric chars)
  // sort/rank only when <= 1,000 items
  const infoThreshold = 1e3;

  if (idxs.length <= infoThreshold) {
    const info = uf.info(idxs, haystack, searchVal);
    // order is a double-indirection array (a re-order of the passed-in idxs)
    // this allows corresponding info to be grabbed directly by idx, if needed
    const order = uf.sort(info, haystack, searchVal);
    // using info.idx here instead of idxs because uf.info() may have
    // further reduced the initial idxs based on prefix/suffix rules
    return order.map((i) => list[info.idx[i]]);
  } else {
    // render pre-filtered but unordered matches
    return idxs.map((i) => list[i]);
  }
};

const ShowItem = ({
  item,
  i18n,
  onSelected,
  ItemRepo,
}: {
  item: PouchWrap<ItemEntry>;
  i18n: I18n;
  onSelected: (item: ItemEntry) => void;
  ItemRepo: ItemRepoType;
}) => {
  const name = getItemName(item, i18n);
  if (item.done) {
    return (
      <AddItemPill
        item={item}
        ItemRepo={ItemRepo}
        onDone={onSelected}
        name={name}
        confirmAnimation="added"
      />
    );
  } else {
    return <ModifyPill item={item} name={name} ItemRepo={ItemRepo} confirmAnimation="added" />;
  }
};

type SuggestionsProps = {
  searchVal: string;
  onSelected: (item: ItemEntry, opts: {isNew: boolean}) => void;
};
const Suggestions = ({searchVal, onSelected}: SuggestionsProps) => {
  const {ItemRepo} = useGroupRepos();
  const {i18n} = useLingui();
  const items = ItemRepo.useGetAll();
  const rawSuggs = useSuggestions(searchVal.trimStart(), items, (i) => getItemName(i, i18n));
  const shownSuggs = !searchVal ? items : rawSuggs;
  const suggs = shownSuggs.length > 15 ? shownSuggs.slice(0, 15) : shownSuggs;

  const shouldShowAddEl = () => {
    if (!searchVal.trim()) return false;
    const firstRes = suggs[0];
    if (!firstRes) return true;
    return searchVal.trim().toLowerCase() !== getItemName(firstRes, i18n).toLowerCase();
  };

  return (
    <Box overflow="auto" flex="auto">
      <Col sp="8">
        {suggs.map((item) => (
          <ShowItem
            key={item._id}
            item={item}
            i18n={i18n}
            onSelected={(selItem) => onSelected(selItem, {isNew: false})}
            ItemRepo={ItemRepo}
          />
        ))}
        {shouldShowAddEl() && (
          <CreateItemPill
            name={searchVal}
            onDone={(item) => onSelected(item, {isNew: true})}
            ItemRepo={ItemRepo}
            confirmAnimation="added"
          />
        )}
      </Col>
    </Box>
  );
};

const AddItem = () => {
  const [searchVal, setSearchVal] = useState("");
  const [refineItemId, setRefineItemId] = useState<ItemId | null>(null);
  const [justAdded, setJustAdded] = useState<null | "new" | "existing">(null);
  const searchFieldRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const handleInput = (e: string) => {
    if (refineItemId) setRefineItemId(null);
    setSearchVal(e);
  };

  const handleSelected = (item: ItemEntry, {isNew}: {isNew: boolean}) => {
    setSearchVal("");
    searchFieldRef.current?.focus();
    setJustAdded(isNew ? "new" : "existing");
    setRefineItemId(item._id);
  };

  const i18n = useLingui().i18n;
  const onClose = () => navigate("..");

  return (
    <Col
      sp="24"
      height="_addItemDrawerHeight"
      maxWidth="formWidth"
      width="100%"
      mr="auto"
      ml="auto"
    >
      <Row sp="8">
        <Input
          type="text"
          value={searchVal}
          onChange={handleInput}
          placeholder={
            justAdded
              ? i18n.t({id: "input.addNextItemPlaceholder"})
              : i18n.t({id: "input.addItemPlaceholder"})
          }
          ref={searchFieldRef}
          className={dsStyles.flex.auto}
          autoFocus
        />
        <Button onClick={onClose} variant="tertiary">
          <Icon name="close" />
        </Button>
      </Row>
      <Suspense fallback={<Spinner size="48" />}>
        {refineItemId ? (
          <RefineItem id={refineItemId} justAdded={justAdded} onClose={onClose} />
        ) : (
          <Suggestions searchVal={searchVal} onSelected={handleSelected} />
        )}
      </Suspense>
    </Col>
  );
};

export default AddItem;
