import type {CSSProperties} from "react";
import spriteUrl from "../assets/_generated/sprite.svg";
import cx from "../utils/cx";
import {iconStyles} from "./Icon.css";
export type IconName =
  | "chevron-left"
  | "clock"
  | "check"
  | "moon"
  | "sun"
  | "close"
  | "vert-ellipsis"
  | "sort"
  | "cloud"
  | "plus"
  | "minus"
  | "menu";

type IconProps = {
  name: IconName;
  size?: keyof typeof iconStyles.size;
  inline?: boolean;
  className?: string;
  style?: CSSProperties;
};
const Icon = (props: IconProps) => {
  const {name, size = "20", inline, className, style} = props;
  const symbolId = `${spriteUrl}#${name}`;

  return (
    <svg
      aria-hidden="true"
      className={cx(
        className,
        iconStyles.base,
        iconStyles.stroke,
        iconStyles.size[size],
        iconStyles.mode[inline ? "inline" : "block"]
      )}
      style={style}
    >
      <use href={symbolId} />
    </svg>
  );
};

export default Icon;
