import type {ReactNode} from "react";
import {createContext, useCallback, useContext, useMemo, useState} from "react";
import {useOutlet} from "react-router";
import {addMessage} from "./features/messenger/messenger";
import {ProvideUserRemoteDb} from "./models/DbList";
import type {UserId, NoPrefixUserId} from "./models/UserRepo";
import {useUserRepos, getUserRepos} from "./models/UserRepo";
import LoginForm from "./features/auth/LoginForm";
import {StorageKey, storageWrapper} from "./storage";
import Button from "./ui/Button";
import {getRandomLowerCaseOnlyId} from "./utils";
import AppShell from "./shared/ui/AppShell";
import {Col, Text} from "./ui/Box";

const WithRemoteDb = ({children, userId}: {children: ReactNode; userId: NoPrefixUserId}) => {
  const me = useUserRepos().MeRepo.useGetById(`user:${userId}` as UserId);
  return <ProvideUserRemoteDb value={me?.remoteDbName ?? null}>{children}</ProvideUserRemoteDb>;
};

export const getUserId = () => {
  return storageWrapper.storageGet(StorageKey.USER_ID) as NoPrefixUserId | null;
};

export const useManageUserId = (): [
  null | NoPrefixUserId,
  (val: null | NoPrefixUserId) => void,
] => {
  const [userId, rawSetUserId] = useState<null | NoPrefixUserId>(getUserId);
  const setUserId: (val: null | NoPrefixUserId) => void = useCallback((val) => {
    rawSetUserId(val);
    if (val) {
      storageWrapper.storageSet(StorageKey.USER_ID, val);
    } else {
      storageWrapper.storageRemove(StorageKey.USER_ID);
    }
  }, []);
  return [userId, setUserId];
};

const Authenticate = ({setUserId}: {setUserId: (val: null | NoPrefixUserId) => void}) => {
  const handleOfflineAccount = async () => {
    const userId = getRandomLowerCaseOnlyId(24) as NoPrefixUserId;
    const {MeRepo} = getUserRepos(userId);
    await MeRepo.create(`user:${userId}` as UserId, {
      createdAt: new Date(),
      name: null,
      remoteDbName: null,
      lang: "en",
    });
    setUserId(userId);
  };

  return (
    <AppShell>
      <Col bg="background" px="12" py="24" align="center" sp="48">
        <Text as="h2" size="20">
          Let's get you started
        </Text>
        <Button onClick={handleOfflineAccount} variant="primary">
          Create new local account
        </Button>
        <Col rounded="4" surface pa="16" sp="12" width="100%" maxWidth="formWidth">
          <Text as="h2" size="20" textAlign="center">
            ... or login
          </Text>
          <LoginForm setUserId={setUserId} />
        </Col>
      </Col>
    </AppShell>
  );
};

type UserContextType = {userId: NoPrefixUserId; logout: () => void};
const UserContext = createContext<UserContextType>(null as unknown as UserContextType);
export const useUserId = () => useContext(UserContext).userId;
export const useLogout = () => useContext(UserContext).logout;

type WithUserIdProps = {
  userId: NoPrefixUserId;
  setUserId: (val: null | NoPrefixUserId) => void;
  children?: ReactNode;
};
export const WithUserId = ({userId, setUserId, children}: WithUserIdProps) => {
  const ctxVal = useMemo(
    () => ({
      userId,
      logout: () => {
        setUserId(null);
        addMessage("Successfully logged out");
      },
    }),
    [userId, setUserId]
  );
  const outlet = useOutlet();
  return (
    <UserContext.Provider value={ctxVal}>
      <WithRemoteDb userId={userId}>{children || outlet || "No page found"}</WithRemoteDb>
    </UserContext.Provider>
  );
};

const WithAuth = () => {
  const [userId, setUserId] = useManageUserId();
  if (!userId) {
    return <Authenticate setUserId={setUserId} />;
  } else {
    return <WithUserId userId={userId} setUserId={setUserId} />;
  }
};

export default WithAuth;
