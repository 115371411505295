import {Suspense, useState, type ReactNode} from "react";
import {useLingui} from "@lingui/react";
import type {I18n} from "@lingui/core";
import {t, Trans} from "@lingui/macro";
import * as v from "valibot";
import {useGroupRepos} from "../../models/GroupRepos";
import {Box, Col, css, Row, StyleChild, Text} from "../../ui/Box";
import {getItemName} from "../../models/ItemEntry";
import type {ItemEntry, ItemId, ItemRepoType} from "../../models/ItemEntry";
import Icon from "../../ui/Icon";
import Button from "../../ui/Button";
import dsStyles from "../../styles/index.css";
import type {PouchWrap} from "../../db/Repo";
import Spinner from "../../shared/ui/Spinner";
import type {CategoryId} from "../categories/CategoryEntry";
import {getCategoryName} from "../categories/CategoryEntry";
import {useForm} from "../form/Form";
import {SelectField, TextField} from "../form/fields";
import {greenTheme} from "../../styles/themes/color-themes.css";
import {isEmoji} from "../../utils";
import {addMessage} from "../messenger/messenger";

const Block = ({children, title}: {children: ReactNode; title: ReactNode}) => (
  <Col
    rounded="4"
    borderWidth={1}
    borderColor="default"
    pa="16"
    sp="12"
    width="100%"
    maxWidth="formWidth"
  >
    <Text as="h3" size="16">
      {title}
    </Text>
    {children}
  </Col>
);

const ManageTags = ({item, ItemRepo}: {item: PouchWrap<ItemEntry>; ItemRepo: ItemRepoType}) => {
  const {Form, field, reset} = useForm({
    initial: {tag: ""},
    schema: tagFormSchema,
    onSubmit: async ({tag}) => {
      const nextHistory = [tag, ...item!.tagHistory.filter((th) => th !== tag)];
      await ItemRepo.update({...item!, tags: [...item!.tags, tag], tagHistory: nextHistory});
      reset();
    },
  });

  const handleAddTag = async (tag: string) => {
    const nextHistory = [tag, ...item.tagHistory.filter((th) => th !== tag)];
    await ItemRepo.update({...item, tags: [...item.tags, tag], tagHistory: nextHistory});
  };

  const handleRemoveTag = async (tag: string) => {
    await ItemRepo.update({...item, tags: item.tags.filter((th) => th !== tag)});
  };

  const presentTagSet = new Set([...item.tags]);
  const shownHistory = item.tagHistory.filter((th) => !presentTagSet.has(th));

  return (
    <Block title={<Trans>Tags</Trans>}>
      <Row wrap sp="4">
        {item.tags.length === 0 && item.tagHistory.length === 0 ? (
          <Box color="secondary">None</Box>
        ) : (
          <>
            {item.tags.map((tag) => (
              <Button key={tag} active onClick={() => handleRemoveTag(tag)}>
                {tag}
              </Button>
            ))}
            {shownHistory.map((tag) => (
              <Button key={tag} onClick={() => handleAddTag(tag)}>
                {tag}
              </Button>
            ))}
          </>
        )}
      </Row>
      <StyleChild display="flex" align="end">
        <Form buttonLabel="Add">
          <TextField label={null} field={field("tag")} placeholder="Add new tag" />
        </Form>
      </StyleChild>
    </Block>
  );
};

const updatePropertiesFormSchema = v.intersect([
  v.object({
    categoryId: v.nullable(
      v.pipe(
        v.string(),
        v.transform((val) => (val !== null ? (val as CategoryId) : null))
      )
    ),
    emoji: v.pipe(v.string(), v.check(isEmoji, "no_emoji")),
  }),
  v.variant("isCustom", [
    v.object({
      isCustom: v.literal(true),
      itemName: v.pipe(v.string(), v.nonEmpty(), v.maxLength(256)),
    }),
    v.object({
      isCustom: v.literal(false),
      itemName: v.pipe(v.string(), v.empty()),
    }),
  ]),
]);

type UpdatePropertiesProps = {
  item: PouchWrap<ItemEntry>;
  ItemRepo: ItemRepoType;
  i18n: I18n;
  onClose: () => void;
};
const UpdateProperties = ({item, i18n, ItemRepo, onClose}: UpdatePropertiesProps) => {
  const {Form, field} = useForm({
    initial: {
      categoryId: item.categoryId,
      isCustom: item.custom,
      itemName: item.custom ? item.name : "",
      emoji: item.emoji || "",
    },
    submitOnBlur: true,
    schema: updatePropertiesFormSchema,
    onSubmit: async ({categoryId, isCustom, itemName, emoji}) => {
      if (isCustom) {
        await ItemRepo.update({...item, categoryId, name: itemName, emoji: emoji || null});
      } else {
        await ItemRepo.update({...item, categoryId, emoji: emoji || null});
      }
    },
  });

  const {CategoryRepo} = useGroupRepos();
  const cats = CategoryRepo.useGetView("sort_index");

  const [showDangerZone, setShowDangerZone] = useState(false);

  return (
    <Block title={<Trans>Update Properties</Trans>}>
      <Form>
        {item.custom && (
          <TextField label="Name" field={field("itemName")} placeholder="Item name" />
        )}
        <TextField field={field("emoji")} label={<Trans>Emoji</Trans>} />
        <SelectField
          label="Category"
          field={field("categoryId")}
          options={[
            {label: "None", value: null},
            ...cats.map((c) => ({
              label: `${c.emoji} ${getCategoryName(c, i18n)}`,
              value: c._id,
            })),
          ]}
        />
      </Form>
      <Button
        onClick={() => setShowDangerZone(!showDangerZone)}
        className={css({alignSelf: "start"})}
        active={showDangerZone}
      >
        <Trans>Danger Zone</Trans>
      </Button>
      {showDangerZone && (
        <DangerZone item={item} ItemRepo={ItemRepo} onClose={onClose} i18n={i18n} />
      )}
    </Block>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _message = [t({id: "item.deleted"})];

const DangerZone = ({
  item,
  ItemRepo,
  onClose,
  i18n,
}: {
  item: PouchWrap<ItemEntry>;
  ItemRepo: ItemRepoType;
  onClose: () => void;
  i18n: I18n;
}) => {
  const handleDelete = async () => {
    await ItemRepo.delete(item);
    addMessage(i18n._("item.deleted", {itemName: getItemName(item, i18n)}));
    onClose();
  };
  return (
    <Button onClick={handleDelete}>
      <Trans>Delete Item</Trans>
    </Button>
  );
};

const tagFormSchema = v.object({
  tag: v.pipe(v.string(), v.nonEmpty(), v.maxLength(32)),
});

type RefineItemProps = {id: ItemId; justAdded?: null | "new" | "existing"; onClose: () => void};
const RefineItem = (props: RefineItemProps) => {
  const {id, justAdded, onClose} = props;
  const {ItemRepo} = useGroupRepos();
  const item = ItemRepo.useGetById(id);
  const {i18n} = useLingui();

  if (!item) return <div>No such item</div>;

  const handleDec = async () => {
    if (item.quantity < 2) {
      await ItemRepo.update({...item, quantity: 0, done: true});
    } else {
      await ItemRepo.update({...item, quantity: item.quantity - 1});
    }
  };
  const handleInc = async () => {
    await ItemRepo.update({...item, done: false, quantity: item.quantity + 1});
  };

  return (
    <Col sp="16" align="center">
      {justAdded ? (
        <>
          <Row className={greenTheme} bg="surface" rounded="4" pa="8" sp="8" align="center">
            <Icon name="check" className={css({color: "secondary"})} />
            <Box color="primary" size="12">
              {getItemName(item, i18n)} was added
            </Box>
          </Row>
          <Text as="h2" size="20">
            <Trans>Refine it</Trans>
          </Text>
        </>
      ) : (
        <Text as="h2" size="20">
          <Trans>Refine {getItemName(item, i18n)}</Trans>
        </Text>
      )}
      <Row
        rounded="4"
        borderWidth={1}
        borderColor="default"
        pa="16"
        sp="8"
        align="center"
        width="100%"
        maxWidth="formWidth"
      >
        <Text as="h3" size="16">
          <Trans>Quantity</Trans>: {item.quantity}
        </Text>
        <Button
          variant="primary"
          size="md"
          onClick={handleDec}
          className={dsStyles.ml.auto}
          disabled={item.done}
        >
          <Icon name="minus" />
        </Button>
        <Button variant="primary" size="md" onClick={handleInc}>
          <Icon name="plus" />
        </Button>
      </Row>
      <ManageTags item={item} ItemRepo={ItemRepo} />
      {justAdded !== "existing" && (
        <Suspense fallback={<Spinner size="48" />}>
          <UpdateProperties item={item} ItemRepo={ItemRepo} i18n={i18n} onClose={onClose} />
        </Suspense>
      )}
    </Col>
  );
};

export default RefineItem;
