import {Link} from "react-router-dom";
import {Trans} from "@lingui/macro";
import {Box, Col, css, Text} from "../../ui/Box";
import type {NoPrefixGroupId} from "../../models/GroupEntry";
import {createLocalGroup} from "../../models/GroupEntry";
import {useLocalRepos} from "../../models/LocalRepo";
import {useMe, useUserRepos} from "../../models/UserRepo";
import type {PouchWrap} from "../../db/Repo";
import type {GroupPreviewEntry, GroupPreviewRepoType} from "../../models/GroupPreviewEntry";
import Button from "../../ui/Button";
import Icon from "../../ui/Icon";
import {greenTheme} from "../../styles/themes/color-themes.css";

const Group = ({group, active}: {group: PouchWrap<GroupPreviewEntry>; active: boolean}) => (
  <Link
    to={`/g/${group.shortId}`}
    className={css(
      {
        display: "flex",
        sp: "4",
        align: "baseline",
        rounded: "4",
        bg: "surface",
        pa: "16",
      },
      active && greenTheme
    )}
  >
    <Box color="primary" textTransform="uppercase" size="16" bold>
      {group.name}
    </Box>
    {group.remoteDbName && <Icon name="cloud" inline />}
    <Text color="secondary" size="14_tight">
      {group.createdAt.toDateString()}
    </Text>
  </Link>
);
const CreateLocalGroup = ({GroupPreviewRepo}: {GroupPreviewRepo: GroupPreviewRepoType}) => {
  const {me, userId} = useMe();
  const handleClick = () =>
    createLocalGroup({
      GroupPreviewRepo,
      userId,
      userName: me.name || "Anon",
      groupName: "Hello",
    });
  return <Button onClick={handleClick}>New Group</Button>;
};

type MainNavProps = {
  currentGroupId: NoPrefixGroupId | null;
};
const MainMenu = ({currentGroupId}: MainNavProps) => {
  const {LocalGroupPreviewRepo} = useLocalRepos();
  const {SharedGroupPreviewRepo} = useUserRepos();
  const localGroups = LocalGroupPreviewRepo.useGetAll();
  const sharedGroups = SharedGroupPreviewRepo.useGetAll();

  const allGroups = [...sharedGroups, ...localGroups];
  return (
    <Col sp="32" flex="auto">
      {allGroups.map((group) => (
        <Group key={group._id} group={group} active={group.groupId === currentGroupId} />
      ))}
      <div>
        <CreateLocalGroup GroupPreviewRepo={LocalGroupPreviewRepo} />
      </div>
      <Col mt="auto" sp="12">
        <Button to="/profile">
          <Trans id="nav.profile">Profile</Trans>
        </Button>
        <Box size="12" color="secondary" style={{opacity: 0.25}} textAlign="center">
          {__APP_VERSION__}
        </Box>
      </Col>
    </Col>
  );
};

export default MainMenu;
