import type {SchemaVersion} from "./models/DbList";
import type {NoPrefixGroupId} from "./models/GroupEntry";

type StorageWrapper = {
  storageGet: <K extends StorageKey>(key: K) => StorageMap[K] | null;
  storageSet: <K extends StorageKey>(key: K, item: StorageMap[K]) => boolean;
  storageRemove: (key: StorageKey) => boolean;
};

export const enum StorageKey {
  LANG = "liste3000:lang",
  USER_ID = "liste3000:userId",
  THEME = "liste3000:theme", // also referenced in index.html
  PILL_SPACING = "liste3000:pillSpacing",
  LAST_GROUP_ID = "liste3000:lastGroupId",

  _SCHEMA_VERSION = "liste3000:dbSchemaVersion:X",
}

type StorageMap = {
  [StorageKey.LANG]: "de" | "en";
  [StorageKey.USER_ID]: string;
  [StorageKey.THEME]: null | "dark" | "light";
  [StorageKey.PILL_SPACING]: "compact" | "wide";
  [StorageKey.LAST_GROUP_ID]: NoPrefixGroupId | null;

  [StorageKey._SCHEMA_VERSION]: SchemaVersion | null;
};

export const getStorageKey = {
  dbSchema: (dbName: string) => `liste3000:dbSchemaVersion:${dbName}` as StorageKey._SCHEMA_VERSION,
};

const getStorage = (storageGetter: () => Storage): StorageWrapper => {
  let storage: Storage;
  try {
    storage = storageGetter();
  } catch {
    return {
      storageGet() {
        return null;
      },
      storageSet() {
        return false;
      },
      storageRemove() {
        return false;
      },
    };
  }

  return {
    storageGet(key) {
      try {
        const content = storage.getItem(key);
        return content ? JSON.parse(content) : null;
      } catch (e) {
        return null;
      }
    },

    storageSet(key, val) {
      try {
        storage.setItem(key, JSON.stringify(val));
        return true;
      } catch (e) {
        return false;
      }
    },

    storageRemove(key) {
      try {
        storage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    },
  };
};

export const storageWrapper = getStorage(() => window.localStorage);
