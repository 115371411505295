import {useEffect, useState} from "react";
import cx from "../../utils/cx";
import {decorationStyles, transitions} from "../../styles/decoration.css";
import dsStyles from "../../styles/index.css";
import {iconStyles, spinnerStyles} from "../../ui/Icon.css";

type Size = keyof (typeof iconStyles)["size"];

const speedBySize: {
  [K in Size]: keyof (typeof decorationStyles)["animation"]["animSpeed"];
} = {
  "16": "0.75s",
  "20": "0.75s",
  "24": "1s",
  "48": "2s",
};

type SpinnerProps = {className?: string; size: Size; inline?: boolean};

export const RawSpinner = ({className, size, inline}: SpinnerProps) => {
  const [id] = useState(() => Math.random().toString());
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={cx(
        className,
        size && iconStyles.size[size],
        iconStyles.base,
        iconStyles.mode[inline ? "inline" : "block"]
      )}
    >
      <path
        d="M12 21a9 9 0 1 1 0-18 9 9 0 0 1 0 18Zm0-16.02a7.02 7.02 0 1 0 0 14.04 7.02 7.02 0 0 0 0-14.04Z"
        className={spinnerStyles.bg}
      />
      <path
        d="M12 4c0-.55.44-1 1-.94a9 9 0 0 1 7.96 7.96c.06.55-.4.99-.94.99-.55 0-.98-.45-1.06-1a7.02 7.02 0 0 0-5.96-5.96c-.54-.08-1-.51-1-1.06Z"
        fill={`url(#spinner-${id})`}
        className={cx(
          decorationStyles.animation.spin,
          decorationStyles.animation.animSpeed[speedBySize[size]]
        )}
      />
      <defs>
        <linearGradient
          id={`spinner-${id}`}
          x1="3"
          y1="12"
          x2="21"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".6" stopOpacity="0" className={spinnerStyles.gradientStop} />
          <stop offset="1" className={spinnerStyles.gradientStop} />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Spinner = (props: SpinnerProps) => {
  const [className, setClassName] = useState(dsStyles.opacity[0]);
  useEffect(() => {
    let id = setTimeout(() => {
      setClassName(dsStyles.opacity[1]);
    }, 250);
    return () => clearTimeout(id);
  }, []);
  return (
    <RawSpinner
      {...props}
      className={cx(className, transitions.duration["0.5s"], props.className)}
    />
  );
};

export default Spinner;
