import {errorToString} from "./utils";

type Args = {
  path: string;
  method?: RequestInit["method"];
  data?: any;
};
export const authServerRequest = async ({path, method = "POST", data}: Args) => {
  const response = await fetch(`${import.meta.env.VITE_AUTH_SERVER_HOST}${path}`, {
    method,
    mode: "cors",
    credentials: "include",
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...(data && {"Content-Type": "application/json"}),
    },
  });
  if (response.status >= 400) {
    const res = await response.json();
    const err = new Error(errorToString(res));
    (err as any).status = response.status;
    throw err;
  }
  return response.json();
};
