import type {ReactNode} from "react";
import {useState} from "react";
import {StorageKey, storageWrapper} from "./storage";
import {Row} from "./ui/Box";
import Icon from "./ui/Icon";
import Button from "./ui/Button";

type Theme = null | "dark" | "light";

const htmlElClasses = document.documentElement.classList;
const lightClass = "light";
const darkClass = "dark";
const metaEl = document.querySelector("meta[name='color-scheme']");

const setMetaEl = (nextTheme: Theme) => {
  if (!metaEl) return;
  const getVal = () => {
    switch (nextTheme) {
      case "dark":
      case "light":
        return nextTheme;
      case null:
        return "light dark";
    }
  };
  metaEl.setAttribute("content", getVal());
};

let theme = storageWrapper.storageGet(StorageKey.THEME);
setMetaEl(theme);

const setTheme = (nextTheme: Theme) => {
  storageWrapper.storageSet(StorageKey.THEME, nextTheme);
  setMetaEl(nextTheme);
  switch (nextTheme) {
    case "dark": {
      htmlElClasses.remove(lightClass);
      htmlElClasses.add(darkClass);
      break;
    }
    case "light": {
      htmlElClasses.add(lightClass);
      htmlElClasses.remove(darkClass);
      break;
    }
    case null: {
      htmlElClasses.remove(lightClass);
      htmlElClasses.remove(darkClass);
      break;
    }
  }
};

const themeInfo = {
  null: {label: "⏱️", next: "dark", key: null},
  dark: {label: "🌙", next: "light", key: "dark"},
  light: {label: "☀️", next: null, key: "light"},
} satisfies {[K in Exclude<Theme, null> | "null"]: {label: string; next: Theme; key: Theme}};

export const MiniThemePicker = () => {
  const [info, setInfo] = useState(themeInfo[theme || "null"]);
  const handleClick = () => {
    let nextTheme: Theme = info.next;
    setTheme(nextTheme);
    setInfo(themeInfo[nextTheme || "null"]);
  };
  return <Button onClick={handleClick}>{info.label}</Button>;
};

const themes: {key: Theme; icon: ReactNode}[] = [
  {
    key: null,
    icon: <Icon name="clock" />,
  },
  {
    key: "dark",
    icon: <Icon name="moon" />,
  },
  {
    key: "light",
    icon: <Icon name="sun" />,
  },
];

const ThemePicker = () => {
  const [currTheme, setCurrTheme] = useState(theme);
  const handleClick = (t: Theme) => {
    setTheme(t);
    setCurrTheme(t);
  };
  return (
    <Row sp="4">
      {themes.map(({key, icon}) => (
        <Button key={key} onClick={() => handleClick(key)} active={key === currTheme}>
          {icon}
        </Button>
      ))}
    </Row>
  );
};

export default ThemePicker;
