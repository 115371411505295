import {getUserId} from "../WithAuth";
import {authServerRequest} from "../authServerRequest";
import type {PouchWrap} from "../db/Repo";
import {createRepo} from "../db/Repo";
import type {HookCb} from "../db/db-hooks";
import type {Nominal} from "../types";
import {createDateBasedIdWithHash, getRandomId} from "../utils";
import {DB_NAMES} from "./DbList";
import type {GroupPreviewRepoType} from "./GroupPreviewEntry";
import {getGroupRepos} from "./GroupRepos";
import {getLocalRepos} from "./LocalRepo";
import type {NoPrefixUserId} from "./UserRepo";
import {getUserRepos} from "./UserRepo";
import {fillInitialItems} from "./initial-group-data";

export type NoPrefixGroupId = Nominal<string, "NoPrefixGroupId">;
export type GroupId = Nominal<string, "GroupId">;

export type GroupEntry = {
  _id: GroupId;
  type: "Group";
  shortId: string;
  name: string;
  remoteDbName: string | null;
  createdAt: Date;
};

export type GroupRepoType = ReturnType<typeof createGroupRepo>;

export const createGroupRepo = (id: NoPrefixGroupId) =>
  createRepo<GroupEntry>({
    dbInfo: DB_NAMES.Group(id),
    idPrefix: "grp:",
    type: "Group",
    withAutoId: false,
    hydrate: (fromDb) => {
      const {createdAt, _id, type, ...rest} = fromDb;
      return {_id: _id as GroupId, type: "Group", createdAt: new Date(createdAt), ...rest};
    },
    dehydrate: (entry) => {
      const {createdAt, ...rest} = entry;
      return {createdAt: createdAt.toISOString(), ...rest};
    },
    views: {},
    hooks: getHooks(),
  });

export const createLocalGroup = async (args: {
  userId: NoPrefixUserId;
  userName: string;
  groupName: string;
  GroupPreviewRepo: GroupPreviewRepoType;
}) => {
  const {userId, userName, groupName, GroupPreviewRepo} = args;
  const groupId = createDateBasedIdWithHash<NoPrefixGroupId>();
  const shortId = getRandomId(8);
  const {GroupRepo, GroupMemberRepo, CategoryRepo, ItemRepo} = getGroupRepos(groupId);
  const group = await GroupRepo.create(`grp:${groupId}` as GroupId, {
    name: groupName,
    createdAt: new Date(),
    remoteDbName: null,
    shortId,
  });
  await GroupPreviewRepo.create({
    name: group.name,
    createdAt: group.createdAt,
    shortId: group.shortId,
    groupId,
    remoteDbName: group.remoteDbName,
  });
  await GroupMemberRepo.create({
    createdAt: new Date(),
    groupId,
    userId,
    memberName: userName,
  });
  await fillInitialItems(CategoryRepo, ItemRepo);
};

const getPreviewRepo = (group: GroupEntry): GroupPreviewRepoType => {
  const userId = getUserId();
  if (!userId) throw new Error("Needs user id");
  if (group.remoteDbName) {
    return getUserRepos(userId).SharedGroupPreviewRepo;
  } else {
    return getLocalRepos(userId).LocalGroupPreviewRepo;
  }
};

const getPreviewGroupAndRepo = async (group: GroupEntry) => {
  const Repo = getPreviewRepo(group);
  const list = await Repo.getAllPromise();
  const preview = list.find((p) => p.shortId === group.shortId);
  if (!preview) return null;
  return {groupPreview: preview, Repo};
};

const getHooks = (): HookCb<GroupEntry>[] => {
  return [
    async ({prevDoc, doc}) => {
      if (!prevDoc) return;

      // RENAME
      if (prevDoc.name !== doc.name) {
        const res = await getPreviewGroupAndRepo(doc);
        if (!res) return;
        await res.Repo.update({...res.groupPreview, name: doc.name});
      }

      // SET remoteDb
      if (!prevDoc.remoteDbName && doc.remoteDbName) {
        const prevRes = await getPreviewGroupAndRepo(prevDoc);
        const userId = getUserId()!;
        const SharedRepo = getUserRepos(userId).SharedGroupPreviewRepo;
        if (!prevRes || !SharedRepo) return;
        await prevRes.Repo.delete(prevRes.groupPreview);
        const {_rev, _id, ...plainGroup} = prevRes.groupPreview;
        await SharedRepo.create({...plainGroup, remoteDbName: doc.remoteDbName});
      }
    },
  ];
};

export const toGroupNoPrefixId = (id: GroupEntry["_id"]) => {
  return id.slice(4) as string as NoPrefixGroupId;
};

export const shareGroup = async (opts: {group: PouchWrap<GroupEntry>}) => {
  const {group} = opts;
  const id = toGroupNoPrefixId(group._id);
  const {GroupRepo} = getGroupRepos(id);
  const dbName = GroupRepo.getDb().name;
  await authServerRequest({
    path: "/share-group",
    data: {dbName},
  });
  await GroupRepo.update({...group, remoteDbName: dbName});
};

export const deleteGroup = async (group: PouchWrap<GroupEntry>, userId: NoPrefixUserId) => {
  if (group.remoteDbName) {
    await authServerRequest({
      path: "/delete-database",
      data: {dbName: group.remoteDbName},
    });
  }
  await getGroupRepos(toGroupNoPrefixId(group._id)).GroupRepo.getDb().destroy();
  const res = await getPreviewGroupAndRepo(group);
  if (res) await res.Repo.delete(res.groupPreview);
};

export const leaveGroup = async (group: GroupEntry, GroupPreviewRepo: GroupPreviewRepoType) => {
  await authServerRequest({
    path: "/leave-group",
    data: {dbName: group.remoteDbName},
  });
  const list = await GroupPreviewRepo.getAllPromise();
  const preview = list.find((p) => p.shortId === group.shortId);
  if (preview) {
    await GroupPreviewRepo.delete(preview);
    await getGroupRepos(preview.groupId).GroupRepo.getDb().destroy();
  }
};
