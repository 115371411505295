import PouchDb from "pouchdb-browser";
import {useState} from "react";
import * as v from "valibot";
import {Trans} from "@lingui/macro";
import {authServerRequest} from "../../authServerRequest";
import type {NoPrefixUserId} from "../../models/UserRepo";
import {getUserRepos} from "../../models/UserRepo";
import {useForm} from "../form/Form";
import {TextField} from "../form/fields";
import Button from "../../ui/Button";
import {Box, css} from "../../ui/Box";
import {greenTheme} from "../../styles/themes/color-themes.css";

const loginFormSchema = v.intersect([
  v.object({
    email: v.pipe(v.string(), v.email()),
  }),
  v.variant("type", [
    v.object({
      type: v.literal("login"),
      password: v.pipe(v.string(), v.nonEmpty(), v.minLength(8)),
    }),
    v.object({
      type: v.literal("forgotPassword"),
      password: v.string(),
    }),
  ]),
]);

export const LoginForm = ({setUserId}: {setUserId: (val: null | NoPrefixUserId) => void}) => {
  const [pwMailRequested, setPwMailRequested] = useState(false);
  const {field, Form, useValues, setValue} = useForm({
    initial: {
      email: "",
      type: "login",
      password: "",
    },
    schema: loginFormSchema,
    onSubmit: async (values) => {
      const {email, password, type} = values;
      if (type === "login") {
        const {userId, remoteDbName} = await authServerRequest({
          path: "/login",
          data: {email, password},
        });
        await PouchDb.sync(
          getUserRepos(userId).MeRepo.getDb(),
          `${import.meta.env.VITE_COUCHDB_HOST}/${remoteDbName}`
        ).on("error", function (err: any) {
          throw new Error(err);
        });
        if (userId) setUserId(userId);
      } else if (type === "forgotPassword") {
        await authServerRequest({
          path: "/request-password-reset",
          data: {email},
        });
        setPwMailRequested(true);
      }
    },
  });

  const type = useValues((s) => s.type);

  if (pwMailRequested) {
    return (
      <Box
        className={greenTheme}
        bg="surface"
        rounded="4"
        pa="8"
        sp="8"
        color="primary"
        textAlign="center"
      >
        <Trans>Reset mail requested</Trans>
      </Box>
    );
  } else {
    return (
      <Form
        buttonLabel={type === "login" ? "Login" : "Request reset link"}
        className={css({sp: "16", display: "flex", flexDir: "column"})}
      >
        <TextField field={field("email")} label={<Trans>Email</Trans>} />
        {type === "login" && (
          <TextField type="password" field={field("password")} label={<Trans>Password</Trans>} />
        )}
        <Button
          onClick={() => setValue("type", type === "login" ? "forgotPassword" : "login")}
          variant="tertiary"
          className={css({mr: "auto"})}
        >
          <Trans>Forgot password?</Trans>
        </Button>
      </Form>
    );
  }
};

export default LoginForm;
