import {useEffect, useState} from "react";
import {authServerRequest} from "../../authServerRequest";
import type {GroupId} from "../../models/GroupEntry";
import Spinner from "../../shared/ui/Spinner";
import {Trans} from "@lingui/macro";

export type TokenType = "verify-email" | "reset-password" | "group-invite";
export type TypeToData = {
  "verify-email": Record<string, never>;
  "reset-password": Record<string, never>;
  "group-invite": {group: {name: string; shortId: string; id: GroupId}; inviter: {name: string}};
};

export function useToken<Type extends keyof TypeToData>({
  type,
  token,
}: {
  token?: string;
  type: Type;
}) {
  const [state, setState] = useState<
    | {step: "initial" | "loading"}
    | {step: "error"; error: string}
    | {step: "success"; data: TypeToData[Type]}
  >({step: "initial"});
  useEffect(() => {
    setState({step: "loading"});
    authServerRequest({path: `/tokens/check/${type}/${token}`, method: "get"}).then(
      (res) => {
        setState({step: "success", data: res.data});
      },
      (err) => {
        setState({step: "error", error: err.message});
      }
    );
  }, [token, type]);
  switch (state.step) {
    case "initial":
    case "loading": {
      return {success: false, renderEl: <Spinner size="24" />} as const;
    }
    case "error": {
      return {
        success: false,
        renderEl: (
          <div>
            <Trans>Error</Trans>: {state.error}
          </div>
        ),
      } as const;
    }
    case "success": {
      return {success: true, data: state.data} as const;
    }
    default: {
      return {success: false, renderEl: <div>Unknown step: {(state as any).step}</div>} as const;
    }
  }
}
