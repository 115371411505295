import type {Dispatch, MutableRefObject} from "react";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {create} from "zustand";
import {subscribeWithSelector} from "zustand/middleware";

type Point = {x: number; y: number};

const primaryButton = 0;
const sloppyClickThreshold = 5;
const isSloppyClickThresholdExceeded = (original: Point, current: Point) =>
  Math.abs(current.x - original.x) >= sloppyClickThreshold ||
  Math.abs(current.y - original.y) >= sloppyClickThreshold;

type Key = number | string;

type DragInfo = {
  key: Key;
  pos: Point;
  startIndex: number;
  currentIndex: number | null;
  gapRect: DOMRect;
  onDrop: (() => void) | null;
};

type DropAnimation = {
  from: Point;
  key: Key;
  timeoutId: ReturnType<typeof setTimeout>;
};

export const useDragStore = create(
  subscribeWithSelector<{
    dragInfo: null | DragInfo;
    setDragInfo: (dragInfo: DragInfo | null) => void;
    updateDragInfo: (dragInfo: Partial<DragInfo>) => void;

    dropAnimation: null | DropAnimation;
    setDropAnimation: (dropAnimation: DropAnimation | null) => void;
  }>((set) => ({
    dragInfo: null,
    setDragInfo: (dragInfo) => set({dragInfo}),
    updateDragInfo: (dragInfo) => set((prev) => ({dragInfo: {...prev.dragInfo!, ...dragInfo}})),

    dropAnimation: null,
    setDropAnimation: (dropAnimation) => set({dropAnimation}),
  }))
);

type MoveStyleManager = {
  resetTo(pos: Point): void;
  setNextPosition(pos: Point): void;
};

const createMoveStyleManager = (opts: {
  node: HTMLElement;
  nodeRect: DOMRect;
  startPos: Point;
  key: Key;
}): MoveStyleManager => {
  const {node, nodeRect, startPos, key} = opts;
  const prevStyles = {
    position: node.style.position,
    width: node.style.width,
    height: node.style.height,
    transform: node.style.transform,
    zIndex: node.style.zIndex,
  };
  node.style.position = "fixed";
  node.style.width = `${nodeRect.width}px`;
  node.style.height = `${nodeRect.height}px`;
  node.style.transform = `translate(${nodeRect.left}px, ${nodeRect.top}px)`;
  node.style.zIndex = "1";

  return {
    resetTo: () => {
      const preReleaseRect = node.getBoundingClientRect();
      Object.entries(prevStyles).forEach(([attr, val]) => (node.style[attr as "position"] = val));

      // could be simplified now that we have access to startPx?
      node.style.transform = "";
      const noTransformRect = node.getBoundingClientRect();

      node.style.transform = prevStyles.transform;
      const postReleaseRect = node.getBoundingClientRect();

      const diff = preReleaseRect.top - postReleaseRect.top;
      const y = postReleaseRect.top - noTransformRect.top + diff;
      const x = 0;
      // console.log({
      //   pre: preReleaseRect.top,
      //   post: postReleaseRect.top,
      //   noTransformRect: noTransformRect.top,
      // });
      node.style.transform = `translate(${x}px, ${y}px)`;
      let timeoutId = setTimeout(() => {
        node.style.transition = "transform 0.2s ease-in-out";
        node.style.transform = prevStyles.transform;
        useDragStore.getState().setDropAnimation(null);
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            node.style.transition = "";
          });
        });
      });
      useDragStore
        .getState()
        .setDropAnimation({key, from: {x: preReleaseRect.left, y: preReleaseRect.top}, timeoutId});
    },
    setNextPosition: (pos) => {
      const y = nodeRect.top + pos.y - startPos.y;
      const x = nodeRect.left + pos.x - startPos.x;
      node.style.transform = `translate(${x}px, ${y}px)`;
    },
  };
};

type Actions = {
  onMouseDown?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseMove?: (e: React.MouseEvent<HTMLElement>) => void;
  onTouchStart?: (e: React.TouchEvent<HTMLElement>) => void;
  onTouchMove?: (e: React.TouchEvent<HTMLElement>) => void;
  onTouchEnd?: (e: React.TouchEvent<HTMLElement>) => void;
  onTouchCancel?: (e: React.TouchEvent<HTMLElement>) => void;
};

type State = {
  state: "idle" | "pending" | "pendingTouch" | "started";
  actions: Actions;
};

const setupHandlers = (opts: {
  setIsDragged: Dispatch<boolean>;
  optsRef: MutableRefObject<DragOpts>;
}) => {
  const {optsRef, setIsDragged} = opts;
  const unsubs: (() => void)[] = [];
  const dir = optsRef.current.direction || "all";

  const getIdleState = (): State => ({
    state: "idle",
    actions: {
      onMouseDown: (e) => {
        if (e.defaultPrevented) return;
        if (e.button !== primaryButton) return;
        if (e.ctrlKey || e.metaKey || e.shiftKey || e.altKey) return;
        e.preventDefault();
        state = getPendingState({startPos: {x: e.clientX, y: e.clientY}});
      },
      onTouchStart: (e) => {
        if (e.defaultPrevented) return;
        const t = e.touches[0];
        const startPos = {x: t.clientX, y: t.clientY};
        const node = e.currentTarget;
        let timeoutId = setTimeout(() => {
          unsubs.splice(0, unsubs.length);
          state = getStartedState({startPos, node, isTouch: true});
        }, 100);
        unsubs.push(() => clearTimeout(timeoutId));
        return (state = getPendingTouchState({startPos}));
      },
    },
  });

  const getPendingState = ({startPos}: {startPos: Point}): State => {
    const cancel = () => {
      state = getIdleState();
    };
    return {
      state: "pending",
      actions: {
        onMouseUp: cancel,
        onMouseDown: cancel,
        onMouseMove: (e) => {
          if (e.button !== primaryButton) return;
          const point = {x: e.clientX, y: e.clientY};
          if (isSloppyClickThresholdExceeded(startPos, point)) {
            e.preventDefault();
          }
          state = getStartedState({startPos, node: e.currentTarget!, isTouch: false});
        },
      },
    };
  };

  const getPendingTouchState = ({startPos}: {startPos: Point}): State => {
    const cancel = () => {
      for (const fn of unsubs.splice(0, unsubs.length)) fn();
      state = getIdleState();
    };
    return {
      state: "pendingTouch",
      actions: {
        onTouchMove: (e: React.TouchEvent) => {
          const t = e.touches[0];
          const point = {x: t.clientX, y: t.clientY};
          if (isSloppyClickThresholdExceeded(startPos, point)) {
            cancel();
          } else {
            e.preventDefault();
          }
        },
        onTouchStart: cancel,
        onTouchEnd: cancel,
        onTouchCancel: cancel,
      },
    };
  };

  const getStartedState = (args: {startPos: Point; node: HTMLElement; isTouch: boolean}): State => {
    const {isTouch, startPos, node} = args;
    const toPos = (input: Point) => {
      switch (dir) {
        case "vertical":
          return {x: startPos.x, y: input.y};
        default:
          return input;
      }
    };
    const nodeRect = node.getBoundingClientRect();
    const moveStyleManager = createMoveStyleManager({
      node,
      nodeRect,
      startPos,
      key: optsRef.current.key,
    });
    const onMove = (rawPos: Point) => {
      const pos = toPos(rawPos);
      moveStyleManager.setNextPosition(pos);
      useDragStore.getState().updateDragInfo({pos, startIndex: optsRef.current.index});
    };
    const endDrag = () => stopDrag(false);
    const cancelDrag = () => stopDrag(true);
    const stopDrag = (isCancelled: boolean) => {
      state = getIdleState();
      let targetPos = startPos;
      if (!isCancelled) {
        const {dragInfo} = useDragStore.getState();
        dragInfo?.onDrop?.();
      }
      for (const fn of unsubs.splice(0, unsubs.length)) fn();
      moveStyleManager.resetTo(targetPos);
    };
    useDragStore.getState().setDragInfo({
      key: optsRef.current.key,
      pos: startPos,
      startIndex: optsRef.current.index,
      currentIndex: optsRef.current.index,
      gapRect: nodeRect,
      onDrop: null,
    });
    setIsDragged(true);
    unsubs.push(() => {
      useDragStore.getState().setDragInfo(null);
      setIsDragged(false);
    });
    if (isTouch) {
      let hasVibrated = false;
      if (navigator.vibrate) {
        try {
          // only works after the first "touch end" has been performed
          navigator.vibrate(50);
          hasVibrated = true;
        } catch {}
      }
      const onTouchMove = (e: TouchEvent) => {
        if (!hasVibrated) {
          hasVibrated = true;
          if (navigator.vibrate) {
            try {
              navigator.vibrate(50);
            } catch {}
          }
        }
        const t = e.touches[0];
        const point = {x: t.clientX, y: t.clientY};
        onMove(point);
      };
      window.addEventListener("touchmove", onTouchMove);
      window.addEventListener("touchend", endDrag);
      window.addEventListener("touchcancel", cancelDrag);
      unsubs.push(() => {
        window.removeEventListener("touchmove", onTouchMove);
        window.removeEventListener("touchend", endDrag);
        window.removeEventListener("touchcancel", cancelDrag);
      });
    } else {
      const onMouseMove = (e: MouseEvent) => {
        const point = {x: e.clientX, y: e.clientY};
        onMove(point);
      };
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", endDrag);
      unsubs.push(() => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", endDrag);
      });
    }
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") cancelDrag();
    };
    window.addEventListener("keydown", handleKeyDown);
    unsubs.push(() => {
      window.removeEventListener("keydown", handleKeyDown);
    });
    return {state: "started", actions: {}};
  };

  let state: State = getIdleState();
  return {
    handlers: Object.fromEntries(
      (
        [
          "onMouseDown",
          "onMouseUp",
          "onMouseMove",
          "onTouchStart",
          "onTouchMove",
          "onTouchEnd",
          "onTouchCancel",
        ] as const
      ).map((key) => [
        key,
        (e: any) => {
          state.actions[key]?.(e);
        },
      ])
    ),
    unsubs,
  };
};

export const useDragItemKey = () => useDragStore((s) => s.dragInfo?.key ?? null);

type Direction = "vertical" | "all";

type DragOpts = {
  key: Key;
  index: number;
  direction?: Direction;
  nodeRef: MutableRefObject<HTMLElement | null>;
  startPx: number;
  onDragged?: Dispatch<boolean>;
};

export const useDrag = (opts: DragOpts) => {
  const optsRef = useRef(opts);
  useEffect(() => {
    optsRef.current = opts;
  });
  const [{handlers, unsubs}] = useState(() =>
    setupHandlers({setIsDragged: (val) => optsRef.current.onDragged?.(val), optsRef})
  );
  useEffect(() => {
    return () => {
      unsubs.forEach((fn) => fn());
    };
  }, [unsubs]);
  useLayoutEffect(() => {
    const {dropAnimation, setDropAnimation} = useDragStore.getState();
    if (!dropAnimation) return;
    if (opts.key === dropAnimation.key) {
      const node = opts.nodeRef.current;
      if (!node) return;
      clearTimeout(dropAnimation.timeoutId);
      setDropAnimation(null);
      node.style.transform = `translateY(${optsRef.current.startPx}px)`;
      const postReleaseRect = node.getBoundingClientRect();
      node.style.transform = "";
      // could be simplified now that we have access to startPx?
      const noTransformRect = node.getBoundingClientRect();
      const diff = dropAnimation.from.y - postReleaseRect.top;
      const y = postReleaseRect.top - noTransformRect.top + diff;
      const x = 0;
      node.style.transform = `translate(${x}px, ${y}px)`;
      setTimeout(() => {
        node.style.transition = "transform 0.2s ease-in-out";
        node.style.transform = `translateY(${optsRef.current.startPx}px)`;
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            node.style.transition = "";
          });
        });
      });
    }
  });

  return {handlers};
};

const isWithin = (
  pos: Point,
  rect: {left: number; right: number; top: number; bottom: number}
): boolean => {
  return pos.x >= rect.left && pos.x <= rect.right && pos.y >= rect.top && pos.y <= rect.bottom;
};

type ScrollManager = {
  onUpdatePos: (pos: Point, rect: DOMRect | null) => void;
  unsubscribe: () => void;
};

const createScrollManager = ({
  el,
  onScroll,
}: {
  el: HTMLElement;
  onScroll: () => void;
}): ScrollManager => {
  let momentum = 0;
  let intensity = 0;
  let nextRaf: null | number = null;

  const handleNextFrame = () => {
    nextRaf = requestAnimationFrame(() => {
      const currSpeed = Math.abs(momentum);
      const power = 1 + (Math.abs(intensity) - 3) / 50;
      const nextSpeed = Math.min(50, Math.max(2, currSpeed ** power));
      momentum = intensity < 0 ? -nextSpeed : nextSpeed;
      el.scrollTop += momentum;
      onScroll();
      handleNextFrame();
    });
  };

  const cancel = () => {
    if (nextRaf) {
      cancelAnimationFrame(nextRaf);
      nextRaf = null;
    }
  };

  const ensureScroll = (nextIntensity: number) => {
    intensity = nextIntensity;
    if (!nextRaf) handleNextFrame();
  };

  return {
    onUpdatePos: (pos, rect) => {
      if (!rect) return cancel();
      const scrollRectHeight = Math.min(
        100,
        Math.max(rect.height * 0.2, Math.min(rect.height * 0.5, 15))
      );
      const topRect = {
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.top + scrollRectHeight,
      };
      const bottomRect = {
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
        top: rect.bottom - scrollRectHeight,
      };
      if (isWithin(pos, topRect)) {
        const canScrollUp = el.scrollTop > 0;
        if (canScrollUp) {
          const relDistanceToTop = (pos.y - topRect.top) / scrollRectHeight;
          return ensureScroll(-Math.max(1, Math.ceil((1 - relDistanceToTop) * 6)));
        }
      }
      if (isWithin(pos, bottomRect)) {
        const relDistanceToBottom = (bottomRect.bottom - pos.y) / scrollRectHeight;
        const canScrollDown = el.clientHeight + el.scrollTop < el.scrollHeight;
        if (canScrollDown) {
          return ensureScroll(Math.max(1, Math.ceil((1 - relDistanceToBottom) * 6)));
        }
      }
      cancel();
    },
    unsubscribe: cancel,
  };
};

type DropZoneManager = {
  unsubscribe: () => void;
};

const createDropZoneManager = (
  optsRef: MutableRefObject<DropOpts>,
  dragInfo: DragInfo
): DropZoneManager => {
  const unsubs: (() => void)[] = [];
  const el = optsRef.current.getElement();

  let prevPos = dragInfo.pos;
  let prevIdx: null | number = null;

  const handleTargetIndex = (pos: Point | null) => {
    let idx = pos ? optsRef.current.getTargetIdx({pos, node: el}) : null;
    if (prevIdx === idx) return;
    const handleDrop =
      idx === null
        ? null
        : () => {
            const {onDrop} = optsRef.current;
            if (!onDrop) return null;
            return onDrop({key: dragInfo.key, startIndex: dragInfo.startIndex, targetIndex: idx!});
          };
    useDragStore.getState().updateDragInfo({currentIndex: idx, onDrop: handleDrop});
    prevIdx = idx;
  };

  const scrollManager = createScrollManager({el, onScroll: () => handleTargetIndex(prevPos)});
  unsubs.push(scrollManager.unsubscribe);

  let prevMode: DropMode = null;
  const rect = el.getBoundingClientRect();

  const setMode = (mode: DropMode) => {
    if (!optsRef.current.onModeChange) return;
    if (prevMode === mode) return;
    optsRef.current.onModeChange(mode, dragInfo.key, dragInfo.startIndex);
    prevMode = mode;
  };

  unsubs.push(() => setMode(null));

  const handlePosChange = (pos: Point | null) => {
    if (!pos) return;
    prevPos = pos;
    const isInside = isWithin(pos, rect);
    setMode(isInside ? "isOver" : "canDrop");
    if (isInside) {
      handleTargetIndex(pos);
      scrollManager.onUpdatePos(pos, rect);
    } else {
      handleTargetIndex(null);
      scrollManager.onUpdatePos(pos, null);
    }
  };

  handlePosChange(dragInfo.pos);
  unsubs.push(useDragStore.subscribe((s) => s.dragInfo?.pos ?? null, handlePosChange));

  return {
    unsubscribe: () => unsubs.forEach((fn) => fn()),
  };
};

type DropMode = null | "canDrop" | "isOver";
type DropOpts = {
  getElement: () => HTMLElement;
  onModeChange?: (mode: DropMode, key: Key | null, index: number | null) => void;
  getTargetIdx: (opts: {pos: Point; node: HTMLElement}) => number | null;
  onDrop?: (opts: {key: Key; startIndex: number; targetIndex: number}) => void;
};

export const useDrop = (opts: DropOpts) => {
  const optsRef = useRef<DropOpts>(opts);
  useEffect(() => {
    optsRef.current = opts;
  });
  const dropManagerRef = useRef<DropZoneManager | null>(null);

  useEffect(() => {
    const unsub = useDragStore.subscribe(
      (s) => s.dragInfo?.key ?? null,
      (dragKey) => {
        if (dragKey !== null) {
          dropManagerRef.current?.unsubscribe();
          dropManagerRef.current = createDropZoneManager(
            optsRef,
            useDragStore.getState().dragInfo!
          );
        } else {
          dropManagerRef.current?.unsubscribe();
          dropManagerRef.current = null;
        }
      }
    );
    return () => {
      unsub();
      dropManagerRef.current?.unsubscribe();
    };
  }, []);
};
