import type {I18nContext} from "@lingui/react";
import {createRepo} from "../../db/Repo";
import {DB_NAMES} from "../../models/DbList";
import type {NoPrefixGroupId} from "../../models/GroupEntry";
import type {Nominal} from "../../types";
import {createDateBasedIdWithHash} from "../../utils";

export type CategoryId = Nominal<string, "CategoryId">;

export type CategoryEntry = {
  _id: CategoryId;
  type: "Category";
  name: string;
  emoji: string;
  createdAt: Date;
  sortIndex: string;
  custom: boolean;
};

export type CategoryRepoType = ReturnType<typeof createCategoryRepo>;

export const createCategoryRepo = (id: NoPrefixGroupId) =>
  createRepo<CategoryEntry, {sort_index: {sortBy: ["sortIndex"]; isPresent: (el: any) => boolean}}>(
    {
      views: {sort_index: {sortBy: ["sortIndex"], isPresent: () => true}},
      dbInfo: DB_NAMES.Group(id),
      idPrefix: "ctg:",
      type: "Category",
      withAutoId: true,
      generateId: () => createDateBasedIdWithHash<CategoryId>(),
      hydrate: (fromDb) => {
        const {createdAt, _id, type, sortIndex, ...rest} = fromDb;
        return {
          _id: _id as CategoryId,
          type: "Category",
          createdAt: new Date(createdAt),
          sortIndex: sortIndex.slice(4),
          ...rest,
        };
      },
      dehydrate: (entry: CategoryEntry) => {
        const {createdAt, sortIndex, ...rest} = entry;
        return {createdAt: createdAt.toISOString(), sortIndex: `ctg:${sortIndex}`, ...rest};
      },
    }
  );

export const getCategoryName = (cat: CategoryEntry, i18n: I18nContext["i18n"]): string =>
  cat.custom ? cat.name : i18n.t({id: `cat.${cat.name}`});
