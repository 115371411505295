import {useEffect, useState} from "react";
import {plural, t} from "@lingui/macro";
import {useLingui} from "@lingui/react";
import type {I18n} from "@lingui/core";
import {pad2} from "./utils";
import {css} from "./ui/Box";

const getDiff = (date: Date) => {
  const now = new Date();
  const secs = Math.round((now.getTime() - date.getTime()) / 10000) * 10;
  let days = null;
  if (secs < 2 * 24 * 3600 && secs > -2 * 24 * 3600) {
    if (now.getDate() === date.getDate()) {
      days = 0 as const;
    } else {
      if (now > date) {
        const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
        if (yesterday.getDate() === date.getDate()) days = 1 as const;
      } else {
        const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        if (tomorrow.getDate() === date.getDate()) days = -1 as const;
      }
    }
  }

  return {
    secs,
    days,
    sameYear: now.getFullYear() === date.getFullYear(),
  };
};

const getNextTickSecs = (secs: number) => {
  if (secs < -3600) return 3600;
  const absSecs = Math.abs(secs);
  if (absSecs < 60) return 10;
  if (absSecs < 3600) return 60;
  if (absSecs < 2 * 24 * 3600) return 3600;
  return null;
};

const getTime = (date: Date) => `${pad2(date.getHours())}:${pad2(date.getMinutes())}`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const messages = [
  t({id: "tomorrow_at"}),
  t({id: "today_at"}),
  t({id: "yesterday_at"}),
  t({id: "date_at"}),
];
const dayToString = {"-1": "tomorrow_at", 0: "today_at", 1: "yesterday_at"};
const getLabel = (
  diff: {secs: number; days: 1 | 0 | -1 | null; sameYear: boolean},
  date: Date,
  i18n: I18n
) => {
  if (diff.secs === 0) {
    return t({id: "now"});
  } else if (diff.secs > 0) {
    if (diff.secs < 60) {
      return plural(diff.secs, {one: "# second ago", other: "# seconds ago"});
    } else if (diff.secs < 3600) {
      const mins = Math.floor(diff.secs / 60);
      return plural(mins, {one: "# minute ago", other: "# minutes ago"});
    }
  } else {
    if (diff.secs > -60) {
      return plural(diff.secs, {one: "in # second", other: "in # seconds"});
    } else if (diff.secs > -3600) {
      const mins = Math.floor(diff.secs / 60);
      return plural(mins, {one: "in # minute", other: "in # minutes"});
    }
  }
  if (diff.days !== null) {
    return `${i18n._(dayToString[diff.days], {time: getTime(date)})}`;
  } else {
    const dateStr = `${pad2(date.getDate())}.${pad2(date.getMonth() + 1)}.${
      diff.sameYear ? "" : pad2(date.getFullYear())
    }`;
    return i18n._(dateStr, {time: getTime(date), date: dateStr});
  }
};

const HumanTime = ({date}: {date: Date | null}) => {
  const [diff, setDiff] = useState(date && (() => getDiff(date)));
  const secs = diff && diff.secs;
  useEffect(() => {
    if (!date) return;
    const nextTickS = getNextTickSecs(secs!);
    if (!nextTickS) return;
    const timeoutId = setTimeout(() => setDiff(getDiff(date)), nextTickS * 1000);
    return () => clearTimeout(timeoutId);
  }, [secs, date]);

  const i18n = useLingui().i18n;

  if (!date) return <span style={{color: "red"}}>No date passed</span>;

  return (
    <span className={css({variantNumeric: "tabularNums"})} title={date.toISOString()}>
      {getLabel(diff!, date, i18n)}
    </span>
  );
};

export default HumanTime;
