import PouchDb from "pouchdb-browser";
import {useEffect, useLayoutEffect, useState} from "react";
import type {DbInfo} from "../models/DbList";
import {getMigratedDb} from "./migration-manager";
import {createQueryCache} from "./query-cache";
import {registerDbHook, type HookCb} from "./db-hooks";
import {ensureRemoteSync} from "./dbChangeListener";

interface BaseModel {
  _id: string;
  type: string;
}

type StringifyVal<T> = T extends null | undefined | number | boolean
  ? T
  : T extends Array<infer U>
    ? StringifyVal<U>[]
    : string;

type Stringified<T extends BaseModel> = {
  [K in keyof T]: StringifyVal<T[K]>;
};

type WithRev = {
  _rev: string;
};

type ViewList = {[viewName: string]: {sortBy: string[]; isPresent: (el: any) => boolean}};
type BaseModelUsingView<T extends ViewList> = BaseModel & {
  [K in T[keyof T]["sortBy"][number]]: string | number | boolean | null | Date;
};

export type PouchWrap<T extends BaseModel> = T & WithRev;

interface BaseRepo<
  Model extends BaseModelUsingView<Views>,
  Views extends ViewList = Record<string, never>,
> {
  useGetAll: () => PouchWrap<Model>[];
  useGetView: (name: null | (keyof Views & string)) => PouchWrap<Model>[];
  useGetById: (id: Model["_id"]) => PouchWrap<Model> | null;
  delete: (entry: PouchWrap<Model>) => Promise<void>;
  update: (entry: PouchWrap<Model>) => Promise<void>;
  updateAll: (entries: PouchWrap<Model>[]) => Promise<void>;

  getByIdPromise: (id: Model["_id"]) => Promise<PouchWrap<Model> | null>;
  getViewPromise: (name: null | (keyof Views & string)) => Promise<PouchWrap<Model>[]>;
  getAllPromise: () => Promise<PouchWrap<Model>[]>;

  getDb: () => PouchDB.Database<Stringified<Model>>;
}

export interface AutoIdRepo<
  Model extends BaseModelUsingView<Views>,
  Views extends ViewList = Record<string, never>,
> extends BaseRepo<Model, Views> {
  create: (entry: Omit<Model, "_id" | "type">) => Promise<PouchWrap<Model>>;
  createBulk: (entry: Omit<Model, "_id" | "type">[]) => Promise<PouchWrap<Model>[]>;
}
export interface ExternalIdRepo<
  Model extends BaseModelUsingView<Views>,
  Views extends ViewList = Record<string, never>,
> extends BaseRepo<Model, Views> {
  create: (id: Model["_id"], entry: Omit<Model, "_id" | "type">) => Promise<PouchWrap<Model>>;
}

type CreateRepoArgs<Model extends BaseModelUsingView<Views>, Views extends ViewList> = {
  dbInfo: DbInfo;
  idPrefix: string;
  type: Model["type"];
  hydrate: (fromDb: PouchWrap<Stringified<Model>>) => PouchWrap<Model>;
  dehydrate: (entry: Model) => Stringified<Model>;
  views: Views;
  hooks?: HookCb<Model>[];
};

type ExternalIdCreateRepoArgs<
  Model extends BaseModelUsingView<Views>,
  Views extends ViewList,
> = CreateRepoArgs<Model, Views> & {
  withAutoId: false;
  generateId?: undefined;
};

type AutoIdCreateRepoArgs<
  Model extends BaseModelUsingView<Views>,
  Views extends ViewList,
> = CreateRepoArgs<Model, Views> & {
  withAutoId: true;
  generateId: (entry: Omit<Model, "_id" | "type">) => Model["_id"];
};

const dbCache = new Map<string, PouchDB.Database<any>>();

export function createRepo<
  Model extends BaseModelUsingView<Views>,
  Views extends ViewList = Record<string, never>,
>(opts: AutoIdCreateRepoArgs<Model, Views>): AutoIdRepo<Model, Views>;
export function createRepo<
  Model extends BaseModelUsingView<Views>,
  Views extends ViewList = Record<string, never>,
>(opts: ExternalIdCreateRepoArgs<Model, Views>): ExternalIdRepo<Model, Views>;
export function createRepo<
  Model extends BaseModelUsingView<Views>,
  Views extends ViewList = Record<string, never>,
>(opts: AutoIdCreateRepoArgs<Model, Views> | ExternalIdCreateRepoArgs<Model, Views>) {
  const {dbInfo, hydrate, dehydrate, idPrefix, generateId, type, withAutoId, views, hooks} = opts;
  const {dbName, useRemoteDb, schemaVersion} = dbInfo;

  const getDb = () => {
    const exist = dbCache.get(dbName);
    if (exist) return exist;
    const proxyDb = getMigratedDb(new PouchDb<Stringified<Model>>(dbName), schemaVersion, (db) => {
      dbCache.set(dbName, db);
    });
    dbCache.set(dbName, proxyDb);
    return proxyDb;
  };

  if (hooks && hooks.length) {
    hooks.forEach((cb) => registerDbHook({dbType: dbInfo.type, modelType: type, cb}));
  }

  const queryCache = createQueryCache<Stringified<Model & WithRev>, PouchWrap<Model>, Views>({
    idPrefix,
    type,
    hydrate,
    dehydrate,
    getDb,
    dbType: dbInfo.type,
    views,
  });

  const useFetchSingle = (id: string) => {
    const [, forceUpdate] = useState<Record<string, never>>({});
    const remoteDb = useRemoteDb();

    useLayoutEffect(() => {
      return queryCache.addDbChangeListenerSingle(id, () => forceUpdate({}));
    }, [id]);

    useEffect(() => {
      if (remoteDb) return ensureRemoteSync(getDb(), remoteDb);
    }, [remoteDb]);

    const {value, isPromise} = queryCache.getSingle(id, {
      useInvalid: true,
      useOptimisticValue: true,
    });
    if (isPromise) throw value;
    return value;
  };

  const useFetchList = (viewName: string | null) => {
    const [, forceUpdate] = useState<Record<string, never>>({});
    const remoteDb = useRemoteDb();

    // needs useLayoutEffect, as useEffect takes too long and we might skip an update
    // just as we rendered component.
    // E.g. deleting an item redirects to list overview, async delete cb only arrives just after
    // list is rendered.
    useLayoutEffect(() => {
      return queryCache.addDbChangeListenerList(viewName, () => forceUpdate({}));
    }, [viewName]);

    useEffect(() => {
      if (remoteDb) return ensureRemoteSync(getDb(), remoteDb);
    }, [remoteDb]);

    const {value, isPromise} = queryCache.getList(viewName, {
      useInvalid: true,
      useOptimisticValue: true,
    });
    if (isPromise) throw value;
    return value;
  };

  const baseRepo: BaseRepo<Model, Views> = {
    useGetAll: () => useFetchList(null),
    useGetView: (name) => useFetchList(name),
    // TODO: check if id is already present from getAll or getView
    useGetById: (id) => useFetchSingle(id),
    delete: async (entry) => {
      await queryCache.delete([entry]);
    },
    update: async (entry) => {
      await queryCache.update([entry]);
    },
    updateAll: async (entries) => {
      await queryCache.update(entries);
    },
    getByIdPromise: async (id: Model["_id"]) => {
      return queryCache.getSingle(id, {useInvalid: false, useOptimisticValue: false}).value;
    },
    getAllPromise: async () => {
      return queryCache.getList(null, {useInvalid: false, useOptimisticValue: false}).value;
    },
    getViewPromise: async (viewName) => {
      return queryCache.getList(viewName, {useInvalid: false, useOptimisticValue: false}).value;
    },
    getDb,
  };

  if (withAutoId) {
    return {
      ...baseRepo,
      create: (entry) => {
        const fullEntry = {
          _id: `${idPrefix}${generateId(entry)}`,
          type,
          ...entry,
        } as unknown as Model;
        return queryCache.create(fullEntry as any);
      },
      createBulk: (entries) => {
        const fullEntries = entries.map(
          (entry) =>
            ({
              _id: `${idPrefix}${generateId(entry)}`,
              type,
              ...entry,
            }) as unknown as Model
        );
        return queryCache.createBulk(fullEntries as any);
      },
    } as AutoIdRepo<Model, Views>;
  } else {
    return {
      ...baseRepo,
      create: (externalId, entry) => {
        if (import.meta.env.DEV && !externalId.startsWith(idPrefix)) {
          throw new Error(`id '${externalId}' doesn't start with prefix '${idPrefix}'!`);
        }
        return queryCache.create({_id: externalId, type, ...entry} as any);
      },
    } as ExternalIdRepo<Model, Views>;
  }
}
