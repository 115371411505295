import type {ComponentProps} from "react";
import {forwardRef, useMemo} from "react";
import RawTextarea from "react-textarea-autosize";
import cx from "../utils/cx";
import {inputStyles as styles} from "./Input.css";

type BaseInput<T> = {
  onChange?: (value: T) => unknown;
  hasError?: boolean;
  size?: "md";
  value?: string;
};

export type InputProps = Omit<JSX.IntrinsicElements["input"], "onChange" | "size" | "value"> &
  BaseInput<string>;

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {onChange, type = "text", value, hasError, className, size = "md", ...rest} = props;
  return (
    <input
      type={type}
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}
      value={value}
      className={cx(className, styles.base, styles.size[size], hasError && styles.hasError)}
      ref={ref}
      {...rest}
    />
  );
});

export type TextAreaProps = Omit<ComponentProps<typeof RawTextarea>, "onChange" | "size"> &
  BaseInput<string>;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const {onChange, value, hasError, className, size = "md", minRows = 2, ...rest} = props;
  return (
    <RawTextarea
      ref={ref as any}
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}
      value={value}
      className={cx(className, styles.base, styles.size[size], hasError && styles.hasError)}
      minRows={minRows}
      cacheMeasurements
      {...rest}
    />
  );
});

type BaseSelect<T> = {
  options: {value: T; label: string}[];
  onChange?: (value: T) => unknown;
  hasError?: boolean;
  size?: "md";
  value?: T;
};

export type SelectProps = Omit<JSX.IntrinsicElements["select"], "onChange" | "size" | "value"> &
  BaseSelect<string | null>;

function toSelectValue<T>(value: T): string {
  return typeof value === "string" ? value : JSON.stringify(value);
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {onChange, value, hasError, className, size = "md", options, ...rest} = props;

  const transformedOptions = useMemo(
    () =>
      options.map((o) => ({
        label: o.label,
        orgValue: o.value,
        stringValue: toSelectValue(o.value),
      })),

    [options]
  );

  return (
    <select
      onChange={
        onChange
          ? (e) =>
              onChange(transformedOptions.find((o) => o.stringValue === e.target.value)!.orgValue)
          : undefined
      }
      value={toSelectValue(value)}
      className={cx(className, styles.base, styles.size[size], hasError && styles.hasError)}
      {...rest}
    >
      {transformedOptions.map(({label, stringValue}) => (
        <option key={stringValue} value={stringValue}>
          {label}
        </option>
      ))}
    </select>
  );
});
