import {useEffect, useRef, useState} from "react";
import type {MouseEventHandler, ReactNode} from "react";
import type {ItemEntry, ItemRepoType} from "../../models/ItemEntry";
import {Box, Col, css, Row, Text} from "../../ui/Box";
import {
  blueTheme,
  darkPillTheme,
  greenTheme,
  tealTheme,
} from "../../styles/themes/color-themes.css";
import type {PouchWrap} from "../../db/Repo";
import {StorageKey, storageWrapper} from "../../storage";
import Button from "../../ui/Button";
import type {IconName} from "../../ui/Icon";
import Icon from "../../ui/Icon";
import cx from "../../utils/cx";
import {transitions} from "../../styles/decoration.css";
import {animActive, disappearAnimActive, pillStyles as styles} from "./ItemPill.css";

export const ItemPillGrid = ({children}: {children: ReactNode}) => {
  const pillSpacing = storageWrapper.storageGet(StorageKey.PILL_SPACING) || "wide";
  return (
    <Box
      display="grid"
      sp="4"
      gridTemplateColumns={pillSpacing === "compact" ? "_itemPillGridCompact" : "_itemPillGridWide"}
    >
      {children}
    </Box>
  );
};

// prevent image download overlay on anrdroid
document.addEventListener("contextmenu", (event) => {
  event.preventDefault();
  event.stopPropagation();
  return false;
});

type ConfirmAnimation = "done" | "added";

const AnimIcon = ({iconName, theme}: {iconName: IconName; theme: string}) => (
  <>
    <Col
      className={css({}, theme, styles.checkboxArea)}
      absolute
      inset="0"
      bg="surface"
      zIndex="1"
      pointerEvents="none"
    />
    <Col
      absolute
      inset="0"
      align="center"
      justify="center"
      zIndex="1"
      className={theme}
      color="primary"
      pointerEvents="none"
    >
      <Icon name={iconName} size="24" className={styles.checkbox} />
    </Col>
  </>
);

type SharedItemPillProps = {
  onClick?: () => void;
  onLongClick?: () => void;
  confirmAnimation?: ConfirmAnimation;
};

type ItemPillLayoutProps = SharedItemPillProps & {
  name: ReactNode;
  theme: string;
  emoji: string | null | ReactNode;
  children?: ReactNode;
};
const ItemPillLayout = (props: ItemPillLayoutProps) => {
  const {children, onClick, onLongClick, emoji, theme, name, confirmAnimation} = props;
  const [downInfo, setDownInfo] = useState<null | {
    ts: number;
    x: number;
    y: number;
    timeoutId: ReturnType<typeof setTimeout>;
    handled: boolean;
  }>(null);

  const [animClass, setAnimClass] = useState<null | string>(null);

  const pendingClickRef = useRef<{
    cb?: () => void;
    timeoutId: ReturnType<typeof setTimeout>;
  } | null>(null);

  const handleClick = () => {
    if (confirmAnimation) {
      setAnimClass(animActive);
      let timeoutId = setTimeout(() => {
        setAnimClass(cx(animActive, disappearAnimActive));
        pendingClickRef.current!.timeoutId = setTimeout(() => {
          setAnimClass(null);
          pendingClickRef.current = null;
          onClick?.();
        }, 250);
      }, 250);
      pendingClickRef.current = {timeoutId, cb: onClick};
    } else {
      onClick?.();
    }
  };

  // if we got unmounted during animation, make sure the click handler is still applied
  useEffect(() => {
    return () => {
      const pending = pendingClickRef.current;
      if (pending) {
        clearTimeout(pending.timeoutId);
        pending.cb?.();
      }
    };
  }, []);

  const handleUp: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!downInfo) return;
    const {x, y, timeoutId, handled} = downInfo;
    if (handled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    clearTimeout(timeoutId);
    const diff = (e.clientX - x) ** 2 + (e.clientY - y) ** 2;
    if (diff > 30) return;
    if (onClick) return handleClick();
  };
  const handleDown: MouseEventHandler<HTMLDivElement> = (e) => {
    setDownInfo({
      ts: new Date().getTime(),
      x: e.clientX,
      y: e.clientY,
      handled: false,
      timeoutId: setTimeout(() => {
        if (onLongClick) {
          if (navigator.vibrate) navigator.vibrate(25);
          setDownInfo((prev) => ({...prev!, handled: true}));
          onLongClick();
        }
        setTimeout(() => setDownInfo(null), 100);
      }, 300),
    });
  };

  const handleMove: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!downInfo) return;
    const {x, y, timeoutId} = downInfo;
    const diff = (e.clientX - x) ** 2 + (e.clientY - y) ** 2;
    if (diff > 30) clearTimeout(timeoutId);
  };

  return (
    <div
      onPointerDown={handleDown}
      onPointerUp={handleUp}
      onPointerMove={handleMove}
      className={css(
        {
          display: "flex",
          bg: "surface",
          elevation: "100",
          cursor: onClick ? "pointer" : undefined,
          userSelect: "none",
          rounded: "4",
          overflow: "hidden",
          position: "relative",
          height: "48px",
        },
        transitions.transformAndColors,
        animClass
      )}
    >
      {confirmAnimation === "done" && (
        <AnimIcon iconName="check" theme={animClass ? greenTheme : theme} />
      )}
      {confirmAnimation === "added" && (
        <AnimIcon iconName="plus" theme={animClass ? blueTheme : theme} />
      )}
      <Row
        className={css(
          {
            flex: "none",
            bg: "surface",
            color: "primary",
            textAlign: "center",
            size: "24",
            align: "center",
            justify: "center",
            width: "48px",
          },
          theme
        )}
      >
        {emoji}
      </Row>
      <Text
        px="16"
        size="20"
        color="primary"
        minWidth="0"
        flex="auto"
        alignSelf="center"
        whiteSpace="nowrap"
        className={styles.contentArea}
      >
        {name}
      </Text>
      {children}
    </div>
  );
};

type AddItemPillProps = {
  item: PouchWrap<ItemEntry>;
  name: string;
  ItemRepo: ItemRepoType;
  onDone?: (item: ItemEntry) => void;
} & Omit<SharedItemPillProps, "onClick" | "onLongClick">;
export const AddItemPill = (props: AddItemPillProps) => {
  const {name, ItemRepo, item, onDone, ...rest} = props;
  const handleAddItem = async () => {
    await ItemRepo.update({...item, done: false, quantity: 1});
    return onDone?.(item);
  };
  return (
    <ItemPillLayout
      onClick={handleAddItem}
      emoji={item.emoji}
      theme={blueTheme}
      name={name}
      {...rest}
    />
  );
};

const Pill = ({children}: {children: ReactNode}) => (
  <Box px="4" bg="surface" size="12" bold color="primary" className={darkPillTheme} rounded="4">
    {children}
  </Box>
);

type ModifyPillProps = {
  item: PouchWrap<ItemEntry>;
  name: string;
  ItemRepo: ItemRepoType;
} & Omit<SharedItemPillProps, "onClick" | "onLongClick">;
export const ModifyPill = (props: ModifyPillProps) => {
  const {item, name, ItemRepo, ...rest} = props;
  const handleDec = async () => {
    if (item.quantity < 2) {
      await ItemRepo.update({...item, done: true});
    } else {
      await ItemRepo.update({...item, quantity: item.quantity - 1});
    }
  };
  const handleInc = async () => {
    await ItemRepo.update({...item, quantity: item.quantity + 1});
  };

  return (
    <ItemPillLayout emoji={item.emoji} theme={tealTheme} name={name} {...rest}>
      <Row flex="none" relative>
        <Row wrap sp="4" absolute justify="end" bottom="0" right="100%" px="4" pb="4">
          {item.quantity > 1 ? <Pill>{item.quantity}</Pill> : null}
          {item.tags.map((t) => (
            <Pill key={t}>{t}</Pill>
          ))}
        </Row>
        <Button variant="primary" size="md" onClick={handleDec}>
          <Icon name="minus" />
        </Button>
        <Button variant="primary" size="md" onClick={handleInc}>
          <Icon name="plus" />
        </Button>
      </Row>
    </ItemPillLayout>
  );
};

type CreateItemPillProps = {
  name: string;
  ItemRepo: ItemRepoType;
  onDone?: (item: ItemEntry) => void;
} & Omit<SharedItemPillProps, "onClick" | "onLongClick">;
export const CreateItemPill = (props: CreateItemPillProps) => {
  const {name, ItemRepo, onDone, ...rest} = props;
  const handleAddItem = async () => {
    const item = await ItemRepo.create({
      name,
      createdAt: new Date(),
      custom: true,
      done: false,
      lastDoneAt: null,
      quantity: 1,
      tagHistory: [],
      tags: [],
      categoryId: null,
      emoji: null,
    });
    return onDone?.(item);
  };
  return (
    <ItemPillLayout
      onClick={handleAddItem}
      emoji={<Icon name="plus" />}
      theme={greenTheme}
      name={name}
      {...rest}
    />
  );
};

type UndoneItemTileProps = {
  item: PouchWrap<ItemEntry>;
  name: string;
} & SharedItemPillProps;
export const UndoneItemPill = (props: UndoneItemTileProps) => {
  const {item, name, ...rest} = props;
  return (
    <ItemPillLayout emoji={item.emoji} theme={tealTheme} name={name} {...rest}>
      <Row wrap sp="4" absolute justify="end" bottom="0" left="0" right="0" px="4" pb="4">
        {item.quantity > 1 ? <Pill>{item.quantity}</Pill> : null}
        {item.tags.map((t) => (
          <Pill key={t}>{t}</Pill>
        ))}
      </Row>
    </ItemPillLayout>
  );
};

type DoneItemTileProps = {
  item: PouchWrap<ItemEntry>;
  name: string;
} & SharedItemPillProps;
export const DoneItemPill = (props: DoneItemTileProps) => {
  const {item, name, ...rest} = props;
  return <ItemPillLayout emoji={item.emoji} theme={blueTheme} name={name} {...rest} />;
};
