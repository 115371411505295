import {createRepo} from "../db/Repo";
import type {Nominal} from "../types";
import {createDateBasedId} from "../utils";
import type {DbInfo} from "./DbList";
import type {NoPrefixGroupId} from "./GroupEntry";

export type GroupPreviewId = Nominal<string, "GroupId">;

export type GroupPreviewEntry = {
  _id: GroupPreviewId;
  type: "GroupPreview";
  shortId: string;
  groupId: NoPrefixGroupId;
  name: string;
  createdAt: Date;
  remoteDbName: string | null;
};

export type GroupPreviewRepoType = ReturnType<typeof createGroupPreviewRepo>;

export const createGroupPreviewRepo = (dbInfo: DbInfo) =>
  createRepo<GroupPreviewEntry>({
    dbInfo,
    idPrefix: "grpPreview:",
    type: "GroupPreview",
    withAutoId: true,
    generateId: ({shortId}) => `${createDateBasedId()}:${shortId}` as GroupPreviewId,
    hydrate: (fromDb) => {
      const {createdAt, _id, type, groupId, ...rest} = fromDb;
      return {
        _id: _id as GroupPreviewId,
        groupId: groupId as NoPrefixGroupId,
        type: "GroupPreview",
        createdAt: new Date(createdAt),
        ...rest,
      };
    },
    dehydrate: (entry) => {
      const {createdAt, ...rest} = entry;
      return {createdAt: createdAt.toISOString(), ...rest};
    },
    views: {},
  });
