import type {ReactNode} from "react";
import {Drawer as Vaul} from "vaul";
import {Suspense} from "react";
import {defaultTheme} from "../styles/themes/color-themes.css";
import Spinner from "../shared/ui/Spinner";
import useEsc from "../utils/use-esc";
import {Col, css} from "./Box";

type SharedDrawerContentProps = {children: ReactNode};

export const SideDrawer = (
  props: SharedDrawerContentProps & {open: boolean; onClose: () => void}
) => {
  const {children, onClose, open} = props;
  useEsc(onClose);
  return (
    <Vaul.Root open={open} onClose={onClose} direction="left">
      <Vaul.Portal>
        <Vaul.Overlay
          className={css(
            {bg: "backdrop", bgAlpha: "80%", position: "fixed", inset: "0"},
            defaultTheme
          )}
          onClick={onClose}
        />
        <Vaul.Content
          className={css(
            {
              bg: "background",
              position: "fixed",
              top: "0",
              bottom: "0",
              left: "0",
              maxWidth: "_sideDrawerWidth",
              width: "_sideDrawerWidth",
              display: "flex",
              flexDir: "column",
              roundedRight: "4",
            },
            defaultTheme
          )}
        >
          <Col overflow="auto" px="12" py="12" flex="auto">
            <Suspense fallback={<Spinner size="48" />}>{children}</Suspense>
          </Col>
        </Vaul.Content>
      </Vaul.Portal>
    </Vaul.Root>
  );
};
