import {Outlet, useParams} from "react-router";
import {useEffect} from "react";
import {ProvideGroupRemoteDb} from "../../models/DbList";
import {ProvideGroupRepos} from "../../models/GroupRepos";
import {useLocalRepos} from "../../models/LocalRepo";
import {useUserRepos} from "../../models/UserRepo";
import {StorageKey, storageWrapper} from "../../storage";

const GroupRoot = () => {
  const {shortId} = useParams<{shortId: string}>();
  const {LocalGroupPreviewRepo} = useLocalRepos();
  const {SharedGroupPreviewRepo} = useUserRepos();
  const groupPreview = [
    ...LocalGroupPreviewRepo.useGetAll(),
    ...SharedGroupPreviewRepo.useGetAll(),
  ].find((g) => g.shortId === shortId);
  const groupId = groupPreview?.groupId ?? null;

  useEffect(() => {
    if (groupId) {
      storageWrapper.storageSet(StorageKey.LAST_GROUP_ID, groupId);
    }
  }, [groupId]);

  if (!groupPreview) return <div>{404}</div>;

  return (
    <ProvideGroupRepos groupId={groupPreview.groupId}>
      <ProvideGroupRemoteDb value={groupPreview.remoteDbName}>
        <Outlet />
      </ProvideGroupRemoteDb>
    </ProvideGroupRepos>
  );
};

export default GroupRoot;
