import type {DbType} from "../models/DbList";

type DbChange = PouchDB.Core.ChangesResponseChange<any>;

type HookCbArgs<T> = {
  db: PouchDB.Database<any>;
  doc: T;
  type: ChangeType;
  prevDoc?: T;
};
export type HookCb<T> = (opts: HookCbArgs<T>) => void;
type RegisterOpts<T> = {
  dbType: DbType;
  modelType: string;
  cb: HookCb<T>;
};

type HookCbSet = Set<HookCb<any>>;
const hooksByDbType = new Map<DbType, Map<string, HookCbSet>>();

export const registerDbHook = <T>(opts: RegisterOpts<T>) => {
  const dbExist = hooksByDbType.get(opts.dbType);
  if (!dbExist) {
    hooksByDbType.set(opts.dbType, new Map([[opts.modelType, new Set([opts.cb])]]));
  } else {
    const cbSet = dbExist.get(opts.dbType);
    if (cbSet) {
      cbSet.add(opts.cb);
    } else {
      dbExist.set(opts.modelType, new Set([opts.cb]));
    }
  }
};

export type ChangeType = "create" | "update" | "delete";

export const getChangeType = (change: DbChange) => {
  if (change.changes.some((c) => c.rev.startsWith("1-"))) return "create";
  if (change.deleted) return "delete";
  return "update";
};

export const getHookManagerForDb = (dbType: DbType, db: PouchDB.Database<any>) => {
  const myHooks = hooksByDbType.get(dbType);

  return {
    onChange: async ({type, change}: {type: string; change: DbChange}) => {
      const hooks = myHooks?.get(type);
      if (!hooks || hooks.size === 0) return;
      const changeType = getChangeType(change);
      if (changeType === "update") {
        const withRevInfo = await db.get(change.id, {revs_info: true});
        type RevInfo = {rev: string; status: "available" | "unavailable"};
        const revs = (withRevInfo._revs_info as RevInfo[]).map((d) => ({
          ...d,
          seq: parseInt(d.rev),
        }));
        for (const {rev} of change.changes) {
          const mySeq = parseInt(rev);
          // find first seq smaller than mine
          const prevRev = revs.find((r) => r.seq < mySeq && r.status === "available")?.rev;
          const prevDoc = prevRev && (await db.get(change.id, {rev: prevRev}));
          hooks.forEach((cb) => {
            cb({db, doc: change.doc, type: changeType, prevDoc});
          });
        }
      } else {
        hooks.forEach((cb) => {
          cb({db, doc: change.doc, type: changeType});
        });
      }
    },
  };
};
