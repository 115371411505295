import {t} from "@lingui/macro";
import type {CategoryEntry, CategoryRepoType} from "../features/categories/CategoryEntry";
import {createSorter} from "../utils/sort-utils";
import {mapByKey} from "../collection-utils";
import type {ItemEntry, ItemRepoType} from "./ItemEntry";

// these ids are meant to be picked up by the lingui extractor
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const messages = [
  t({id: "cat.fruit-vegetables"}),
  t({id: "cat.bread"}),
  t({id: "cat.dairy"}),
  t({id: "cat.spices"}),
  t({id: "cat.meat"}),
  t({id: "cat.convenience"}),
  t({id: "cat.grain"}),
  t({id: "cat.snacks"}),
  t({id: "cat.drinks"}),
  t({id: "cat.household"}),
  t({id: "cat.care"}),
  t({id: "cat.diy"}),
  t({id: "cat.pet"}),

  t({id: "item.apple"}),
  t({id: "item.carrot"}),
  t({id: "item.potato"}),
  t({id: "item.cucumber"}),
  t({id: "item.bell-pepper"}),
  t({id: "item.whole-wheat-bread"}),
  t({id: "item.baguette"}),
  t({id: "item.rye-bread"}),
  t({id: "item.toast"}),
  t({id: "item.pretzel"}),
  t({id: "item.milk"}),
  t({id: "item.butter"}),
  t({id: "item.cheese"}),
  t({id: "item.yogurt"}),
  t({id: "item.cream"}),
  t({id: "item.salt"}),
  t({id: "item.pepper"}),
  t({id: "item.paprika"}),
  t({id: "item.cinnamon"}),
  t({id: "item.thyme"}),
  t({id: "item.chicken-breast"}),
  t({id: "item.pork-chops"}),
  t({id: "item.beef-steak"}),
  t({id: "item.sausages"}),
  t({id: "item.salmon-fillet"}),
  t({id: "item.pasta"}),
  t({id: "item.pizza"}),
  t({id: "item.canned-soup"}),
  t({id: "item.frozen-vegetables"}),
  t({id: "item.ready-made-sauce"}),
  t({id: "item.rice"}),
  t({id: "item.quinoa"}),
  t({id: "item.oats"}),
  t({id: "item.barley"}),
  t({id: "item.couscous"}),
  t({id: "item.chocolate"}),
  t({id: "item.chips"}),
  t({id: "item.nuts"}),
  t({id: "item.dried-fruits"}),
  t({id: "item.popcorn"}),
  t({id: "item.water"}),
  t({id: "item.orange-juice"}),
  t({id: "item.cola"}),
  t({id: "item.beer"}),
  t({id: "item.coffee"}),
  t({id: "item.dish-soap"}),
  t({id: "item.laundry-detergent"}),
  t({id: "item.toilet-paper"}),
  t({id: "item.trash-bags"}),
  t({id: "item.cleaning-spray"}),
  t({id: "item.shampoo"}),
  t({id: "item.toothpaste"}),
  t({id: "item.body-lotion"}),
  t({id: "item.razor"}),
  t({id: "item.deodorant"}),
  t({id: "item.screwdriver"}),
  t({id: "item.paintbrush"}),
  t({id: "item.nails"}),
  t({id: "item.hammer"}),
  t({id: "item.sandpaper"}),
  t({id: "item.cat-food"}),
  t({id: "item.dog-treats"}),
  t({id: "item.cat-litter"}),
  t({id: "item.bird-seeds"}),
  t({id: "item.fish-food"}),
];

const initialCategories = [
  {name: "fruit-vegetables", emoji: "🍆"},
  {name: "bread", emoji: "🍞"},
  {name: "dairy", emoji: "🥛"},
  {name: "spices", emoji: "🌶️"},
  {name: "meat", emoji: "🥩"},
  {name: "convenience", emoji: "🧑‍🍳"},
  {name: "grain", emoji: "🌾"},
  {name: "snacks", emoji: "🍪"},
  {name: "drinks", emoji: "🥤"},
  {name: "household", emoji: "🫧"},
  {name: "care", emoji: "🧴"},
  {name: "diy", emoji: "🔨"},
  {name: "pet", emoji: "🐱"},
];

const initialItems = [
  {name: "apple", category: "fruit-vegetables", icon: "🍎"},
  {name: "carrot", category: "fruit-vegetables", icon: "🥕"},
  {name: "potato", category: "fruit-vegetables", icon: "🥔"},
  {name: "cucumber", category: "fruit-vegetables", icon: "🥒"},
  {name: "bell-pepper", category: "fruit-vegetables", icon: "🫑"},

  {name: "whole-wheat-bread", category: "bread", icon: "🍞"},
  {name: "baguette", category: "bread", icon: "🥖"},
  {name: "rye-bread", category: "bread", icon: "🌾"},
  {name: "toast", category: "bread", icon: "🍞"},
  {name: "pretzel", category: "bread", icon: "🥨"},

  {name: "milk", category: "dairy", icon: "🥛"},
  {name: "butter", category: "dairy", icon: "🧈"},
  {name: "cheese", category: "dairy", icon: "🧀"},
  {name: "yogurt", category: "dairy", icon: "⚪️"},
  {name: "cream", category: "dairy", icon: "🧁"},

  {name: "salt", category: "spices", icon: "🧂"},
  {name: "pepper", category: "spices", icon: "⚫️"},
  {name: "paprika", category: "spices", icon: "🔴"},
  {name: "cinnamon", category: "spices", icon: "🍂"},
  {name: "thyme", category: "spices", icon: "🌿"},

  {name: "chicken-breast", category: "meat", icon: "🐓"},
  {name: "pork-chops", category: "meat", icon: "🐷"},
  {name: "beef-steak", category: "meat", icon: "🥩"},
  {name: "sausages", category: "meat", icon: "🌭"},
  {name: "salmon-fillet", category: "meat", icon: "🍣"},

  {name: "pasta", category: "convenience", icon: "🍝"},
  {name: "pizza", category: "convenience", icon: "🍕"},
  {name: "canned-soup", category: "convenience", icon: "🍯"},
  {name: "frozen-vegetables", category: "convenience", icon: "🥦"},
  {name: "ready-made-sauce", category: "convenience", icon: "🥫"},

  {name: "rice", category: "grain", icon: "🍚"},
  {name: "quinoa", category: "grain", icon: "🌾"},
  {name: "oats", category: "grain", icon: "🌾"},
  {name: "barley", category: "grain", icon: "🌾"},
  {name: "couscous", category: "grain", icon: "🌾"},

  {name: "chocolate", category: "snacks", icon: "🍫"},
  {name: "chips", category: "snacks", icon: "🟡"},
  {name: "nuts", category: "snacks", icon: "🥜"},
  {name: "dried-fruits", category: "snacks", icon: "🍇"},
  {name: "popcorn", category: "snacks", icon: "🍿"},

  {name: "water", category: "drinks", icon: "🚰"},
  {name: "orange-juice", category: "drinks", icon: "🧃"},
  {name: "cola", category: "drinks", icon: "🥤"},
  {name: "beer", category: "drinks", icon: "🍺"},
  {name: "coffee", category: "drinks", icon: "☕️"},

  {name: "dish-soap", category: "household", icon: "🧼"},
  {name: "laundry-detergent", category: "household", icon: "🧹"},
  {name: "toilet-paper", category: "household", icon: "🧻"},
  {name: "trash-bags", category: "household", icon: "🗑️"},
  {name: "cleaning-spray", category: "household", icon: "🚽"},

  {name: "shampoo", category: "care", icon: "🧼"},
  {name: "toothpaste", category: "care", icon: "🪥"},
  {name: "body-lotion", category: "care", icon: "🧼"},
  {name: "razor", category: "care", icon: "🗡️"},
  {name: "deodorant", category: "care", icon: "📢"},

  {name: "screwdriver", category: "diy", icon: "🪛"},
  {name: "paintbrush", category: "diy", icon: "👩‍🎨"},
  {name: "nails", category: "diy", icon: "🔩"},
  {name: "hammer", category: "diy", icon: "🔨"},
  {name: "sandpaper", category: "diy", icon: "📜"},

  {name: "cat-food", category: "pet", icon: "🐱"},
  {name: "dog-treats", category: "pet", icon: "🐕"},
  {name: "cat-litter", category: "pet", icon: "🐱"},
  {name: "bird-seeds", category: "pet", icon: "🐦"},
  {name: "fish-food", category: "pet", icon: "🐟"},
] satisfies {name: string; category: string; icon: string | null}[];
export const fillInitialItems = async (CategoryRepo: CategoryRepoType, ItemRepo: ItemRepoType) => {
  const sortIdxs = createSorter()
    .getMudder()
    .mudder("", "", initialCategories.length, undefined, initialCategories.length + 10);
  const now = new Date();
  const newEntries: Omit<CategoryEntry, "type" | "_id">[] = initialCategories.map((c, i) => ({
    ...c,
    createdAt: now,
    custom: false,
    sortIndex: sortIdxs[i],
  }));
  const cats = await CategoryRepo.createBulk(newEntries);
  const catByName = mapByKey(cats, "name");

  const newItems: Omit<ItemEntry, "type" | "_id">[] = initialItems.map((item) => ({
    name: item.name,
    categoryId: catByName.get(item.category)!._id,
    createdAt: now,
    done: true,
    lastDoneAt: null,
    quantity: 0,
    tagHistory: [],
    tags: [],
    custom: false,
    emoji: item.icon,
  }));
  await ItemRepo.createBulk(newItems);
};
