import {useLingui} from "@lingui/react";
import {useNavigate, type RouteObject} from "react-router";
import {useMemo} from "react";
import {Trans} from "@lingui/macro";
import {useGroupRepos} from "../../models/GroupRepos";
import {Col, Text} from "../../ui/Box";
import {getItemName, type ItemEntry} from "../../models/ItemEntry";
import type {PouchWrap} from "../../db/Repo";
import {
  getCategoryName,
  type CategoryEntry,
  type CategoryRepoType,
} from "../../features/categories/CategoryEntry";
import {groupBy} from "../../collection-utils";
import AddItem from "../../features/items/AddItem";
import AppShell from "../../shared/ui/AppShell";
import GroupFooterNav from "../../shared/ui/GroupFooterNav";
import type {GroupId} from "../../models/GroupEntry";
import Header from "../../shared/ui/Header";
import Icon from "../../ui/Icon";
import Button, {FloatingButton} from "../../ui/Button";
import {DrawerRoutes, RouteDrawer} from "../../ui/Drawer";
import EditItem from "../../features/items/EditItem";
import {DoneItemPill, ItemPillGrid, UndoneItemPill} from "../../features/items/ItemPill";
import groupByDate from "../../utils";
import HumanTime from "../../HumanTime";

type CatWithItems = {category: CategoryEntry; items: PouchWrap<ItemEntry>[]};

const getGroups = (items: PouchWrap<ItemEntry>[], cats: CategoryEntry[]) => {
  const byCatId = groupBy(items, "categoryId");
  const groups = cats
    .map((cat) => {
      const catItems = byCatId.get(cat._id);
      if (catItems) {
        byCatId.delete(cat._id);
        return {category: cat as CategoryEntry | null, items: catItems};
      }
      return null as any as CatWithItems;
    })
    .filter(Boolean);
  const leftOverItems: PouchWrap<ItemEntry>[] = [];
  for (const leftOver of byCatId.values()) leftOverItems.push(...leftOver);
  if (leftOverItems.length) {
    groups.push({items: leftOverItems, category: null});
  }
  return groups;
};

type ItemListByCategoryProps = {
  items: PouchWrap<ItemEntry>[];
  onItemClick: (item: PouchWrap<ItemEntry>) => void;
  CategoryRepo: CategoryRepoType;
};
const ItemListByCategory = (props: ItemListByCategoryProps) => {
  const {items, onItemClick, CategoryRepo} = props;
  const {i18n} = useLingui();
  const navigate = useNavigate();
  const categories = CategoryRepo.useGetView("sort_index");
  const groups = useMemo(() => getGroups(items, categories), [items, categories]);
  return (
    <Col sp="16">
      {groups.map(({category: cat, items: catItems}) => (
        <Col sp="8" key={cat?._id ?? "null"}>
          {cat ? (
            <Text size="16" color="secondary">
              {cat.emoji} {getCategoryName(cat, i18n)}
            </Text>
          ) : (
            <Text>
              <Trans id={"cat.other"}>other</Trans>
            </Text>
          )}
          <ItemPillGrid>
            {catItems.map((item) => (
              <UndoneItemPill
                key={item._id}
                item={item}
                confirmAnimation="done"
                onClick={() => onItemClick(item)}
                name={getItemName(item, i18n)}
                onLongClick={() => navigate(`edit/${item._id}`)}
              />
            ))}
          </ItemPillGrid>
        </Col>
      ))}
    </Col>
  );
};

type ItemsListProps = {
  items: PouchWrap<ItemEntry>[];
  onItemClick: (item: PouchWrap<ItemEntry>) => void;
};
const ItemList = (props: ItemsListProps) => {
  const {items, onItemClick} = props;
  const {i18n} = useLingui();
  const navigate = useNavigate();
  return (
    <ItemPillGrid>
      {items.map((item) => (
        <DoneItemPill
          key={item._id}
          item={item}
          onClick={() => onItemClick(item)}
          name={getItemName(item, i18n)}
          confirmAnimation="added"
          onLongClick={() => navigate(`edit/${item._id}`)}
        />
      ))}
    </ItemPillGrid>
  );
};

const ShoppingList = () => {
  const {ItemRepo, CategoryRepo} = useGroupRepos();
  const items = ItemRepo.useGetView("pending_item");
  const handleClickItem = async (item: PouchWrap<ItemEntry>) => {
    await ItemRepo.update({...item, done: true, lastDoneAt: new Date()});
  };
  return items.length === 0 ? (
    <div>No Pending Items</div>
  ) : (
    <ItemListByCategory items={items} onItemClick={handleClickItem} CategoryRepo={CategoryRepo} />
  );
};

const JustPurchasedList = () => {
  const {ItemRepo} = useGroupRepos();
  const rawItems = ItemRepo.useGetView("last_done_item");
  const items = [...rawItems]
    .filter((item) => item.lastDoneAt)
    .slice(-15)
    .reverse();
  const groups = groupByDate({items, extractDate: (item) => item.lastDoneAt!});

  const handleClickItem = async (item: PouchWrap<ItemEntry>) => {
    await ItemRepo.update({...item, done: false, quantity: 1});
  };

  return (
    <>
      <Text as="h2" size="20" color="primary">
        <Trans>Recently purchased</Trans>
      </Text>
      {groups.length === 0 ? (
        <Text color="secondary" size="14_tight">
          <Trans>Nothing</Trans>
        </Text>
      ) : (
        groups.map(({items: groupItems, newestDate}) => (
          <Col key={newestDate.getTime()} sp="8">
            <Text size="16" color="secondary">
              <HumanTime date={newestDate} />
            </Text>
            <ItemList items={groupItems} onItemClick={handleClickItem} />
          </Col>
        ))
      )}
    </>
  );
};

const modalRoutes: RouteObject[] = [
  {
    path: "add",
    element: (
      <RouteDrawer backPath="..">
        <AddItem />
      </RouteDrawer>
    ),
  },
  {
    path: "edit/:itemId",
    element: (
      <RouteDrawer backPath="..">
        <EditItem />
      </RouteDrawer>
    ),
  },
];

const ItemOverview = () => {
  const {GroupRepo, groupId} = useGroupRepos();
  const group = GroupRepo.useGetById(`grp:${groupId}` as GroupId);

  if (!group) return <div>{404}</div>;

  return (
    <AppShell
      header={
        <Header
          currentGroupId={groupId}
          title={
            <>
              {group.name} {group.remoteDbName ? <Icon name="cloud" inline /> : null}
            </>
          }
          actions={
            <>
              <Button variant="tertiary" to="categories">
                <Icon name="sort" />
              </Button>
              <Button variant="tertiary" to="settings">
                <Icon name="vert-ellipsis" />
              </Button>
            </>
          }
        />
      }
      footer={<GroupFooterNav current="list" group={group} />}
    >
      <Col bg="background" px="12" py="8" sp="24">
        <ShoppingList />
        <JustPurchasedList />
        <FloatingButton to="add">
          <Icon name="plus" />
        </FloatingButton>
      </Col>
      <DrawerRoutes routes={modalRoutes} key={groupId} />
    </AppShell>
  );
};

export default ItemOverview;
