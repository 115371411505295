import type {ReactNode} from "react";
import {createContext, useContext, useMemo} from "react";
import type {CategoryRepoType} from "../features/categories/CategoryEntry";
import {createCategoryRepo} from "../features/categories/CategoryEntry";
import type {GroupRepoType, NoPrefixGroupId} from "./GroupEntry";
import {createGroupRepo} from "./GroupEntry";
import type {GroupMemberRepoType} from "./GroupMemberRepo";
import {createGroupMemberRepo} from "./GroupMemberRepo";
import type {ItemRepoType} from "./ItemEntry";
import {createItemRepo} from "./ItemEntry";

export type GroupRepos = {
  CategoryRepo: CategoryRepoType;
  GroupRepo: GroupRepoType;
  GroupMemberRepo: GroupMemberRepoType;
  ItemRepo: ItemRepoType;
};

const repoCache = new Map<NoPrefixGroupId, GroupRepos>();

export const getGroupRepos = (id: NoPrefixGroupId): GroupRepos => {
  const exist = repoCache.get(id);
  if (exist) return exist;
  const repos: GroupRepos = {
    CategoryRepo: createCategoryRepo(id),
    GroupRepo: createGroupRepo(id),
    GroupMemberRepo: createGroupMemberRepo(id),
    ItemRepo: createItemRepo(id),
  };
  repoCache.set(id, repos);
  return repos;
};

const GroupReposContext = createContext<GroupRepos & {groupId: NoPrefixGroupId}>(null as any);

export const ProvideGroupRepos = (props: {groupId: NoPrefixGroupId; children: ReactNode}) => {
  const {groupId, children} = props;
  return (
    <GroupReposContext.Provider
      value={useMemo(() => ({...getGroupRepos(groupId), groupId}), [groupId])}
    >
      {children}
    </GroupReposContext.Provider>
  );
};

export const useGroupRepos = () => useContext(GroupReposContext);
