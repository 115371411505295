import {useUserId} from "../WithAuth";
import {DB_NAMES} from "./DbList";
import type {GroupPreviewRepoType} from "./GroupPreviewEntry";
import {createGroupPreviewRepo} from "./GroupPreviewEntry";
import type {NoPrefixUserId} from "./UserRepo";

export type LocalRepos = {
  LocalGroupPreviewRepo: GroupPreviewRepoType;
};

const repoCache = new Map<NoPrefixUserId, LocalRepos>();

export const getLocalRepos = (id: NoPrefixUserId): LocalRepos => {
  const exist = repoCache.get(id);
  if (exist) return exist;
  const repos: LocalRepos = {
    LocalGroupPreviewRepo: createGroupPreviewRepo(DB_NAMES.Local(id)),
  };
  repoCache.set(id, repos);
  return repos;
};

export const useLocalRepos = () => getLocalRepos(useUserId());
