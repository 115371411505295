type ByKey = <T, K extends keyof T>(list: T[], keyGetter: K) => Map<T[K], T[]>;
type ByKeyFn = <T, K>(list: T[], keyGetter: (el: T) => K) => Map<K, T[]>;

export const groupBy: ByKey & ByKeyFn = (list: any[], keyGetter: any | ((el: any) => any)) => {
  const map = new Map();
  const isFn = typeof keyGetter === "function";
  for (const entry of list) {
    const key = isFn ? keyGetter(entry) : entry[keyGetter];
    const exist = map.get(key);
    if (exist) {
      exist.push(entry);
    } else {
      map.set(key, [entry]);
    }
  }
  return map;
};
export const mapByKey = <T, K extends keyof T>(list: T[], key: K): Map<T[K], T> => {
  const map = new Map<T[K], T>();
  for (const entry of list) {
    map.set(entry[key], entry);
  }
  return map;
};
