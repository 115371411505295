import type {RouteObject} from "react-router";
import {Trans} from "@lingui/macro";
import {useGroupRepos} from "../../models/GroupRepos";
import {Box} from "../../ui/Box";
import {CreateDialog, EditDialog} from "../../features/categories/CategoryDialogs";
import CategoryList from "../../features/categories/CategoryList";
import AppShell from "../../shared/ui/AppShell";
import GroupFooterNav from "../../shared/ui/GroupFooterNav";
import type {GroupId} from "../../models/GroupEntry";
import Header from "../../shared/ui/Header";
import {DrawerRoutes, RouteDrawer} from "../../ui/Drawer";
import Button from "../../ui/Button";

const modalRoutes: RouteObject[] = [
  {
    path: "edit/:itemId",
    element: (
      <RouteDrawer backPath="..">
        <EditDialog />
      </RouteDrawer>
    ),
  },
  {
    path: "create",
    element: (
      <RouteDrawer backPath="..">
        <CreateDialog />
      </RouteDrawer>
    ),
  },
];

// type CategoryOverviewProps = {};
const CategoryOverview = () => {
  const {CategoryRepo, GroupRepo, groupId} = useGroupRepos();
  const group = GroupRepo.useGetById(`grp:${groupId}` as GroupId);
  const cats = CategoryRepo.useGetView("sort_index");

  if (!group) return <div>{404}</div>;

  return (
    <AppShell
      header={
        <Header
          currentGroupId={groupId}
          title={group.name}
          backButton={{to: "..", label: <Trans id={`nav.back`}>Back</Trans>}}
        />
      }
      footer={<GroupFooterNav current="list" group={group} />}
    >
      <Box bg="background" px="12" py="12">
        {cats.length === 0 ? <div>No Categories</div> : <CategoryList cats={cats} />}
        <div>
          <Button to="create">Add Category</Button>
        </div>
        <DrawerRoutes routes={modalRoutes} />
      </Box>
    </AppShell>
  );
};

export default CategoryOverview;
