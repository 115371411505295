import type {ReactNode} from "react";
import {Drawer as Vaul} from "vaul";
import {Suspense, createContext, useContext} from "react";
import type {RouteObject, Location} from "react-router";
import {useLocation, useNavigate, useRoutes} from "react-router";
import {defaultTheme} from "../styles/themes/color-themes.css";
import Spinner from "../shared/ui/Spinner";
import {useMyTransition} from "../utils/use-my-transition";
import useEsc from "../utils/use-esc";
import {Box, Col, css} from "./Box";

type SharedDrawerContentProps = {children: ReactNode};

const Drawer = (props: SharedDrawerContentProps & {open: boolean; onClose: () => void}) => {
  const {children, onClose, open} = props;
  useEsc(onClose);
  return (
    <Vaul.Root shouldScaleBackground open={open} onClose={onClose}>
      <Vaul.Portal>
        <Vaul.Overlay
          className={css(
            {bg: "backdrop", bgAlpha: "80%", position: "fixed", inset: "0"},
            defaultTheme
          )}
        />
        <Vaul.Content
          className={css(
            {
              bg: "surface",
              position: "fixed",
              bottom: "0",
              left: "0",
              right: "0",
              maxHeight: "_drawerHeight",
              display: "flex",
              flexDir: "column",
              roundedTop: "4",
            },
            defaultTheme
          )}
        >
          <Col overflow="auto" px="12" pt="12">
            <Suspense fallback={<Spinner size="48" />}>{children}</Suspense>
          </Col>
          <Col absolute bottom="100%" left="0" right="0" pb="8" align="center">
            <Box
              surface
              height="_drawerHandleHeight"
              width="100%"
              rounded="4"
              maxWidth="_drawerHandleWidth"
            />
          </Col>
        </Vaul.Content>
      </Vaul.Portal>
    </Vaul.Root>
  );
};

type ControlledDrawerProps = SharedDrawerContentProps & {open: boolean; onClose: () => void};
export const ControlledDrawer = (props: ControlledDrawerProps) => {
  const {open, onClose, ...rest} = props;
  const handleClose = () => {
    if (open && onClose) onClose();
  };

  return <Drawer open={open} {...rest} onClose={handleClose} />;
};

const DrawerRouteCtx = createContext<boolean>(null as any);

export const RouteDrawer = (props: {backPath: string} & SharedDrawerContentProps) => {
  const {backPath, ...rest} = props;
  const navigate = useNavigate();
  const unmounting = useContext(DrawerRouteCtx);
  const handleClose = () => {
    if (!unmounting) navigate(backPath);
  };
  return <Drawer onClose={handleClose} open={!unmounting} {...rest} />;
};

const RenderRoutes = (props: {routes: RouteObject[]; location: Location; unmounting: boolean}) => {
  const {routes, location, unmounting} = props;
  const routeEl = useRoutes(routes, location);
  return routeEl && <DrawerRouteCtx.Provider value={unmounting}>{routeEl}</DrawerRouteCtx.Provider>;
};

export const DrawerRoutes = ({routes}: {routes: RouteObject[]}) => {
  const location = useLocation();
  const pathTransitions = useMyTransition(location, location.pathname, {canRecover: false});
  return pathTransitions((loc, unmounting) => (
    <RenderRoutes routes={routes} location={loc} unmounting={unmounting} />
  ));
};
