import {useState} from "react";
import * as v from "valibot";
import type {PouchWrap} from "../../db/Repo";
import useToggle from "../../hooks/useToggle";
import type {NoPrefixUserId, UserEntry} from "../../models/UserRepo";
import {useMe} from "../../models/UserRepo";
import Button from "../../ui/Button";
import {authServerRequest} from "../../authServerRequest";
import {Box, Col, css, Row} from "../../ui/Box";
import {ControlledDrawer} from "../../ui/Drawer";
import {useForm} from "../form/Form";
import {TextField} from "../form/fields";
import {SyncForm} from "./SignUpForm";
import {useAuthIssueStore} from "./auth-issue-store";
import {Trans} from "@lingui/macro";
import {greenTheme} from "../../styles/themes/color-themes.css";

const UnsyncedSection = ({userId, me}: {userId: NoPrefixUserId; me: PouchWrap<UserEntry>}) => {
  const [showForm, {toggle}] = useToggle();
  return (
    <div>
      <Button onClick={toggle}>sync</Button>
      {showForm && <SyncForm userId={userId} me={me} />}
    </div>
  );
};

const loginFormSchema = v.object({
  password: v.pipe(v.string(), v.nonEmpty()),
});

const ForgotPasswordButton = ({userId}: {userId: NoPrefixUserId}) => {
  const [pwMailRequested, setPwMailRequested] = useState(false);
  const handleForgotPw = async () => {
    await authServerRequest({
      path: "/request-password-reset-with-user-id",
      data: {userId},
    });
    setPwMailRequested(true);
  };
  if (pwMailRequested) {
    return (
      <Box
        className={greenTheme}
        bg="surface"
        rounded="4"
        pa="8"
        sp="8"
        color="primary"
        textAlign="center"
      >
        <Trans>Reset mail requested</Trans>
      </Box>
    );
  } else {
    return (
      <Button variant="tertiary" onClick={handleForgotPw}>
        <Trans>Send mail to reset password</Trans>
      </Button>
    );
  }
};

const LoginModalContent = ({userId}: {userId: NoPrefixUserId}) => {
  const {field, Form} = useForm({
    initial: {password: ""},
    schema: loginFormSchema,
    onSubmit: async ({password}) => {
      await authServerRequest({
        path: "/re-auth",
        data: {userId, password},
      });
      window.location.reload();
    },
  });
  return (
    <Col pa="16" sp="12" width="100%" maxWidth="formWidth" ml="auto" mr="auto">
      <Form
        buttonLabel={<Trans>Re-Auth</Trans>}
        className={css({sp: "16", display: "flex", flexDir: "column"})}
      >
        <TextField type="password" field={field("password")} label={<Trans>Password</Trans>} />
      </Form>
      <ForgotPasswordButton userId={userId} />
    </Col>
  );
};

const WithSyncIssue = ({userId}: {userId: NoPrefixUserId}) => {
  const [showForm, {on, off}] = useToggle();
  return (
    <Row sp="8" px="12" py="8" align="center" justify="center">
      <ControlledDrawer open={showForm} onClose={off}>
        <LoginModalContent userId={userId} />
      </ControlledDrawer>
      <Box size="14_tight" color="secondary">
        <Trans>Disconnected</Trans>
      </Box>
      <Button variant="secondary" onClick={on}>
        <Trans>Re-Auth</Trans>
      </Button>
    </Row>
  );
};

export const ShowSyncIssue = () => {
  const {me, userId} = useMe();
  if (!me.remoteDbName) return null;
  return <InnerShowSyncIssue userId={userId} />;
};

const InnerShowSyncIssue = ({userId}: {userId: NoPrefixUserId}) => {
  const hasIssue = useAuthIssueStore((s) => s.hasAuthIssue);
  if (!hasIssue) return null;
  return <WithSyncIssue userId={userId} />;
};

const UserSyncSection = () => {
  const {me, userId} = useMe();
  if (!me.remoteDbName) {
    return <UnsyncedSection userId={userId} me={me} />;
  } else {
    return <div>Synced {me.name}</div>;
  }
};

export default UserSyncSection;
