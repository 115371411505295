import {forwardRef, type ReactNode} from "react";
import {Trans} from "@lingui/react";
import type {InputProps, SelectProps} from "../../ui/Input";
import {Input, Select} from "../../ui/Input";
import {Box, Col, Row, css} from "../../ui/Box";
import Spinner from "../../shared/ui/Spinner";
import type {NeoField, UseMetaArgs} from "./lib/create-neo-form";

const shouldShowError = (meta: UseMetaArgs) => {
  if (meta.errors === null || meta.errors.length === 0) return false;
  return (meta.hasChanged && !meta.isFocussed) || meta.submitAttemptCount > 0;
};

type WithLabelProps = {
  field: NeoField<any>;
  label?: ReactNode | null;
  hint?: ReactNode;
  children: ReactNode;
};
export const WithLabel = ({field, label = field.name, children, hint}: WithLabelProps) => {
  const {useMeta, id} = field;
  const {errors, isPending} = useMeta((meta) => ({
    errors: shouldShowError(meta) ? meta.errors : [],
    isPending: meta.isPending,
  }));
  return (
    <Col sp="4">
      {label && (
        <label htmlFor={id} className={css({color: "secondary", weight: "bold", size: "14_tight"})}>
          {label}
          {isPending && <Spinner size="16" inline />}
        </label>
      )}
      {children}
      {errors.length > 0 && (
        <Row wrap sp="4">
          {errors.map((e) => (
            <Box style={{color: "red"}} key={e.code}>
              <Trans
                id={`validation.${e.code}`}
                message={e.message}
                values={{requirement: e.ctx.requirement, received: e.ctx.received}}
              />
            </Box>
          ))}
        </Row>
      )}
      {hint && typeof hint === "string" ? <div style={{color: "gray"}}>{hint}</div> : hint}
    </Col>
  );
};

type MinusFieldProps<T> = Omit<T, "onChange" | "onBlur" | "onFocus" | "id" | "value" | "ref">;

type TextFieldProps = {
  field: NeoField<string>;
  label?: ReactNode | null;
  hint?: ReactNode;
} & MinusFieldProps<InputProps>;
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({field, label, hint, ...rest}, ref) => {
    const {useValue, useMeta, getHandlers, name, id} = field;
    const showErrors = useMeta((meta) => shouldShowError(meta));
    return (
      <WithLabel field={field} label={label} hint={hint}>
        <Input
          name={name}
          id={id}
          {...rest}
          {...getHandlers({...rest, ref})}
          value={useValue()}
          hasError={showErrors}
        />
      </WithLabel>
    );
  }
);

type SelectFieldProps = {
  field: NeoField<string | null>;
  label?: string;
  hint?: ReactNode;
} & MinusFieldProps<SelectProps>;
export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  ({field, label, hint, ...rest}, ref) => {
    const {getHandlers, useValue, useMeta, id, name} = field;
    const showErrors = useMeta((meta) => shouldShowError(meta));
    return (
      <WithLabel field={field} label={label} hint={hint}>
        <Select
          name={name}
          id={id}
          {...rest}
          {...getHandlers({...rest, ref})}
          value={useValue()}
          hasError={showErrors}
        />
      </WithLabel>
    );
  }
);
