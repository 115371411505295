import {useParams} from "react-router";
import * as v from "valibot";
import {Trans} from "@lingui/macro";
import {authServerRequest} from "../../authServerRequest";
import {useToken} from "../../features/tokens/useToken";
import {useForm} from "../../features/form/Form";
import {TextField} from "../../features/form/fields";
import AppShell from "../../shared/ui/AppShell";
import {Col, css} from "../../ui/Box";

const resetPasswordSchema = v.object({
  newPassword: v.pipe(v.string(), v.nonEmpty(), v.minLength(8)),
});

const ResetPassword = () => {
  const {token} = useParams<{token: string}>();
  const {Form, field} = useForm({
    initial: {newPassword: ""},
    schema: resetPasswordSchema,
    onSubmit: async ({newPassword}) => {
      await authServerRequest({path: `/tokens/apply/reset-password/${token}`, data: {newPassword}});
      window.location.href = "/";
    },
  });

  const tokenState = useToken({token, type: "reset-password"});
  return (
    <AppShell>
      <Col bg="background" px="12" py="24" align="center" sp="48">
        <Col rounded="4" surface pa="16" sp="12" width="100%" maxWidth="formWidth">
          {!tokenState.success ? (
            tokenState.renderEl
          ) : (
            <Form
              buttonLabel="Reset password"
              className={css({sp: "16", display: "flex", flexDir: "column"})}
            >
              <TextField
                type="password"
                field={field("newPassword")}
                label={<Trans>New Password</Trans>}
              />
            </Form>
          )}
        </Col>
      </Col>
    </AppShell>
  );
};

export default ResetPassword;
