import type {Messages} from "@lingui/core";
import {i18n} from "@lingui/core";
import {I18nProvider} from "@lingui/react";
import type {Dispatch, ReactNode, SetStateAction} from "react";
import {useEffect, createContext, useContext, useCallback, useTransition, useState} from "react";
import {StorageKey, storageWrapper} from "./storage";

const messageFiles = import.meta.glob("../locales/*.mjs");

export type Lang = "en" | "de";
const availableLocales: Set<Lang> = new Set(["en", "de"]);

const _messageCache: {[lang: string]: Messages | Promise<unknown>} = {};

let _currLoadLang: Lang | null = null;

const loadLang = (lang: Lang) => {
  const msgFn = messageFiles[`../locales/${lang}.mjs`];
  const promise = msgFn()
    .then((localesFile) => {
      const msg: Messages = (localesFile as any).messages;
      _messageCache[lang] = msg;
      // return new Promise<Messages>((res) => setTimeout(() => res(msg), 1000));
      return msg;
    })
    .then((msg) => {
      if (_currLoadLang === lang) {
        i18n.load(lang, msg);
        i18n.activate(lang);
      }
    });
  _messageCache[lang] = promise;
  return promise;
};

const attemptToSetLang = (lang: Lang): void => {
  const exist = _messageCache[lang];
  if (exist) {
    if (exist instanceof Promise) return;
    setTimeout(() => {
      i18n.load(lang, exist);
      i18n.activate(lang);
    });
  } else {
    void loadLang(lang);
  }
};

const useEnsureMessageLoaded = (lang: Lang): void => {
  _currLoadLang = lang;
  const exist = _messageCache[lang];
  if (exist) {
    if (exist instanceof Promise) throw exist;
    return;
  } else {
    throw loadLang(lang);
  }
};

const getPreferredLang = (): Lang => {
  const storageVal = storageWrapper.storageGet(StorageKey.LANG);
  if (storageVal && availableLocales.has(storageVal)) return storageVal;

  const browserLang: string = navigator.language || (navigator as any).userLanguage || "";
  const first = browserLang.slice(0, 2).toLowerCase() as Lang;
  if (availableLocales.has(first)) return first;

  return "en";
};

export const useSetLang = () => {
  const [pending, startTransition] = useTransition();
  const setRawLang = useContext(LangCtx);
  const setLang = useCallback<Dispatch<SetStateAction<Lang>>>(
    (arg) =>
      startTransition(() => {
        setRawLang((prev) => {
          const val = arg instanceof Function ? arg(prev) : arg;
          storageWrapper.storageSet(StorageKey.LANG, val);
          attemptToSetLang(val);
          return val;
        });
      }),
    [setRawLang]
  );
  return [pending, setLang] as const;
};

const LangCtx = createContext<Dispatch<SetStateAction<Lang>>>(null as any);

export const I18n = ({children}: {children: ReactNode}) => {
  const [lang, setRawLang] = useState<Lang>(getPreferredLang);

  useEffect(() => {
    document.documentElement.lang = lang;
  }, [lang]);

  useEnsureMessageLoaded(lang);
  return (
    <LangCtx.Provider value={setRawLang}>
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </LangCtx.Provider>
  );
};
