import {useRef} from "react";
import type {AsyncValidationValue} from "./AsyncCheckManager";

export type DeepIndex<T, K extends string> = T extends object
  ? K extends `${infer F}.${infer R}`
    ? DeepIndex<Idx<T, F>, R>
    : Idx<T, K>
  : never;

type Idx<T, K extends string> = K extends keyof T
  ? T[K]
  : K extends `${number}`
    ? number extends keyof T
      ? T[number]
      : never
    : never;

export const pathInto = <T, K extends string>(obj: T, path: K): DeepIndex<T, K> => {
  const pathArr = path.split(".");
  let currObj: any = obj;
  for (const key of pathArr) {
    if (!(key in currObj)) return undefined as any;
    currObj = currObj[key];
  }
  return currObj;
};

export const setIntoPath = <T, K extends string>(obj: T, path: K, val: DeepIndex<T, K>): void => {
  const pathArr = path.split(".");
  let currObj: any = obj;
  for (const key of pathArr.slice(0, -1)) {
    currObj = currObj[key];
    if (!currObj) currObj = {};
  }
  currObj[pathArr.slice(-1)[0]] = val;
};

export const tryPathInto = <T, K extends string>(
  obj: T,
  path: K
): {ok: true; value: DeepIndex<T, K>} | {ok: false} => {
  const pathArr = path.split(".");
  let currObj: any = obj;
  for (const key of pathArr) {
    if (!(key in currObj)) return {ok: false};
    currObj = currObj[key];
  }
  return {ok: true, value: currObj};
};

export type FormDataSlice<TSlice> = {
  useValue: <T = TSlice>(getter?: (slice: TSlice) => T) => T;
  setValue: (data: TSlice) => void;
};

export type FieldValues = Record<string, any>;

export type FieldMeta = {
  isFocussed: boolean;
  hasChanged: boolean;
  visited: boolean;
};

export type NeoFormError = {code: string; message?: string; ctx?: any};
export type NeoFormErrors = Record<string, NeoFormError[]>;

export type Adapter<TInput extends FieldValues, TParsed extends FieldValues> = {
  parse: (values: TInput) => ParseResult<TParsed>;
};

export type ParseResult<TParsed extends FieldValues> =
  | {
      errorsByPath: null;
      values: TParsed;
    }
  | {
      errorsByPath: NeoFormErrors;
      values: Partial<TParsed>;
    };

export type StoreContent<TInput extends FieldValues, TParsed extends FieldValues> = {
  inputState: {_ref: TInput};
  parsedState: ParseResult<TParsed>;
  fieldMetaByPath: {_ref: Record<string, FieldMeta>};
  asyncChecksByPath: {_ref: Record<string, AsyncValidationValue>};
  submitAttemptCount: number;
  hasChangedAfterSubmit: boolean;
};

export const deepEqual = <T extends any>(obj1: T, obj2: T): boolean => {
  if (obj1 === obj2) return true;

  if (obj1 && obj2 && typeof obj1 === "object" && typeof obj2 === "object") {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

    for (let key in obj1) {
      if (!Object.prototype.hasOwnProperty.call(obj2, key)) return false;
      if (!deepEqual(obj1[key], obj2[key])) return false;
    }
    return true;
  }
  return false;
};

export const useDeepEqual = <S, U>(selector: (state: S) => U): ((state: S) => U) => {
  const prev = useRef<U>();

  return (state) => {
    const next = selector(state);
    return deepEqual(prev.current, next) ? (prev.current as U) : (prev.current = next);
  };
};

export const mergeErrors = <TInput extends FieldValues, TParsed extends FieldValues>(
  s: StoreContent<TInput, TParsed>
): null | NeoFormErrors => {
  const errors = s.parsedState.errorsByPath;
  const asyncErrors = Object.entries(s.asyncChecksByPath._ref)
    .map(([key, val]) => [key, asyncErrorToNeoFormError(val)!] as const)
    .filter(([, val]) => val !== null);
  if (!asyncErrors.length) return errors;
  const merged = {...errors} as NeoFormErrors;
  for (const [key, val] of asyncErrors) {
    const exists = merged[key];
    if (exists) {
      exists.push(val);
    } else {
      merged[key] = [val];
    }
  }
  return merged;
};

export const asyncErrorToNeoFormError = (
  asyncError?: AsyncValidationValue
): NeoFormError | null => {
  if (!asyncError) return null;
  if (asyncError.state === "resolved" && !asyncError.isValid) {
    return asyncError;
  } else {
    return null;
  }
};

type OmitProps<T extends React.ComponentType<any>, TOmitProps> = T extends React.ComponentType<
  infer Props
>
  ? React.ComponentType<Omit<Props, keyof TOmitProps>>
  : never;

export const useBuildFormComponent = <TForm extends React.ComponentType<any>, TFormProps>(
  Form: TForm,
  props: TFormProps
) => {
  const propRef = useRef<TFormProps>(props);
  propRef.current = props;
  const formRef = useRef<OmitProps<TForm, TFormProps> | null>(null);
  if (!formRef.current) {
    formRef.current = ((userProps: any) => {
      return <Form {...propRef.current} {...userProps} />;
    }) as any;
  }
  return formRef.current!;
};
