import type {ReactNode} from "react";
import {Box, Col, StyleChild} from "../../ui/Box";
import {makeScrollable} from "../../utils/makeScrollable";
import {defaultTheme} from "../../styles/themes/color-themes.css";

type AppShellProps = {header?: ReactNode; children: ReactNode; footer?: ReactNode};
const AppShell = ({children, header, footer}: AppShellProps) => {
  return (
    <StyleChild
      display="flex"
      flexDir="column"
      flex="auto"
      maxWidth="_appShellWidth"
      width="100%"
      ml="auto"
      mr="auto"
      elevation="200"
      bg="background"
      className={defaultTheme}
      color="primary"
    >
      <div vaul-drawer-wrapper="">
        {header}
        <Box flex="auto" relative className={makeScrollable({dir: "vertical"})}>
          <Col absolute top="0" left="0" right="0" minHeight="100%">
            {children}
          </Col>
        </Box>
        {footer}
      </div>
    </StyleChild>
  );
};

export default AppShell;
