import {useNavigate, useParams} from "react-router";
import {Box, Col} from "../../ui/Box";
import type {ItemId} from "../../models/ItemEntry";
import Button from "../../ui/Button";
import Icon from "../../ui/Icon";
import RefineItem from "./RefineItem";

const EditItem = () => {
  const params = useParams<{itemId: string}>();
  const navigate = useNavigate();
  const onClose = () => navigate("..");

  return (
    <Col sp="12" height="_addItemDrawerHeight">
      <Box absolute right="0" top="0" pt="4" pr="4">
        <Button onClick={onClose} variant="tertiary">
          <Icon name="close" />
        </Button>
      </Box>
      <RefineItem id={params.itemId as ItemId} onClose={onClose} />
    </Col>
  );
};

export default EditItem;
