import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {Trans} from "@lingui/macro";
import {authServerRequest} from "../../authServerRequest";
import {addMessage} from "../../features/messenger/messenger";
import AppShell from "../../shared/ui/AppShell";
import {Col} from "../../ui/Box";
import Header from "../../shared/ui/Header";

const useResult = () => {
  const {token} = useParams();
  const navigation = useNavigate();
  const [state, setState] = useState<
    {step: "initial" | "loading"} | {step: "error"; error: string}
  >({step: "initial"});
  useEffect(() => {
    setState({step: "loading"});
    authServerRequest({path: `/tokens/apply/verify-email/${token}`}).then(
      () => {
        addMessage("Email has been verified");
        navigation("/", {replace: true});
      },
      (err) => {
        setState({step: "error", error: err.message});
      }
    );
  }, [token, navigation]);
  switch (state.step) {
    case "initial":
    case "loading": {
      return <Trans>Verifying...</Trans>;
    }
    case "error": {
      return (
        <div>
          <Trans>Error</Trans>: {state.error}
        </div>
      );
    }
    default: {
      return <div>Unknown step: {(state as any).step}</div>;
    }
  }
};

const VerifyEmail = () => {
  const result = useResult();
  return (
    <AppShell
      header={
        <Header
          currentGroupId={null}
          title={<Trans>Verify Email</Trans>}
          backButton={{to: "/", label: <Trans id={`nav.back`}>Back</Trans>}}
          skipChechSync
        />
      }
    >
      <Col bg="background" px="12" py="24" align="center" sp="48" color="primary" size="14_tight">
        {result}
      </Col>
    </AppShell>
  );
};

export default VerifyEmail;
