import {useNavigate, useParams} from "react-router";
import * as v from "valibot";
import {useLingui} from "@lingui/react";
import {Trans} from "@lingui/macro";
import type {PouchWrap} from "../../db/Repo";
import {useGroupRepos} from "../../models/GroupRepos";
import Button from "../../ui/Button";
import {createSorter} from "../../utils/sort-utils";
import Icon from "../../ui/Icon";
import {StyleChild} from "../../ui/Box";
import type {ItemRepoType} from "../../models/ItemEntry";
import {useForm} from "../form/Form";
import {TextField} from "../form/fields";
import {isEmoji} from "../../utils";
import {
  getCategoryName,
  type CategoryEntry,
  type CategoryId,
  type CategoryRepoType,
} from "./CategoryEntry";

const categoryFormSchema = v.object({
  categoryName: v.pipe(v.string(), v.nonEmpty(), v.maxLength(256)),
  emoji: v.pipe(v.string(), v.nonEmpty(), v.check(isEmoji, "no_emoji")),
});

export const CreateDialog = () => {
  const {CategoryRepo} = useGroupRepos();
  const cats = CategoryRepo.useGetView("sort_index");
  const navigate = useNavigate();
  const {Form, field} = useForm({
    initial: {categoryName: "", emoji: "🛒"},
    schema: categoryFormSchema,
    onSubmit: async ({categoryName, emoji}) => {
      const lastCat = cats.length > 0 ? cats[cats.length - 1] : null;
      const sortIndex = createSorter().addAtEnd({
        currLastValue: lastCat ? lastCat.sortIndex : null,
        expectedRemainingCount: 20,
      });
      await CategoryRepo.create({
        createdAt: new Date(),
        emoji,
        name: categoryName,
        sortIndex,
        custom: true,
      });
      navigate("..");
    },
  });

  return (
    <div>
      <h1>Add Category</h1>
      <button onClick={() => navigate("..")}>
        <Icon name="close" />
      </button>
      <StyleChild display="flex" flexDir="column" sp="16">
        <Form>
          <TextField field={field("categoryName")} label={<Trans>Name</Trans>} autoFocus />
          <TextField field={field("emoji")} label={<Trans>Emoji</Trans>} />
        </Form>
      </StyleChild>
    </div>
  );
};

type InnerEditProps = {
  cat: PouchWrap<CategoryEntry>;
  CategoryRepo: CategoryRepoType;
  ItemRepo: ItemRepoType;
};

const InnerEdit = ({CategoryRepo, ItemRepo, cat}: InnerEditProps) => {
  const navigate = useNavigate();
  const handleDelete = async () => {
    const items = await ItemRepo.getAllPromise();
    const affectedItems = items.filter((i) => i.categoryId === cat._id);
    if (affectedItems.length > 0) {
      await ItemRepo.updateAll(affectedItems.map((i) => ({...i, categoryId: null})));
    }
    await CategoryRepo.delete(cat);
    navigate("..");
  };

  const {i18n} = useLingui();
  const name = getCategoryName(cat, i18n);

  const {Form, field} = useForm({
    initial: {categoryName: name, emoji: cat.emoji},
    schema: categoryFormSchema,
    onSubmit: async ({categoryName, emoji}) => {
      const getCustom = (): Partial<CategoryEntry> => {
        if (!cat.custom && categoryName === name) return {};
        return {custom: true, name: categoryName};
      };
      await CategoryRepo.update({...cat, emoji, ...getCustom()});
      navigate("..");
    },
  });
  return (
    <div>
      <h1>Edit {name} Category</h1>
      <div>
        <Button onClick={handleDelete}>del</Button>
      </div>
      <Form>
        <TextField label="Name" field={field("categoryName")} autoFocus />
        <TextField label="Emoji" field={field("emoji")} />
      </Form>
    </div>
  );
};

export const EditDialog = () => {
  const {CategoryRepo, ItemRepo} = useGroupRepos();
  const params = useParams<{itemId: string}>();
  const cat = CategoryRepo.useGetById(params.itemId as CategoryId);
  return cat ? (
    <InnerEdit cat={cat} CategoryRepo={CategoryRepo} ItemRepo={ItemRepo} />
  ) : (
    <div>404</div>
  );
};
