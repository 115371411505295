import type * as v from "valibot";
import {useEffect, useRef} from "react";
import type {ReactNode, ComponentProps} from "react";
import {addMessage} from "../messenger/messenger";
import {errorToString} from "../../utils";
import Button from "../../ui/Button";
import {css} from "../../ui/Box";
import type {ValibotFormArgs} from "./lib/create-valibot-neo-form";
import {useValibotNeoForm} from "./lib/create-valibot-neo-form";
import type {FormDataSlice} from "./lib/utils";
import {useBuildFormComponent} from "./lib/utils";

type FormState = "loading" | "initial" | "success" | "error";

type MyFormProps = {
  buttonLabel?: ReactNode;
  formStateSlice: FormDataSlice<FormState>;
  submitOnBlur?: boolean;
} & ComponentProps<"form">;

const MyForm = ({
  buttonLabel = "Save",
  formStateSlice,
  submitOnBlur,
  children,
  ...rest
}: MyFormProps) => {
  const state = formStateSlice.useValue();
  return (
    <form {...rest}>
      {children}
      {!submitOnBlur && (
        <Button type="submit" variant="primary" className={css({mr: "auto"})}>
          {buttonLabel}
          {state === "loading"
            ? " ..."
            : state === "success"
              ? ` ✅`
              : state === "error"
                ? ` ❌`
                : null}
        </Button>
      )}
    </form>
  );
};

type Form2Props<TSchema extends v.BaseSchema<any, any, any>> = ValibotFormArgs<TSchema>;
export const useForm = <TSchema extends v.BaseSchema<any, any, any>>(args: Form2Props<TSchema>) => {
  const {onSubmit, ...rest} = args;
  const timeoutIdRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    };
  }, []);

  const handleSubmit = async (values: v.InferOutput<TSchema>) => {
    const res = args.onSubmit(values);
    if (res && "then" in res) {
      formStateSlice.setValue("loading");
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
      res.then(
        () => {
          formStateSlice.setValue("success");
          timeoutIdRef.current = setTimeout(() => {
            formStateSlice.setValue("initial");
          }, 2000);
        },
        (e) => {
          formStateSlice.setValue("error");
          timeoutIdRef.current = setTimeout(() => {
            formStateSlice.setValue("initial");
          }, 2000);
          addMessage(errorToString(e), {type: "error"});
        }
      );
    }
  };

  const formResult = useValibotNeoForm({...rest, onSubmit: handleSubmit});
  const {onSubmit: _formSubmit, createCustomDataSlice, ...neoFormProps} = formResult;
  const formStateSlice = createCustomDataSlice<FormState>("formState", "initial");

  const formResultRef = useRef(formResult);
  formResultRef.current = formResult;

  useEffect(() => {
    if (rest.submitOnBlur) {
      return () => {
        const {onSubmit: submit, _store} = formResultRef.current;
        if (_store.getState().hasChangedAfterSubmit) submit();
      };
    }
  }, [rest.submitOnBlur]);

  const Form = useBuildFormComponent(MyForm, {
    onSubmit: _formSubmit,
    formStateSlice,
    submitOnBlur: rest.submitOnBlur,
  });

  return {...neoFormProps, Form};
};
