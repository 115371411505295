import {memo, useLayoutEffect, useRef} from "react";
import {Link} from "react-router-dom";
import type {I18nContext} from "@lingui/react";
import {useLingui} from "@lingui/react";
import type {PouchWrap} from "../../db/Repo";
import {Col, Row, StyleChild, Text} from "../../ui/Box";
import {useDndItem, useDndList} from "../../utils/list-dnd/use-dnd-list";
import {findClosestScrollableParent} from "../../dom-utils";
import {createSorter, moveAndEnsureBalance} from "../../utils/sort-utils";
import {useGroupRepos} from "../../models/GroupRepos";
import {findNearestIndexViaBinarySearch} from "../../utils";
import {getCategoryName, type CategoryEntry} from "./CategoryEntry";

type CategoryTileProps = {
  cat: PouchWrap<CategoryEntry>;
  startPx: number;
  index: number;
  i18n: I18nContext["i18n"];
};
const CategoryTile = memo((props: CategoryTileProps) => {
  const {cat, index, startPx, i18n} = props;
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const {handlers, transform} = useDndItem({nodeRef, index, startPx, key: cat._id});

  return (
    <Row
      sp="16"
      align="baseline"
      surface
      px="8"
      py="8"
      size="16"
      style={{transform}}
      absolute
      top="0"
      left="0"
      width="100%"
      rounded="4"
      {...handlers}
      ref={nodeRef}
    >
      <Text whiteSpace="nowrap">
        {/* lingui-extract-ignore */}
        {cat.emoji} {getCategoryName(cat, i18n)}
      </Text>
      <StyleChild size="12" color="secondary" display="block">
        <Link to={`edit/${cat._id}`}>Edit</Link>
      </StyleChild>
    </Row>
  );
});

const LIST_GAP = 4;

type CategoryListProps = {
  cats: PouchWrap<CategoryEntry>[];
};
const CategoryList = (props: CategoryListProps) => {
  const {cats} = props;
  const {CategoryRepo} = useGroupRepos();
  const nodeRef = useRef<HTMLElement | null>(null);
  const scrollNodeRef = useRef<HTMLElement | null>(null);
  const {i18n} = useLingui();

  useLayoutEffect(() => {
    // This approach was chosen over passing a ref from the parent as
    // useVirtual uses useLayoutEffect, and children's useLayoutEffect are
    // called _before_ the parent gets a chance to set refs (both via useRef
    // and callback refs)
    scrollNodeRef.current = findClosestScrollableParent(nodeRef.current!) as HTMLElement;
  }, []);

  const {height, virtualItems} = useDndList({
    count: cats.length,
    getScrollElement: () => scrollNodeRef.current,
    gap: LIST_GAP,
    height: 18 + 24,
    onDrop: async ({targetIndex, startIndex, key}) => {
      if (targetIndex === startIndex) return;
      let before;
      let after;
      if (startIndex > targetIndex) {
        // move up
        before = targetIndex > 0 ? cats[targetIndex - 1] : null;
        after = cats[targetIndex];
        // console.log("MOVE UP", before?.sortIndex, after.sortIndex);
      } else {
        before = cats[targetIndex];
        after = targetIndex < cats.length - 1 ? cats[targetIndex + 1] : null;
      }
      const updateEls = await moveAndEnsureBalance({
        targetEl: cats[startIndex],
        prevEl: before,
        nextEl: after,
        getSortIndex: (el) => el.sortIndex,
        sorter: createSorter(),
        getSortedElementsBetween: async (start, end) => {
          const [startIdx] = findNearestIndexViaBinarySearch(cats, start, (cat) => cat.sortIndex);
          const [, endIdx] = findNearestIndexViaBinarySearch(cats, end, (cat) => cat.sortIndex);
          const els = cats.slice(Math.max(0, startIdx), Math.min(cats.length - 1, endIdx) + 1);
          return els;
        },
      });
      await CategoryRepo.updateAll(updateEls.map(([cat, sortIndex]) => ({...cat, sortIndex})));
    },
  });

  return (
    <Col relative style={{height}} ref={nodeRef}>
      {virtualItems.map((item) => {
        const cat = cats[item.index];
        return (
          <CategoryTile
            key={cat._id}
            cat={cat}
            index={item.index}
            startPx={item.start}
            i18n={i18n}
          />
        );
      })}
    </Col>
  );
};

export default CategoryList;
