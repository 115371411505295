import {forwardRef, cloneElement} from "react";
import type {CSSProperties, ReactNode} from "react";
import dsStyles from "../styles/index.css";
import type {DsStyles as RawStyleProps} from "../styles/index.css";
import cx from "../utils/cx";

type Booleanify<E> = E extends "true" ? true : E;
export type StyleProps = {
  [Key in keyof RawStyleProps]?: Booleanify<keyof RawStyleProps[Key]> | false;
};

export type BoxProps = StyleProps & {
  className?: string;
  id?: string;
  as?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  style?: CSSProperties;
};

const applyProps = (props: BoxProps, defaultComp: keyof JSX.IntrinsicElements) => {
  let Comp: any = defaultComp;
  const compProps: any = {};
  const classList = [];
  for (const prop in props) {
    const val = props[prop as keyof BoxProps];
    if (val === null || val === undefined) continue;
    switch (prop) {
      case "as":
        Comp = val;
        break;
      case "children":
      case "style":
      case "id":
        compProps[prop] = val;
        break;
      case "className":
        classList.push(val);
        break;
      default:
        const s = dsStyles[prop as keyof StyleProps];
        if (!s) {
          // let's allow `onMouseOver` etc, otherwise WithTooltip etc will become a pain?
          if (/^on[A-Z]/.test(prop)) {
            compProps[prop] = val;
          } else {
            console.warn(`Can't apply style prop '${prop}' with value '${val as any}'`);
          }
          continue;
        }
        if (!val && val !== 0) continue;
        classList.push((s as any)[val as any]);
    }
  }
  if (classList.length) {
    compProps.className = classList.join(" ");
  }
  return {Comp, compProps};
};

const createStyleComponent = (
  defaultProps: StyleProps,
  defaultComp: keyof JSX.IntrinsicElements = "div"
) => {
  return forwardRef<HTMLElement, BoxProps>((props, ref) => {
    const combinedProps = {...defaultProps, ...props} as BoxProps;
    const {Comp, compProps} = applyProps(combinedProps, defaultComp);
    return <Comp {...compProps} ref={ref} />;
  });
};

export const StyleChild = forwardRef<
  HTMLElement,
  StyleProps & {children: ReactNode; className?: string}
>((props, ref) => {
  const {compProps} = applyProps(props, "div");
  return cloneElement(compProps.children as any, {
    className: cx(compProps.children.props.className, compProps.className),
    style: {...compProps.children.props.style, ...compProps.style},
    ref,
  });
});
export const Box = createStyleComponent({}, "div");
export const Row = createStyleComponent({display: "flex", flexDir: "row"}, "div");
export const Col = createStyleComponent({display: "flex", flexDir: "column"}, "div");
export const Text = createStyleComponent({textOverflow: "ellipsis"}, "div");

export type CSSProps = {
  [Key in keyof RawStyleProps]?: keyof RawStyleProps[Key];
};

export const css = (props: CSSProps, ...classNames: (string | undefined | null | false)[]) => {
  const l: string[] = [...(classNames.filter(Boolean) as string[])];
  for (const prop in props) {
    const val = props[prop as keyof StyleProps];
    if (val === null || val === undefined) continue;
    const s = dsStyles[prop as keyof StyleProps];
    l.push((s as any)[val as any]);
  }
  return l.join(" ");
};
