import type {SymbolTable} from "mudder";
import {base62 as mudder} from "mudder";
import {findIndexViaBinarySearch} from "../utils";

export type Sorter = {
  addAtEnd: (opts: {currLastValue: string | null; expectedRemainingCount: number}) => string;
  addAtStart: (opts: {currFirstValue: string | null; expectedRemainingCount: number}) => string;
  spreadBetween: (start: string | null, end: string | null, count: number) => string[];
  getMudder(): SymbolTable;
  lastChar: string;
  firstChar: string;
};

export const createSorter = (): Sorter => {
  const lastChar = mudder.num2sym[mudder.maxBase - 1];
  const firstChar = mudder.num2sym[0];

  return {
    addAtEnd: ({currLastValue, expectedRemainingCount}) =>
      mudder.mudder(currLastValue || "", "", 1, undefined, expectedRemainingCount)[0],
    addAtStart: ({currFirstValue, expectedRemainingCount}) => {
      const precision = Math.max(
        0,
        Math.ceil(Math.log(expectedRemainingCount) / Math.log(mudder.maxBase)) - 1
      );
      return mudder.mudder(
        currFirstValue || lastChar,
        firstChar,
        1,
        undefined,
        expectedRemainingCount,
        precision
      )[0];
    },
    spreadBetween: (start: string | null, end: string | null, count: number): string[] => {
      return mudder.mudder(start || "", end || "", count);
    },
    getMudder: () => mudder,
    lastChar,
    firstChar,
  };
};

const getNormalizedLowerHigher = (lower: string, higher: string): [string, string, number] => {
  const ll = lower.length;
  const hl = higher.length;
  if (ll === hl) return [lower, higher, ll];
  if (ll < hl) {
    return [lower.padEnd(hl, "0"), higher, hl];
  } else {
    return [lower, higher.padEnd(ll, "0"), ll];
  }
};

const getDiffAsNumber = (rawLower: string, rawHigher: string, symbolTable: SymbolTable): number => {
  if (rawHigher.length === 0) {
    const max = symbolTable.maxBase ** rawLower.length;
    return (max - symbolTable.stringToNumber(rawLower)) / max;
  }
  const [lower, higher, precision] = getNormalizedLowerHigher(rawLower, rawHigher);
  if (precision > 32) return 0;
  const diff = symbolTable.stringToNumber(higher) - symbolTable.stringToNumber(lower);
  return diff / symbolTable.maxBase ** precision;
};

type NeedRebalanceOpts = {
  lower: string;
  higher: string;
  count?: number;
  threshold?: number;
  sorter: Sorter;
};
export const needsRebalance = (opts: NeedRebalanceOpts) => {
  const {lower, higher, count = 1, threshold = 100_000_000, sorter} = opts;
  const diff = getDiffAsNumber(lower, higher, sorter.getMudder());
  return diff / count < 1 / threshold;
};

type ElementWithSortIndex<T> = readonly [T, string];

// TODO: would be great if getSortedElementsBetween would return a the left and right neighbor as well
type BalanceOpts<T> = {
  targetEl: T;
  prevEl: T | null;
  nextEl: T | null;
  getSortIndex: (el: T) => string;
  getSortedElementsBetween: (start: string, end: string) => Promise<T[]>;
  sorter: Sorter;
};
export const moveAndEnsureBalance = async <T>(
  opts: BalanceOpts<T>
): Promise<ElementWithSortIndex<T>[]> => {
  const {targetEl, prevEl, nextEl, sorter, getSortIndex} = opts;
  const {firstChar, lastChar} = sorter;
  const start = prevEl ? getSortIndex(prevEl) : firstChar;
  const end = nextEl ? getSortIndex(nextEl) : lastChar;
  if (!needsRebalance({lower: start, higher: end, sorter})) {
    const nextIdx = sorter.spreadBetween(start, end, 1)[0];
    return [[targetEl, nextIdx]];
  } else {
    const symbolTable = sorter.getMudder();
    const getRangeEnd = () => {
      if (end === lastChar) return lastChar;
      const endChar = end[0];
      const endNumber = symbolTable.stringToNumber(endChar);
      if (endNumber + 1 < symbolTable.maxBase) return symbolTable.num2sym[endNumber + 1];
      const digits = [...symbolTable.stringToDigits(end), symbolTable.maxBase - 1];
      return symbolTable.digitsToString(digits);
    };
    const range = [start[0], getRangeEnd()];
    const toRebalance = await opts.getSortedElementsBetween(range[0], range[1]);
    const targetSortIndx = getSortIndex(targetEl);
    const targetIdx = findIndexViaBinarySearch(toRebalance, targetSortIndx, getSortIndex);
    if (targetIdx === -1) {
      const beforeIdx = prevEl ? findIndexViaBinarySearch(toRebalance, start, getSortIndex) : -1;
      toRebalance.splice(beforeIdx + 1, 0, targetEl);
    } else {
      const removedEls = toRebalance.splice(targetIdx, 1);
      const beforeIdx = prevEl ? findIndexViaBinarySearch(toRebalance, start, getSortIndex) : -1;
      toRebalance.splice(beforeIdx + 1, 0, ...removedEls);
    }
    const firstIdx = getSortIndex(toRebalance[0]);
    const spreadStart = range[0] < firstIdx ? range[0] : firstIdx;

    const lastIdx = getSortIndex(toRebalance[toRebalance.length - 1]);
    const spreadEnd = range[1] > lastIdx ? range[1] : lastIdx;

    const newSortIdxs = sorter.spreadBetween(spreadStart, spreadEnd, toRebalance.length);
    return toRebalance.map((el, idx) => [el, newSortIdxs[idx]] as const);
  }
};
