import {LRUCache} from "lru-cache";
import {shallow} from "zustand/shallow";
import {findNearestIndexForListArgsViaBinarySearch} from "../utils";
import type {DbType} from "../models/DbList";
import {addDbChangeListenerForQuery} from "./dbChangeListener";
import type {ChangeType} from "./db-hooks";
import {getChangeType} from "./db-hooks";

type PromiseResult<T> = {value: T; error: null} | {value: null; error: Error};

type DbObject = {_id: string; _rev: string};

type PromiseCacheEntry<T> =
  | {
      keyType: "single" | "list";
      promise: Promise<PromiseResult<T>>;
      result: null;
      size: number;
      invalid: null;
      optimisticCache: [number, T][];
    }
  | {
      keyType: "single" | "list";
      promise: null;
      result: PromiseResult<T>;
      size: number;
      invalid: boolean;
      optimisticCache: [number, T][];
    };

type DbChange = PouchDB.Core.ChangesResponseChange<any>;

type LastChange<T extends DbObject> = {
  changeObj: SomeChange<T>;
  type: ChangeType;
  id: string;
  processedKeys: Map<string, boolean>;
  hydratedObj: T;
};

let nextOptimisticChangeIdx = 1;

type OptimisticChange<T extends DbObject> = {
  type: "create" | "update" | "delete";
  el: T;
  idx: number;
  affectedEntryList: [number, T | null | T[]][][];
};

type SomeChange<T extends DbObject> =
  | {type: "db"; payload: DbChange}
  | {type: "optimistic"; payload: OptimisticChange<T>};

type GetOpts = {useInvalid: boolean; useOptimisticValue: boolean};
type MaybePromise<T> = {value: T; isPromise: false} | {value: Promise<T>; isPromise: true};

export type QueryCache<
  T,
  Views extends {[viewName: string]: {sortBy: string[]; isPresent: (el: any) => boolean}},
> = {
  // loadAndSetSingle: (key: string) => PromiseCacheEntry<T | null>;
  getSingle: (id: string, opts: GetOpts) => MaybePromise<T | null>;
  addDbChangeListenerSingle: (id: string, cb: () => void) => () => void;

  getList: (viewName: null | (keyof Views & string), opts: GetOpts) => MaybePromise<T[]>;
  addDbChangeListenerList: (viewName: null | (keyof Views & string), cb: () => void) => () => void;

  create: (els: T) => Promise<T & {_rev: string}>;
  createBulk: (els: T[]) => Promise<(T & {_rev: string})[]>;
  update: (els: T[]) => Promise<void>;
  delete: (els: T[]) => Promise<void>;
};

export const createQueryCache = <
  TString extends DbObject,
  T extends DbObject & {
    [K in Views[keyof Views]["sortBy"][number]]: string | number | boolean | null | Date;
  },
  Views extends {[viewName: string]: {sortBy: string[]; isPresent: (el: any) => boolean}},
>(args: {
  idPrefix: string;
  type: string;
  hydrate: (asString: TString) => T;
  dehydrate: (el: T) => any;
  getDb: () => PouchDB.Database<any>;
  dbType: DbType;
  views: Views;
}): QueryCache<T, Views> => {
  const {idPrefix, hydrate: passedHydrate, getDb, views, dehydrate, type, dbType} = args;

  /**
   * Why two caches?
   *
   * queryCache caches requests to db, incl. promises
   *
   * objCache stores during hydration, i.e. only sync
   * the idea for the objCache is that if I already queried all items, I don't have
   * to query for item with id 123, as it already has been seen.
   *
   * The issue being that there are now two places to invalidate when an item changed.
   *
   * Invalidation is in a weird state. We probably don't need it, if the `setUpLocalChangeListener` is active.
   * But any component can call `Repo.getAllPromise()` which might not have an active listener (only `Repo.useAll()` causes a listener)
   *
   */
  const queryCache = new LRUCache<string, PromiseCacheEntry<T | T[] | null>>({
    maxSize: 10 * 1024 * 1024,
    sizeCalculation: (e) => e.size,
  });

  const objCache = new Map<
    string,
    {raw: TString; value: T; invalid: boolean; optimisticChangeStack: [number, T][]}
  >();

  // only hydrate and pass new el if el is actually new/changed, otherwise
  // pass already hydrated element
  const hydrate = (el: TString): T => {
    const exist = objCache.get(el._id);
    if (exist) {
      if (exist.optimisticChangeStack.length > 0) {
        return exist.optimisticChangeStack.at(-1)![1];
      }
      if (shallow(exist.raw, el)) {
        exist.invalid = false;
        return exist.value;
      }
    }
    const value = passedHydrate(el);
    objCache.set(el._id, {raw: el, value, invalid: false, optimisticChangeStack: []});
    return value;
  };

  const getListKey = (viewName: null | string) =>
    `${idPrefix}:${viewName ? `vw_${viewName}` : "all"}`;

  const loadAndSet = <TRaw, TTransformed extends T | T[] | null>(
    keyType: "single" | "list",
    key: string,
    getter: () => Promise<TRaw>,
    transform: (raw: TRaw) => TTransformed,
    getSize: (result: TTransformed) => number
  ) => {
    const promise: Promise<PromiseResult<TTransformed>> = getter()
      .then(
        (res) => ({value: transform(res), error: null}),
        (error) => ({value: null, error})
      )
      .then((result) => {
        const latestEntry = queryCache.get(key) as PromiseCacheEntry<TTransformed> | undefined;
        if (!latestEntry || latestEntry.promise === promise) {
          queryCache.set(key, {
            keyType,
            promise: null,
            result,
            size: result.error ? 1 : Math.max(1, getSize(result.value!)),
            invalid: false,
            optimisticCache: [],
          });
          return result;
        }
        // if a new promise has already been set up, return that result instead
        if (latestEntry.promise) return latestEntry.promise;
        return latestEntry.result;
      });
    const cacheEntry: PromiseCacheEntry<TTransformed> = {
      keyType,
      promise,
      result: null,
      size: 1,
      invalid: null,
      optimisticCache: [],
    };
    queryCache.set(key, cacheEntry);
    return promise;
  };

  const get = <TData extends T | T[] | null>(
    key: string,
    loader: () => Promise<PromiseResult<TData>>,
    {useInvalid, useOptimisticValue}: GetOpts
  ): MaybePromise<TData> => {
    const cacheEntry = queryCache.get(key) as PromiseCacheEntry<TData>;
    const handlePromiseResult = (r: PromiseResult<TData>) =>
      r.error ? Promise.reject(r.error) : r.value;
    if (!cacheEntry) return {isPromise: true, value: loader().then(handlePromiseResult)};
    const {promise, invalid, result, optimisticCache} = cacheEntry;
    if (useOptimisticValue) {
      if (optimisticCache.length > 0) {
        return {isPromise: false, value: optimisticCache.at(-1)![1]};
      }
    }
    if (promise) return {isPromise: true, value: promise.then(handlePromiseResult)};
    if (invalid) {
      cacheEntry.invalid = false;
      if (!useInvalid) {
        return {
          isPromise: true,
          value: loader().then(handlePromiseResult),
        };
      } else {
        void loader();
      }
    }
    if (result.error) throw result.error;
    return {isPromise: false, value: result.value};
  };

  const invalidateKey = (key: string) => {
    const exist = queryCache.get(key);
    if (exist) {
      if (exist.promise) {
        // TODO: think about what to do here
      } else {
        queryCache.set(key, {...exist, invalid: true});
      }
    }
  };

  let lastChange: LastChange<T> | null = null;

  const classifyChange = (
    someChange: SomeChange<T>
  ): Pick<LastChange<T>, "type" | "id" | "hydratedObj"> => {
    switch (someChange.type) {
      case "db": {
        const change = someChange.payload;
        return {
          type: getChangeType(change),
          id: change.id,
          hydratedObj: hydrate(change.doc! as TString),
        };
      }
      case "optimistic": {
        const change = someChange.payload;
        return {type: change.type, hydratedObj: change.el, id: change.el._id};
      }
    }
  };

  const processChange = (
    change: SomeChange<T>,
    key: string,
    isRelevant: (arg: LastChange<T>, el: T) => boolean
  ): boolean => {
    if (change.payload !== lastChange?.changeObj.payload) {
      // console.log("GOT NEW CHANGE", change);
      lastChange = {
        changeObj: change,
        ...classifyChange(change),
        processedKeys: new Map(),
      };
    }
    const exist = lastChange.processedKeys.get(key);
    if (exist) return exist;
    const relevant = isRelevant(lastChange, lastChange.hydratedObj);
    if (relevant && change.type !== "optimistic") invalidateKey(key);
    lastChange.processedKeys.set(key, relevant);
    return relevant;
  };

  const invalidateCache = (res: (PouchDB.Core.Response | PouchDB.Core.Error)[]) => {
    queryCache.forEach((e) => {
      e.invalid = true;
    });
    return res;
  };

  const invalidateCacheAndObjCache = (res: (PouchDB.Core.Response | PouchDB.Core.Error)[]) => {
    queryCache.forEach((e) => {
      e.invalid = true;
    });
    res.forEach((e) => {
      if (!e.id) return;
      const obj = objCache.get(e.id);
      if (obj) obj.invalid = true;
    });
  };

  const rejectOnBulkUpdateError = (list: (PouchDB.Core.Response | PouchDB.Core.Error)[]) => {
    const withErr = list.find((r) => "error" in r) as PouchDB.Core.Error | null;
    if (withErr) {
      console.error(withErr);
      return Promise.reject(withErr.message || withErr.name);
    }
    return list;
  };

  const triggerReloadOnReject = (err: any) => {
    triggerUpdateCb();
    return Promise.reject(err);
  };

  const optimisticChangeListeners = new Set<(change: OptimisticChange<T>) => void>();
  const cbs = new Set<() => void>();

  const addChangeListener = (
    cb: () => void,
    onChange: (change: SomeChange<T>) => boolean,
    onStopSync: () => void
  ) => {
    const handleDbChange = (change: DbChange) => {
      if (onChange({type: "db", payload: change})) cb();
    };
    const handleOptimisticChange = (change: OptimisticChange<T>) => {
      if (onChange({type: "optimistic", payload: change})) cb();
    };
    optimisticChangeListeners.add(handleOptimisticChange);
    cbs.add(cb);
    const unsubDbChange = addDbChangeListenerForQuery({
      db: getDb(),
      dbType,
      type,
      idPrefix,
      cb: handleDbChange,
      onStopSyncType: onStopSync,
    });

    return () => {
      unsubDbChange();
      optimisticChangeListeners.delete(handleOptimisticChange);
      cbs.delete(cb);
    };
  };

  const triggerUpdateCb = () => cbs.forEach((cb) => cb());

  const emitOptimisticCache = (
    optType: OptimisticChange<T>["type"],
    el: OptimisticChange<T>["el"]
  ) => {
    const change: OptimisticChange<T> = {
      type: optType,
      el,
      idx: (nextOptimisticChangeIdx += 1),
      affectedEntryList: [],
    };
    optimisticChangeListeners.forEach((fn) => fn(change));
    return () => {
      change.affectedEntryList.forEach((list) => {
        const idx = list.findIndex(([lIdx]) => lIdx === change.idx);
        if (idx < 0) return;
        list.splice(idx, 1);
      });
    };
  };

  return {
    getSingle: (id, opts) => {
      const fromObjCache = objCache.get(id);
      if (fromObjCache) {
        const {optimisticChangeStack, invalid, value} = fromObjCache;
        if (opts.useOptimisticValue && optimisticChangeStack.length > 0) {
          return {isPromise: false, value: optimisticChangeStack.at(-1)![1]};
        }
        if (!invalid) return {isPromise: false, value};
      }

      const getter = async () => {
        const result = await getDb()
          .get(id)
          .catch((e) => {
            console.error(e);
            return null;
          });
        // await new Promise((res) => setTimeout(res, 2000));
        return result ? (result as TString) : null;
      };

      const loader = () =>
        loadAndSet(
          "single",
          id,
          getter,
          (val) => (val ? hydrate(val) : null),
          () => 1
        );

      return get(id, loader, opts);
    },

    getList: (viewName, opts) => {
      const key = getListKey(viewName);

      const getter = async () => {
        const getResults = () => {
          if (viewName) {
            return getDb().query(viewName, {
              include_docs: true,
            });
          } else {
            const q = `${idPrefix}`;
            return getDb().allDocs({
              include_docs: true,
              startkey: q,
              endkey: `${q}\uffff`,
            });
          }
        };
        const result = await getResults().catch((e) => {
          console.error(e);
          return {rows: []};
        });
        // await new Promise((res) => setTimeout(res, 2000));
        return result.rows.map((row) => row.doc! as TString);
      };

      const loader = () =>
        loadAndSet<TString[], T[]>(
          "list",
          key,
          getter,
          (res) => res.map((val) => hydrate(val)),
          (val) => val.length
        );

      return get(key, loader, opts);
    },

    addDbChangeListenerSingle: (id, cb) => {
      const onStopSync = () => {
        const exist = objCache.get(id);
        if (exist) exist.invalid = true;
        invalidateKey(id);
      };
      return addChangeListener(
        cb,
        (change) => {
          return processChange(change, id, (obj, next) => {
            if (obj.id !== id) return false;
            if (change.type === "optimistic") {
              const exist = objCache.get(id);
              if (exist) {
                exist.optimisticChangeStack.push([change.payload.idx, next]);
                change.payload.affectedEntryList.push(exist.optimisticChangeStack);
              }
            }
            return true;
          });
        },
        onStopSync
      );
    },
    addDbChangeListenerList: (viewName, cb) => {
      const key = getListKey(viewName);
      const onStopSync = () => invalidateKey(key);
      return addChangeListener(
        cb,
        (change) => {
          return processChange(change, key, ({type: changeType, id}, next) => {
            const cacheEntry = queryCache.get(key);
            if (!cacheEntry) return false;
            if (cacheEntry.promise && change.type !== "optimistic") return true;
            if (!cacheEntry.result || cacheEntry.result.error) return true;

            const latestVal = (
              cacheEntry.optimisticCache.length > 0
                ? cacheEntry.optimisticCache.at(-1)![1]
                : cacheEntry.result.value
            ) as T[];

            const cacheVal = change.type === "optimistic" ? [...latestVal] : latestVal;
            if (!viewName) {
              switch (changeType) {
                case "create": {
                  cacheVal.push(next);
                  break;
                }
                case "update": {
                  const idx = cacheVal.findIndex((v) => v._id === id);
                  if (idx >= 0) cacheVal[idx] = next;
                  break;
                }
                case "delete": {
                  const idx = cacheVal.findIndex((v) => v._id === id);
                  if (idx >= 0) cacheVal.splice(idx, 1);
                  break;
                }
              }
              if (change.type === "optimistic") {
                cacheEntry.optimisticCache.push([change.payload.idx, cacheVal]);
                change.payload.affectedEntryList.push(cacheEntry.optimisticCache);
              }
              return true;
            } else {
              const info = views[viewName];
              const sortBy = info.sortBy as (keyof T)[];
              switch (changeType) {
                case "create": {
                  if (!info.isPresent(next)) return false;
                  const [, right] = findNearestIndexForListArgsViaBinarySearch(
                    cacheVal,
                    next,
                    (e) => [...sortBy, "_id"].map((f) => e[f])
                  );
                  cacheVal.splice(right, 0, next);
                  if (change.type === "optimistic") {
                    cacheEntry.optimisticCache.push([change.payload.idx, cacheVal]);
                    change.payload.affectedEntryList.push(cacheEntry.optimisticCache);
                  }
                  return true;
                }
                case "update": {
                  const idx = cacheVal.findIndex((v) => v._id === id);
                  const shouldBePresent = info.isPresent(next);
                  if (idx >= 0) {
                    if (!shouldBePresent) {
                      cacheVal.splice(idx, 1);
                      if (change.type === "optimistic") {
                        cacheEntry.optimisticCache.push([change.payload.idx, cacheVal]);
                        change.payload.affectedEntryList.push(cacheEntry.optimisticCache);
                      }
                      return true;
                    } else {
                      const indexFieldsUnchanged = shallow(
                        sortBy.map((f) => next[f]),
                        sortBy.map((f) => cacheVal[idx][f])
                      );
                      if (indexFieldsUnchanged) {
                        cacheVal[idx] = next;
                      } else {
                        // view based field (e.g. sortIndex) has changed, so we need to change its position!
                        cacheVal.splice(idx, 1);
                        const [, right] = findNearestIndexForListArgsViaBinarySearch(
                          cacheVal,
                          next,
                          (e) => [...sortBy, "_id"].map((f) => e[f])
                        );
                        cacheVal.splice(right, 0, next);
                      }
                      if (change.type === "optimistic") {
                        cacheEntry.optimisticCache.push([change.payload.idx, cacheVal]);
                        change.payload.affectedEntryList.push(cacheEntry.optimisticCache);
                      }
                      return true;
                    }
                  } else {
                    if (shouldBePresent) {
                      const [, right] = findNearestIndexForListArgsViaBinarySearch(
                        cacheVal,
                        next,
                        (e) => [...sortBy, "_id"].map((f) => e[f])
                      );
                      cacheVal.splice(right, 0, next);
                      if (change.type === "optimistic") {
                        cacheEntry.optimisticCache.push([change.payload.idx, cacheVal]);
                        change.payload.affectedEntryList.push(cacheEntry.optimisticCache);
                      }
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
                case "delete": {
                  const idx = cacheVal.findIndex((v) => v._id === id);
                  if (idx >= 0) {
                    cacheVal.splice(idx, 1);
                    if (change.type === "optimistic") {
                      cacheEntry.optimisticCache.push([change.payload.idx, cacheVal]);
                      change.payload.affectedEntryList.push(cacheEntry.optimisticCache);
                    }
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            }
          });
        },
        onStopSync
      );
    },

    create: async (el) => {
      const onDone = emitOptimisticCache("create", {...el, _rev: "0-optimistic"});
      const res = await getDb().post(dehydrate(el)).finally(onDone);
      return {...el, _rev: res.rev};
    },
    createBulk: async (els) => {
      const onDone = emitOptimisticCache("create", {...els[0], _rev: "0-optimistic"});
      const res = await getDb()
        .bulkDocs(els.map((entry) => dehydrate(entry)))
        .then(rejectOnBulkUpdateError)
        .then(invalidateCache)
        .catch(triggerReloadOnReject)
        .finally(onDone);
      return els.map((el, idx) => ({...el, rev: res[idx].rev!}));
    },
    update: async (els) => {
      const onDoneFns = els.map((el) => emitOptimisticCache("update", el));
      await getDb()
        .bulkDocs(els.map((entry) => dehydrate(entry)))
        .then(rejectOnBulkUpdateError)
        .then(invalidateCacheAndObjCache)
        .catch(triggerReloadOnReject)
        .finally(() => {
          onDoneFns.forEach((fn) => fn());
        });
    },
    delete: async (els) => {
      const onDoneFns = els.map((el) => emitOptimisticCache("delete", el));
      await getDb()
        .bulkDocs(els.map((el) => ({...el, _deleted: true})))
        .then(rejectOnBulkUpdateError)
        .then(invalidateCache)
        .catch(triggerReloadOnReject)
        .finally(() => {
          onDoneFns.forEach((fn) => fn());
        });
    },
  };
};
