import {useEffect, useRef} from "react";

const useEsc = (onEsc: () => void) => {
  const cbRef = useRef(onEsc);
  useEffect(() => {
    cbRef.current = onEsc;
  });
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.code === "Escape") cbRef.current();
    };
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, []);
};
export default useEsc;
