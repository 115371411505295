import type {ReactNode} from "react";
import {Fragment, useLayoutEffect, useRef, useState} from "react";

type TransitionFn<T> = (fn: (obj: T, closing: boolean) => ReactNode) => ReactNode[];

type TransMap<T> = Map<Key, {data: T; timeoutId: null | ReturnType<typeof setTimeout>; key: Key}>;

type Key = string | null | boolean;

export const useMyTransition = <T extends any>(
  obj: T,
  key: Key,
  opts?: {canRecover?: boolean; timeoutMs?: number}
): TransitionFn<T> => {
  const mapRef = useRef<TransMap<T>>(null as any);
  if (!mapRef.current) mapRef.current = new Map();
  const [, forceUpdate] = useState<unknown>();
  const optsObj = {obj, canRecover: opts?.canRecover || true, timeoutMs: opts?.timeoutMs || 500};
  const objRef = useRef(optsObj);
  useLayoutEffect(() => {
    objRef.current = optsObj;
  });
  useLayoutEffect(() => {
    let found = false;
    let updated = false;
    for (let [elKey, val] of mapRef.current) {
      if (elKey === key) {
        if (val.timeoutId) {
          clearTimeout(val.timeoutId);
          val.timeoutId = null;
          updated = true;
        }
        found = true;
      } else {
        if (!val.timeoutId) {
          if (!objRef.current.canRecover) {
            mapRef.current.delete(elKey);
            elKey = `${key}-${new Date().getTime()}`;
            mapRef.current.set(elKey, val);
          }
          val.timeoutId = setTimeout(() => {
            mapRef.current.delete(elKey);
            forceUpdate({});
          }, objRef.current.timeoutMs);
          updated = true;
        }
      }
    }
    if (!found) {
      mapRef.current.set(key, {data: objRef.current.obj, timeoutId: null, key});
      updated = true;
    }
    if (updated) forceUpdate({});
  }, [key]);
  return (renderFn) =>
    [...mapRef.current.values()].map((val) => (
      <Fragment key={`${val.key}`}>{renderFn(val.data, val.timeoutId !== null)}</Fragment>
    ));
};
