import {useState, type ReactNode, useEffect} from "react";
import {Trans} from "@lingui/macro";
import {useNavigate} from "react-router-dom";
import copy from "copy-to-clipboard";
import * as v from "valibot";
import AppShell from "../../shared/ui/AppShell";
import GroupFooterNav from "../../shared/ui/GroupFooterNav";
import {Col, css, Row, Text} from "../../ui/Box";
import Header from "../../shared/ui/Header";
import {shareGroup, leaveGroup, deleteGroup} from "../../models/GroupEntry";
import type {GroupRepoType, GroupEntry, GroupId} from "../../models/GroupEntry";
import {useGroupRepos} from "../../models/GroupRepos";
import {useIsUserSynced} from "../../models/DbList";
import type {PouchWrap} from "../../db/Repo";
import Button from "../../ui/Button";
import {authServerRequest} from "../../authServerRequest";
import {addMessage} from "../../features/messenger/messenger";
import {useUserId} from "../../WithAuth";
import useToggle from "../../hooks/useToggle";
import {getUserRepos} from "../../models/UserRepo";
import Icon from "../../ui/Icon";
import {ControlledDrawer} from "../../ui/Drawer";
import {Input} from "../../ui/Input";
import {useForm} from "../../features/form/Form";
import {TextField} from "../../features/form/fields";

const Block = ({children, title}: {children: ReactNode; title: ReactNode}) => (
  <Col rounded="4" surface pa="16" sp="12">
    <Text as="h2" size="20">
      {title}
    </Text>
    {children}
  </Col>
);

type GroupProps = {group: PouchWrap<GroupEntry>};

const renameFormSchema = v.object({
  groupName: v.pipe(v.string(), v.nonEmpty(), v.maxLength(256)),
});

const Rename = ({group, GroupRepo}: GroupProps & {GroupRepo: GroupRepoType}) => {
  const {Form, field} = useForm({
    initial: {groupName: group.name},
    schema: renameFormSchema,
    onSubmit: async ({groupName}) => {
      await GroupRepo.update({...group, name: groupName});
    },
  });
  return (
    <Form buttonLabel="Rename" className={css({display: "flex", sp: "8", align: "end"})}>
      <TextField label="Name" field={field("groupName")} />
    </Form>
  );
};

const Configure = ({group, GroupRepo}: GroupProps & {GroupRepo: GroupRepoType}) => (
  <Block title={<Trans>Basics</Trans>}>
    <Rename group={group} GroupRepo={GroupRepo} />
  </Block>
);

const SyncButton = (props: GroupProps) => {
  const {group} = props;
  const isUserSynced = useIsUserSynced();

  if (!isUserSynced) return <div>Can't sync: User has local-only account</div>;

  const handleSync = () => shareGroup({group});
  return group.remoteDbName ? (
    <div>
      Group is synced on all devices <Icon name="cloud" inline />
    </div>
  ) : (
    <Button onClick={handleSync}>Sync on all devices</Button>
  );
};

const UrlArea = ({url, onClose}: {url: string; onClose: () => void}) => {
  const [input, setInput] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (input) input.select();
  }, [input]);

  const handleCopyClick = () => {
    copy(url);
    addMessage("Url copied");
  };

  return (
    <div>
      <Input readOnly defaultValue={url} ref={setInput} />
      <Button onClick={handleCopyClick}>Copy</Button>
      <div>This link can be used to invite a single user.</div>
      <Button onClick={onClose}>Close</Button>
    </div>
  );
};

const ShareButton = ({group}: GroupProps) => {
  const [url, setUrl] = useState<string | null>(null);

  if (!group.remoteDbName) return null;

  const handleClick = async () => {
    const res = await authServerRequest({
      path: `/create-group-invite-link`,
      method: "POST",
      data: {dbName: group.remoteDbName, groupId: group._id},
    });
    setUrl(res.link);
  };

  return (
    <Col sp="4">
      <Text bold size="16">
        Invite others to join this list
      </Text>
      {url ? (
        <UrlArea url={url} onClose={() => setUrl(null)} />
      ) : (
        <div>
          <Button onClick={handleClick}>Create Link to Share</Button>
        </div>
      )}
    </Col>
  );
};

const MemberList = () => {
  const {GroupMemberRepo} = useGroupRepos();
  const userId = useUserId();
  const members = GroupMemberRepo.useGetAll();
  if (!members) return <div>no members</div>;
  return (
    <div>
      members: {members.map((m) => (m.userId === userId ? "You" : m.memberName)).join(", ")}
    </div>
  );
};

const Sharing = ({group}: GroupProps) => (
  <Block title={<Trans>Sharing</Trans>}>
    <SyncButton group={group} />
    <MemberList />
    <ShareButton group={group} />
  </Block>
);

const LeaveModalContent = ({group, onClose}: {group: GroupEntry; onClose: () => void}) => {
  const navigate = useNavigate();
  const userId = useUserId();
  const {SharedGroupPreviewRepo} = getUserRepos(userId);
  const handleLeave = async () => {
    await leaveGroup(group, SharedGroupPreviewRepo);
    navigate("/");
  };
  return (
    <Col sp="8">
      <Text size="14_tight">Are you sure!?</Text>
      <Row sp="4">
        <Button onClick={onClose}>no</Button> <Button onClick={handleLeave}>yes</Button>
      </Row>
    </Col>
  );
};

const LeaveButton = ({group}: {group: GroupEntry}) => {
  const [showWarning, {on, off}] = useToggle();
  return (
    <>
      <ControlledDrawer open={showWarning} onClose={off}>
        <LeaveModalContent group={group} onClose={off} />
      </ControlledDrawer>
      <Button onClick={on}>Leave</Button>
    </>
  );
};

const LeaveGroup = ({group}: GroupProps) => {
  const {GroupMemberRepo} = useGroupRepos();
  const members = GroupMemberRepo.useGetAll();
  if (members.length <= 1) return null;
  return <LeaveButton group={group} />;
};

const DeleteModalContent = ({
  group,
  onClose,
}: {
  group: PouchWrap<GroupEntry>;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const userId = useUserId();
  const handleDelete = async () => {
    await deleteGroup(group, userId);
    navigate("/");
  };
  return (
    <Col sp="8">
      <Text size="14_tight">Are you sure!?</Text>
      <Row sp="4">
        <Button onClick={onClose}>no</Button> <Button onClick={handleDelete}>yes</Button>
      </Row>
    </Col>
  );
};

const DeleteButton = ({group}: {group: PouchWrap<GroupEntry>}) => {
  const [showWarning, {on, off}] = useToggle();
  return (
    <>
      <ControlledDrawer open={showWarning} onClose={off}>
        <DeleteModalContent group={group} onClose={off} />
      </ControlledDrawer>
      <Button onClick={on}>Delete List</Button>
    </>
  );
};

const Danger = ({group}: GroupProps) => (
  <Block title={<Trans>Danger Zone</Trans>}>
    <LeaveGroup group={group} />
    <div>
      <DeleteButton group={group} />
    </div>
  </Block>
);

const GroupSettings = () => {
  const {GroupRepo, groupId} = useGroupRepos();
  const group = GroupRepo.useGetById(`grp:${groupId}` as GroupId);

  if (!group) return <div>{404}</div>;
  return (
    <AppShell
      header={
        <Header
          currentGroupId={groupId}
          title={<Trans>Settings</Trans>}
          backButton={{to: "..", label: <Trans id={`nav.back`}>Back</Trans>}}
        />
      }
      footer={<GroupFooterNav current="list" group={group} />}
    >
      <Col px="12" sp="24" py="24">
        <Col align="start">
          <Button to="../categories">
            <Trans>Change Order</Trans>
          </Button>
        </Col>
        <Configure group={group} GroupRepo={GroupRepo} />
        <Sharing group={group} />
        <Danger group={group} />
      </Col>
    </AppShell>
  );
};

export default GroupSettings;
