import {Link, Navigate} from "react-router-dom";
import {Trans} from "@lingui/macro";
import type {PouchWrap} from "../../db/Repo";
import {createLocalGroup} from "../../models/GroupEntry";
import type {GroupPreviewEntry, GroupPreviewRepoType} from "../../models/GroupPreviewEntry";
import {useLocalRepos} from "../../models/LocalRepo";
import {useMe, useUserRepos} from "../../models/UserRepo";
import Button from "../../ui/Button";
import Header from "../../shared/ui/Header";
import AppShell from "../../shared/ui/AppShell";
import Icon from "../../ui/Icon";
import {Box, Col, css, Text} from "../../ui/Box";
import {StorageKey, storageWrapper} from "../../storage";

const Group = ({group}: {group: PouchWrap<GroupPreviewEntry>}) => (
  <Link
    to={`/g/${group.shortId}`}
    className={css({
      display: "flex",
      sp: "4",
      align: "baseline",
      rounded: "4",
      bg: "surface",
      pa: "16",
    })}
  >
    <Box color="primary" textTransform="uppercase" size="16" bold>
      {group.name}
    </Box>
    {group.remoteDbName && <Icon name="cloud" inline />}
    <Text color="secondary" size="14_tight">
      {group.createdAt.toDateString()}
    </Text>
  </Link>
);

const CreateLocalGroup = ({GroupPreviewRepo}: {GroupPreviewRepo: GroupPreviewRepoType}) => {
  const {me, userId} = useMe();
  const handleClick = () =>
    createLocalGroup({
      GroupPreviewRepo,
      userId,
      userName: me.name || "Anon",
      groupName: "Hello",
    });
  return <Button onClick={handleClick}>add local</Button>;
};

const App = () => {
  const {LocalGroupPreviewRepo} = useLocalRepos();
  const {SharedGroupPreviewRepo} = useUserRepos();
  const localGroups = LocalGroupPreviewRepo.useGetAll();
  const sharedGroups = SharedGroupPreviewRepo.useGetAll();

  const allGroups = [...sharedGroups, ...localGroups];

  const groupId = storageWrapper.storageGet(StorageKey.LAST_GROUP_ID);
  const foundLastGroup = allGroups.find((g) => g.groupId === groupId);

  return (
    <AppShell header={<Header currentGroupId={null} title={<Trans id="nav.lists">Lists</Trans>} />}>
      {foundLastGroup ? (
        <Navigate replace to={`/g/${foundLastGroup.shortId}`} />
      ) : (
        <Col bg="background" px="12" py="24" sp="24">
          {allGroups.map((group) => (
            <Group key={group._id} group={group} />
          ))}
          <div>
            <CreateLocalGroup GroupPreviewRepo={LocalGroupPreviewRepo} />
          </div>
        </Col>
      )}
    </AppShell>
  );
};

export default App;
