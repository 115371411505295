const overflowRegex = /(auto|scroll|overlay)/;
export const findClosestScrollableParent = (target: HTMLElement) => {
  let current = target;
  while (current) {
    const style = getComputedStyle(current);
    if (overflowRegex.test(style.overflowY + style.overflowX)) {
      return current;
    }
    current = current.offsetParent as HTMLElement;
  }
  return window;
};

// export const findScrollableParents = (target: HTMLElement) => {
//   const scrollableParents: (HTMLElement | Window)[] = [window];
//   const inner = (node: HTMLElement | null) => {
//     if (!node) return;

//     const style = getComputedStyle(node);
//     const overflowRegex = /(auto|scroll|overlay)/;

//     if (overflowRegex.test(style.overflowY + style.overflowX)) {
//       scrollableParents.push(node);
//     }

//     inner(node.offsetParent as HTMLElement);
//   };
//   inner(target);
//   return scrollableParents;
// };
