import {Trans} from "@lingui/macro";
import PouchDb from "pouchdb-browser";
import type {ReactNode} from "react";
import {useNavigate, useParams} from "react-router";
import {authServerRequest} from "../../authServerRequest";
import type {TypeToData} from "../../features/tokens/useToken";
import {useToken} from "../../features/tokens/useToken";
import type {GroupEntry, GroupId, NoPrefixGroupId} from "../../models/GroupEntry";
import type {NoPrefixUserId, UserId} from "../../models/UserRepo";
import {getUserRepos} from "../../models/UserRepo";
import Button from "../../ui/Button";
import {useManageUserId, WithUserId} from "../../WithAuth";
import useToggle from "../../hooks/useToggle";
import {getGroupRepos} from "../../models/GroupRepos";
import LoginForm from "../../features/auth/LoginForm";
import SignUpForm, {SyncForm} from "../../features/auth/SignUpForm";
import AppShell from "../../shared/ui/AppShell";
import {Box, Col} from "../../ui/Box";

const InnerInvite = (props: {children: ReactNode; tokenData: TypeToData["group-invite"]}) => {
  const {tokenData, children} = props;
  return (
    <Col sp="16" width="100%" maxWidth="formWidth">
      <Box color="primary" size="14_tight">
        <Trans>
          <b>{tokenData.inviter.name}</b> wants to invite you to <b>{tokenData.group.name}</b>
        </Trans>
      </Box>
      {children}
    </Col>
  );
};

const AcceptForm = ({
  userId,
  token,
  groupId,
}: {
  userId: NoPrefixUserId;
  token: string;
  groupId: GroupId;
}) => {
  const {MeRepo, SharedGroupPreviewRepo} = getUserRepos(userId);
  const me = MeRepo.useGetById(`user:${userId}` as UserId);
  const navigation = useNavigate();
  const [isProcessingSync, {on, off}] = useToggle();
  if (!me) return <div>No user!?</div>;
  const handleAccept = async () => {
    const id = groupId.replace(/^grp:/, "") as NoPrefixGroupId;
    const {group}: {group: GroupEntry} = await authServerRequest({
      path: `/tokens/apply/group-invite/${token}`,
    });
    await SharedGroupPreviewRepo.create({
      createdAt: new Date(group.createdAt),
      groupId: id,
      name: group.name,
      remoteDbName: group.remoteDbName,
      shortId: group.shortId,
    });
    const {GroupRepo, GroupMemberRepo} = getGroupRepos(id);
    await PouchDb.replicate(
      `${import.meta.env.VITE_COUCHDB_HOST}/${group.remoteDbName}`,
      GroupRepo.getDb()
    ).on("error", function (err: any) {
      throw new Error(err);
    });
    await GroupMemberRepo.create({
      createdAt: new Date(),
      groupId: id,
      userId,
      memberName: me.name || "Anon",
    });
    navigation(`/g/${group.shortId}`);
  };

  if (me.remoteDbName && !isProcessingSync) {
    return (
      <div>
        <Button onClick={handleAccept}>Accept</Button>
      </div>
    );
  } else {
    return (
      <div>
        <div>Please sync!</div>
        <SyncForm
          me={me}
          userId={userId}
          onDone={async () => {
            on();
            await handleAccept();
            off();
          }}
        />
      </div>
    );
  }
};

const MyAuthenticate = ({setUserId}: {setUserId: (val: null | NoPrefixUserId) => void}) => {
  return (
    <Col sp="32">
      <Col rounded="4" surface pa="16" sp="12" width="100%" maxWidth="formWidth">
        <Box as="h2" size="20" textAlign="center">
          <Trans>Create account</Trans>
        </Box>
        <SignUpForm setUserId={setUserId} />
      </Col>
      <Col rounded="4" surface pa="16" sp="12" width="100%" maxWidth="formWidth">
        <Box as="h2" size="20" textAlign="center">
          <Trans>...or log in</Trans>
        </Box>
        <LoginForm setUserId={setUserId} />
      </Col>
    </Col>
  );
};

const GroupInvite = () => {
  const {token} = useParams<{token: string}>();
  const tokenState = useToken({token, type: "group-invite"});
  const [userId, setUserId] = useManageUserId();
  return (
    <AppShell>
      <Col bg="background" px="12" py="24" align="center" sp="48">
        {tokenState.success ? (
          <InnerInvite tokenData={tokenState.data}>
            {userId ? (
              <WithUserId userId={userId} setUserId={setUserId}>
                <AcceptForm
                  userId={userId}
                  token={token as string}
                  groupId={tokenState.data.group.id}
                />
              </WithUserId>
            ) : (
              <MyAuthenticate setUserId={setUserId} />
            )}
          </InnerInvite>
        ) : (
          tokenState.renderEl
        )}
      </Col>
    </AppShell>
  );
};

export default GroupInvite;
