import type {HookCb} from "../db/db-hooks";
import {createRepo} from "../db/Repo";
import type {Lang} from "../i18n";
import {StorageKey, storageWrapper} from "../storage";
import type {Nominal} from "../types";
import {useUserId} from "../WithAuth";
import {DB_NAMES} from "./DbList";
import type {GroupPreviewRepoType} from "./GroupPreviewEntry";
import {createGroupPreviewRepo} from "./GroupPreviewEntry";
import {getGroupRepos} from "./GroupRepos";

export type UserId = Nominal<string, "UserId">;
export type NoPrefixUserId = Nominal<string, "NoPrefixUserId">;

export type UserEntry = {
  _id: UserId;
  type: "User";
  name: string | null;
  remoteDbName: string | null;
  createdAt: Date;
  lang: Lang;
};

export type UserRepoType = ReturnType<typeof createUserRepo>;

const createUserRepo = (id: NoPrefixUserId) =>
  createRepo<UserEntry>({
    dbInfo: DB_NAMES.User(id),
    idPrefix: "user:",
    type: "User",
    withAutoId: false,
    hydrate: (fromDb) => {
      const {createdAt, _id, type, lang, ...rest} = fromDb;
      return {
        _id: _id as UserId,
        type: "User",
        createdAt: new Date(createdAt),
        lang: lang as Lang,
        ...rest,
      };
    },
    dehydrate: (entry) => {
      const {createdAt, ...rest} = entry;
      return {createdAt: createdAt.toISOString(), ...rest};
    },
    views: {},
    hooks: getHooks(),
  });

export const toUserNoPrefixId = (id: UserEntry["_id"]) => {
  return id.slice(5) as string as NoPrefixUserId;
};

export type UserRepos = {
  MeRepo: UserRepoType;
  SharedGroupPreviewRepo: GroupPreviewRepoType;
};

const repoCache = new Map<NoPrefixUserId, UserRepos>();

export const getUserRepos = (id: NoPrefixUserId): UserRepos => {
  const exist = repoCache.get(id);
  if (exist) return exist;
  const repos: UserRepos = {
    MeRepo: createUserRepo(id),
    SharedGroupPreviewRepo: createGroupPreviewRepo(DB_NAMES.User(id)),
  };
  repoCache.set(id, repos);
  return repos;
};

export const useUserRepos = () => {
  return getUserRepos(useUserId());
};

export const useMe = () => {
  const userId = useUserId();
  const {MeRepo} = getUserRepos(userId);
  const me = MeRepo.useGetById(`user:${userId}` as UserId);
  if (!me) {
    console.error("user repo doesn't include current user!");
    storageWrapper.storageRemove(StorageKey.USER_ID);
    window.location.reload();
    throw new Error("user repo doesn't include current user!?");
  }
  return {me, userId};
};

const getHooks = (): HookCb<UserEntry>[] => {
  return [
    async ({prevDoc, doc}) => {
      if (!prevDoc) return;

      // RENAME
      if (prevDoc.name !== doc.name) {
        const id = toUserNoPrefixId(doc._id);
        const {SharedGroupPreviewRepo} = getUserRepos(id);
        const groups = await SharedGroupPreviewRepo.getAllPromise();
        for (const groupPrv of groups) {
          const {GroupMemberRepo} = getGroupRepos(groupPrv.groupId);
          const membs = await GroupMemberRepo.getAllPromise();
          const me = membs.find((m) => m.userId === id);
          if (me) {
            // Note: This will only get synced once the group is opened
            await GroupMemberRepo.update({...me, memberName: doc.name || "Anon"});
          }
        }
      }
    },
  ];
};
