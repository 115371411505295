import PouchDb from "pouchdb-browser";
import {useAuthIssueStore} from "../features/auth/auth-issue-store";
import type {DbType} from "../models/DbList";
import {getHookManagerForDb} from "./db-hooks";

type DbChange = PouchDB.Core.ChangesResponseChange<any>;
type ChangeCb = (change: DbChange) => void;

type DbListenerMapValue = {
  killTimeoutId: ReturnType<typeof setTimeout> | null;
  changeListener: PouchDB.Core.Changes<any> | null;
  callbacks: Map<string | null, Set<ChangeCb>>;
};

const dbListeners = new Map<string, DbListenerMapValue>();

const getDbListener = (dbName: string) => {
  const exists = dbListeners.get(dbName);
  if (exists) return exists;
  const next: DbListenerMapValue = {
    killTimeoutId: null,
    changeListener: null,
    callbacks: new Map(),
  };
  dbListeners.set(dbName, next);
  return next;
};

const idPrefixToType = new Map<string, string>();

const setUpLocalChangeListener = (
  db: PouchDB.Database<any>,
  onChange: (change: DbChange, type: string | null) => void
) => {
  // eslint-disable-next-line no-console
  console.log("START LOCAL", db.name);
  return db
    .changes({
      since: "now",
      live: true,
      include_docs: true,
    })
    .on("change", function (change) {
      const getType = (): string | null => {
        const docType = change.doc!.type;
        if (docType) return docType;
        const match = change.id.match(/^([\w]+:)/);
        if (!match) {
          console.warn(`invalid id: '${change.id}'`);
          return null;
        }
        const byPrefix = idPrefixToType.get(match[1]);
        if (!byPrefix) {
          console.warn(`invalid prefix for id '${change.id}'`);
          return null;
        }
        return byPrefix;
      };
      onChange(change, getType());
    })
    .on("error", function (err) {
      throw new Error(err);
    });
};

// remote listeners are only active if a `useList` or `useSingle` is present
const setUpRemoteListener = (db: PouchDB.Database<any>, remoteDb: string) => {
  // eslint-disable-next-line no-console
  console.log("SYNC", remoteDb);
  return PouchDb.sync(db, `${import.meta.env.VITE_COUCHDB_HOST}/${remoteDb}`, {
    live: true,
    retry: true,
  }).on("error", function (err: any) {
    if (err?.status === 401) {
      useAuthIssueStore.getState().setAuthIssue(true);
    } else {
      throw new Error(err);
    }
  });
};

const remoteDbSyncCounts = new Map<
  string,
  {
    count: number;
    sync: PouchDB.Replication.Sync<any>;
    killTimeoutId: ReturnType<typeof setTimeout> | null;
  }
>();
export const ensureRemoteSync = (db: PouchDB.Database<any>, remoteDb: string) => {
  const exist = remoteDbSyncCounts.get(remoteDb) ?? 0;
  if (exist) {
    exist.count += 1;
    if (exist.killTimeoutId) {
      clearTimeout(exist.killTimeoutId);
      exist.killTimeoutId = null;
    }
  } else {
    remoteDbSyncCounts.set(remoteDb, {
      count: 1,
      sync: setUpRemoteListener(db, remoteDb),
      killTimeoutId: null,
    });
  }
  return () => {
    const val = remoteDbSyncCounts.get(remoteDb);
    if (!val) return;
    val.count -= 1;
    if (val.count <= 0 && !val.killTimeoutId) {
      val.killTimeoutId = setTimeout(() => {
        // eslint-disable-next-line no-console
        console.log("STOP SYNC", remoteDb);
        val.sync.cancel();
        remoteDbSyncCounts.delete(remoteDb);
      }, 100);
    }
  };
};

export const addDbChangeListenerForQuery = (opts: {
  db: PouchDB.Database<any>;
  dbType: DbType;
  type: string;
  idPrefix: string;
  cb: ChangeCb;
  onStopSyncType: () => void;
}) => {
  const {db, dbType, idPrefix, type, cb, onStopSyncType} = opts;
  idPrefixToType.set(idPrefix, type);
  const val = getDbListener(db.name);
  if (val.killTimeoutId) {
    clearTimeout(val.killTimeoutId);
    val.killTimeoutId = null;
  }
  const exist = val.callbacks.get(type);
  if (exist) {
    exist.add(cb);
  } else {
    val.callbacks.set(type, new Set([cb]));
  }
  if (!val.changeListener) {
    const hooks = getHookManagerForDb(dbType, db);
    val.changeListener = setUpLocalChangeListener(db, (change, foundType) => {
      if (!foundType) return;
      void hooks.onChange({type: foundType, change});
      (val.callbacks.get(foundType) || []).forEach((fn) => fn(change));
    });
  }

  return () => {
    const l = val.callbacks.get(type);
    if (!l) return;
    l.delete(cb);
    if (l.size > 0) return;
    val.callbacks.delete(type);
    if (val.callbacks.size > 0) return;
    if (!val.killTimeoutId) {
      val.killTimeoutId = setTimeout(() => {
        val.killTimeoutId = null;
        if (val.changeListener) {
          // eslint-disable-next-line no-console
          console.log("STOP LOCAL", db.name);
          val.changeListener.cancel();
          val.changeListener = null;
        }
        onStopSyncType();
      }, 100);
    }
  };
};
