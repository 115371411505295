import {i18n} from "@lingui/core";
import getEmojiRegex from "emoji-regex";

export const pad2 = (num: number) => (num > 9 ? `${num}` : `0${num}`);

const validIdChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890";
export const getRandomId = (size: number) => {
  const s: string[] = [];
  while (s.length < size) {
    s.push(validIdChars[Math.floor(Math.random() * validIdChars.length)]);
  }
  return s.join("");
};

const validLowerCaseIdChars = "abcdefghijklmnopqrstuvwxyz01234567890";
export const getRandomLowerCaseOnlyId = (size: number) => {
  const s: string[] = [];
  while (s.length < size) {
    s.push(validLowerCaseIdChars[Math.floor(Math.random() * validLowerCaseIdChars.length)]);
  }
  return s.join("");
};

export const createDateBasedId = <T extends string = string>(): T => {
  const now = new Date();
  const tsDate = `${now.getUTCFullYear()}${pad2(now.getUTCMonth() + 1)}${pad2(now.getUTCDate())}`;
  const tsTime = `${pad2(now.getUTCHours())}${pad2(now.getUTCMinutes())}${pad2(
    now.getUTCSeconds()
  )}`;
  return `${tsDate}-${tsTime}` as T;
};
export const createDateBasedIdWithHash = <T extends string = string>(): T => {
  const hash = getRandomLowerCaseOnlyId(6);
  return `${createDateBasedId()}-${hash}` as T;
};

export const shrinker = (text: string, maxChars: number): string => {
  if (!text || text.length <= maxChars) return text;
  const lastPartLength = Math.round(maxChars / 4);
  let lastPart = text.slice(maxChars - lastPartLength, maxChars);
  const m = lastPart.match(/\s+\S*$/);
  if (m) lastPart = lastPart.slice(0, lastPart.length - m[0].length + 1);
  lastPart = `${lastPart.slice(0, -1)}…`;
  return text.slice(0, maxChars - lastPartLength) + lastPart;
};

export const errorToString = (maybeError: any, fallback?: string): string => {
  if (!maybeError) return "Unknown error";
  if (typeof maybeError === "string") return maybeError;
  if (maybeError.errorKey && maybeError.errorKey in i18n.messages) {
    return i18n._(maybeError.errorKey);
  }
  if (maybeError.message) return errorToString(maybeError.message);
  if (maybeError.error) return errorToString(maybeError.error);
  if (maybeError.errorKey) return errorToString(maybeError.errorKey);
  if (maybeError.payload) return errorToString(maybeError.payload);
  console.error("Couldn't interpret error", maybeError);
  try {
    return shrinker(JSON.stringify(maybeError), 60);
  } catch {
    return fallback !== undefined ? fallback : "Unknown error";
  }
};
export const findIndexViaBinarySearch = <T, TVal>(
  arr: T[],
  target: TVal,
  getValue: (el: T) => TVal
): number => {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midVal = getValue(arr[mid]);
    if (midVal === target) {
      return mid; // Element found, return its index
    } else if (midVal < target) {
      left = mid + 1; // Search in the right half
    } else {
      right = mid - 1; // Search in the left half
    }
  }

  return -1;
};

export const findNearestIndexViaBinarySearch = <T, TVal>(
  arr: T[],
  target: TVal,
  getValue: (el: T) => TVal
): [number, number] => {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midVal = getValue(arr[mid]);
    if (midVal === target) {
      return [mid, mid]; // Element found, return its index
    } else if (midVal < target) {
      left = mid + 1; // Search in the right half
    } else {
      right = mid - 1; // Search in the left half
    }
  }

  return [right, left];
};

export const findNearestIndexForListArgsViaBinarySearch = <T>(
  arr: T[],
  target: T,
  getValues: (el: T) => any[]
) => {
  let left = 0;
  let right = arr.length - 1;
  const targetVals = getValues(target);

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midVals = getValues(arr[mid]);
    for (let i = 0; i < midVals.length; i += 1) {
      const midVal = midVals[i];
      const targetVal = targetVals[i];
      if (midVal === targetVal) {
        if (i < midVals.length - 1) continue;
        return [mid, mid]; // Element found, return its index
      } else if (midVal < targetVal) {
        left = mid + 1; // Search in the right half
        break;
      } else {
        right = mid - 1; // Search in the left half
        break;
      }
    }
  }

  return [right, left];
};

const getMaxDiffInMs = (date: Date) => {
  const diffInDays = (new Date().getTime() - date.getTime()) / (1000 * 3600 * 24);
  if (diffInDays < 0.2) return 1000 * 3600 * 0.5;
  if (diffInDays < 1) return 1000 * 3600 * 2;
  return 1000 * 3600 * 12;
};

type TimeGroup<T> = {
  newestDate: Date;
  oldestDate: Date;
  maxDiffInMs: number;
  items: T[];
};
export default function groupByDate<T>(opts: {items: T[]; extractDate: (item: T) => Date}) {
  const {items, extractDate} = opts;
  if (!items.length) return [];
  const groups: TimeGroup<T>[] = [];
  const itemsWithExtras = items.map((item) => ({item, date: extractDate(item)}));
  const firstItem = itemsWithExtras[0];
  let currentGroup: TimeGroup<T> = {
    newestDate: firstItem.date,
    oldestDate: firstItem.date,
    maxDiffInMs: getMaxDiffInMs(firstItem.date),
    items: [firstItem.item],
  };
  groups.push(currentGroup);
  itemsWithExtras.slice(1).forEach(({item, date}) => {
    const getDiff = () => {
      if (!currentGroup.oldestDate) return 0;
      if (!date) return 0;
      return currentGroup.oldestDate.getTime() - date.getTime();
    };
    const diffToPreviousGroupInMs = getDiff();
    if (diffToPreviousGroupInMs > currentGroup.maxDiffInMs) {
      currentGroup = {
        newestDate: date,
        oldestDate: date,
        maxDiffInMs: getMaxDiffInMs(date),
        items: [item],
      };
      groups.push(currentGroup);
    } else {
      currentGroup.oldestDate = date;
      currentGroup.items.push(item);
    }
  });
  return groups;
}

const emojiRegex = getEmojiRegex();

export const isEmoji = (str: string) => {
  if (!str) return true;
  const m = str.match(emojiRegex);
  return m ? m[0] === str : false;
};
