import type {CurrentSchemaVersion, SchemaVersion} from "../models/DbList";
import {getStorageKey, storageWrapper} from "../storage";
import {createSorter} from "../utils/sort-utils";

type Db = PouchDB.Database<any>;

const schemaVersionId = "schema-version";

const proxiedFns = new Set(["get", "allDocs", "remove", "put", "post"]);

export const getMigratedDb = <T extends Db>(
  db: T,
  targetSchemaVersion: CurrentSchemaVersion,
  onDone?: (realDb: T) => void
): T => {
  let done = false;
  const migrating = migrate(db, targetSchemaVersion).then(
    () => {
      done = true;
      onDone?.(db);
    },
    (err) => {
      console.error("Migration failed: ", err);
      onDone?.(db);
    }
  );
  return new Proxy(
    {},
    {
      get(target, property) {
        if (!done && proxiedFns.has(property as "get")) {
          return async (...args: any[]) => {
            // console.log("AWAIT MIGRATION");
            await migrating;
            // console.log("DONE AWAIT MIGRATION");
            return db[property as "get"](...(args as [any]));
          };
        } else {
          return db[property as "name"];
        }
      },
    }
  ) as any;
};

type SchemaRow = {_id: string; version: SchemaVersion};

const getExistingDbSchemaVersion = async (db: Db): Promise<null | SchemaVersion> => {
  const key = getStorageKey.dbSchema(db.name);
  const storageVal = storageWrapper.storageGet(key);
  if (storageVal) return storageVal;

  const result = await db
    .get(schemaVersionId)
    .then((r: SchemaRow) => r.version)
    .catch((e: any) => {
      if (e?.name === "not_found") return null;
      throw e;
    });
  storageWrapper.storageSet(key, result);
  return result;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _resetTo = async (db: Db, version: SchemaVersion) => {
  const schemaVersionRow = await db.get(schemaVersionId);
  let putResult = await db.put({...schemaVersionRow, version});
  schemaVersionRow._rev = putResult.rev;
  storageWrapper.storageSet(getStorageKey.dbSchema(db.name), version);
};

const migrate = async (db: Db, targetSchemaVersion: CurrentSchemaVersion) => {
  const existing = await getExistingDbSchemaVersion(db);
  // if (existing === "group:2024-01-02") {
  //   await _resetTo(db, "group:2023-12-23");
  //   console.log("RESET");
  //   return;
  // }
  if (existing === targetSchemaVersion) return;
  console.warn("MIGRATE", existing, "to", targetSchemaVersion);
  if (existing === null) {
    await setupNewDb(db, targetSchemaVersion);
    await db.put({_id: schemaVersionId, version: targetSchemaVersion});
    storageWrapper.storageSet(getStorageKey.dbSchema(db.name), targetSchemaVersion);
  } else {
    let currentVersion: SchemaVersion | null = existing;
    const schemaVersionRow = await db.get(schemaVersionId);
    while (currentVersion) {
      const nextVersion = await migrateToVersion(db, currentVersion);
      let putResult = await db.put({...schemaVersionRow, version: currentVersion});
      schemaVersionRow._rev = putResult.rev;
      storageWrapper.storageSet(getStorageKey.dbSchema(db.name), currentVersion);
      currentVersion = nextVersion;
    }
  }
};

const createDesignDoc = async (db: Db, name: string, mapFunction: string) => {
  const exist = await db.get(`_design/${name}`).catch(() => null);
  var ddoc = {
    _id: `_design/${name}`,
    views: {
      [name]: {map: mapFunction.toString()},
    },
  };
  await db.put({...ddoc, ...(exist ? {_rev: exist._rev} : {})});
  return ddoc;
};

const GROUP_INDECES = {
  sortIndex: async (db) =>
    createDesignDoc(
      db,
      "sort_index",
      `function(doc) {
        if (doc.type === "Category" && doc.sortIndex) {
          emit(doc.sortIndex)
        }
      }`
    ),
  pendingItem: async (db) =>
    createDesignDoc(
      db,
      "pending_item",
      `function(doc) {
        if (doc.type === "Item" && doc.done === false) {
          emit(doc.done)
        }
      }`
    ),
  lastDoneItem: async (db) =>
    createDesignDoc(
      db,
      "last_done_item",
      `function(doc) {
        if (doc.type === "Item" && doc.done) {
          emit(doc.lastDoneAt)
        }
      }`
    ),
} satisfies {[key: string]: (db: Db) => Promise<unknown>};

const addSortIndex = async (db: Db) => {
  await GROUP_INDECES.sortIndex(db);
  const docs = await db
    .allDocs({
      include_docs: true,
      startkey: "ctg:",
      endkey: "ctg:\uffff",
    })
    .then((result) => result.rows.map((row) => row.doc));
  if (docs.length > 0) {
    const sorter = createSorter();
    const sortIdxList = sorter.getMudder().mudder("", "", docs.length, undefined, docs.length + 20);
    await db.bulkDocs(docs.map((d, idx) => ({...d, sortIndex: `ctg:${sortIdxList[idx]}`})));
  }
};

const migrateToVersion = async (
  db: Db,
  schemaVersion: SchemaVersion
): Promise<SchemaVersion | null> => {
  switch (schemaVersion) {
    case "group:2023-12-22":
      return "group:2023-12-23";
    case "group:2023-12-23": {
      await addSortIndex(db);
      return "group:2024-01-02";
    }
    case "group:2024-01-02": {
      await GROUP_INDECES.pendingItem(db);
      await GROUP_INDECES.lastDoneItem(db);
      return null;
    }
    case "local:2023-12-22":
      return "local:2023-12-23";
    case "local:2023-12-23": {
      await addSortIndex(db);
      return null;
    }
    case "user:2023-12-22": {
      return null;
    }
  }
};

const setupNewDb = async (db: Db, schemaVersion: CurrentSchemaVersion) => {
  switch (schemaVersion) {
    case "group:2024-01-02":
    case "local:2023-12-23": {
      for (const index of Object.values(GROUP_INDECES)) {
        await index(db);
      }
      return;
    }
    default:
      break;
  }
};
