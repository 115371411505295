import {useEffect, useState} from "react";
import {registerSW} from "virtual:pwa-register";
import {create} from "zustand";
import Button from "./ui/Button";

const updateServiceWorker = registerSW({
  onOfflineReady: () => {
    // eslint-disable-next-line no-console
    console.log("can be used offline");
  },
  onNeedRefresh() {
    useServiceWorkerStore.getState().set({needsRefresh: true});
  },
});

type ServiceWorkerStoreState = {needsRefresh: boolean};

const useServiceWorkerStore = create<
  ServiceWorkerStoreState & {set: (state: ServiceWorkerStoreState) => void; updateFn: () => void}
>((set) => ({
  updateFn: updateServiceWorker,
  needsRefresh: false,
  set,
}));

export const UpdatePrompt = () => {
  const needsRefresh = useServiceWorkerStore((s) => s.needsRefresh);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (needsRefresh) setShow(true);
  }, [needsRefresh]);

  if (!show) return null;
  return (
    <div>
      <div>Neue Version verfügbar</div>
      <Button
        onClick={async () => {
          await updateServiceWorker();
          setShow(false);
        }}
      >
        Her damit!
      </Button>
    </div>
  );
};
