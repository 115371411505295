import {type ReactNode} from "react";
import {useLocation, useNavigate} from "react-router";
import {Box, Col, Row, Text} from "../../ui/Box";
import {ShowSyncIssue} from "../../features/auth/UserSyncSection";
import Icon from "../../ui/Icon";
import Button from "../../ui/Button";
import {SideDrawer} from "../../ui/SideDrawer";
import type {NoPrefixGroupId} from "../../models/GroupEntry";
import MainMenu from "./MainMenu";

const MainMenuIcon = ({currentGroupId}: {currentGroupId: NoPrefixGroupId | null}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isOpen = Boolean(location.state?.openMenu);
  const onClose = () => {
    if (!location.state?.openMenu) return;
    navigate(-1);
  };
  const onOpen = () => {
    navigate(
      {
        pathname: location.pathname,
        search: location.search,
        hash: location.hash,
      },
      {replace: false, state: {...location.state, openMenu: true}}
    );
  };

  return (
    <>
      <Button variant="tertiary" onClick={onOpen}>
        <Icon name="menu" />
      </Button>
      <SideDrawer open={isOpen} onClose={onClose}>
        <MainMenu currentGroupId={currentGroupId} />
      </SideDrawer>
    </>
  );
};

type HeaderProps = {
  title: ReactNode;
  backButton?: {to: string; label: ReactNode};
  actions?: ReactNode;
  skipChechSync?: boolean;
  currentGroupId: NoPrefixGroupId | null;
};
const Header = (props: HeaderProps) => {
  const {title, backButton, actions, skipChechSync, currentGroupId} = props;
  return (
    <Col>
      {!skipChechSync && <ShowSyncIssue />}
      <Box
        sp="8"
        display="grid"
        gridTemplateColumns="_header"
        surface
        py="8"
        px="12"
        elevation="200"
        align="center"
      >
        {backButton ? (
          <Row justify="start" align="center">
            <Button variant="tertiary" to={backButton.to}>
              <Icon name="chevron-left" inline /> {backButton.label}
            </Button>
          </Row>
        ) : (
          <Row justify="start" align="center">
            <MainMenuIcon currentGroupId={currentGroupId} />
          </Row>
        )}
        <Text bold as="h1" size="20" color="primary">
          {title}
        </Text>
        {actions ? (
          <Row justify="end" align="center" sp="8">
            {actions}
          </Row>
        ) : (
          <div />
        )}
      </Box>
    </Col>
  );
};

export default Header;
