import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import type {RouteObject} from "react-router-dom";
import {BrowserRouter, useRoutes} from "react-router-dom";
import {Trans} from "@lingui/macro";
import Hub from "./pages/lists/Hub";
import GroupRoot from "./pages/lists/GroupRoot";
import WithAuth from "./WithAuth";
import "./styles/global-styles.css";
import "./manageServiceWorker";
import VerifyEmail from "./pages/auth/VerifyEmail";
import ResetPassword from "./pages/auth/ResetPassword";
import {ShowMessages} from "./features/messenger/messenger";
import {I18n} from "./i18n";
import GroupInvite from "./pages/auth/GroupInvite";
import CategoryOverview from "./pages/lists/CategoryOverview";
import ItemOverview from "./pages/lists/ItemOverview";
import Profile from "./pages/profile/Profile";
import GroupSettings from "./pages/lists/GroupSettings";
import AppShell from "./shared/ui/AppShell";
import Spinner from "./shared/ui/Spinner";
import {Col} from "./ui/Box";
import Header from "./shared/ui/Header";
import GroupRecipes from "./pages/lists/GroupRecipes";

const routes: RouteObject[] = [
  {path: "/auth/verify-email/:token", element: <VerifyEmail />},
  {path: "/auth/reset-password/:token", element: <ResetPassword />},
  {path: "/group-invite/:token", element: <GroupInvite />},
  {
    path: "/",
    element: <WithAuth />,
    children: [
      {
        path: "/",
        element: <Hub />,
      },
      {
        path: "/g/:shortId",
        element: <GroupRoot />,
        children: [
          {
            path: "categories/*",
            element: <CategoryOverview />,
          },
          {
            path: "settings",
            element: <GroupSettings />,
          },
          {
            path: "cook",
            element: <GroupRecipes />,
          },
          {
            index: true,
            path: "*",
            element: <ItemOverview />,
          },
        ],
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "*",
        element: (
          <AppShell header={<Header title="404" currentGroupId={null} skipChechSync />}>
            <Col
              align="center"
              justify="center"
              textAlign="center"
              color="secondary"
              flex="auto"
              bold
              size="16"
            >
              <Trans>Not found</Trans>
            </Col>
          </AppShell>
        ),
      },
    ],
  },
];

const Routes = () => useRoutes(routes);

const LoadingFallback = () => (
  <AppShell>
    <Col align="center" justify="center" flex="auto">
      <Spinner size="48" />
    </Col>
  </AppShell>
);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Suspense fallback={<LoadingFallback />}>
      <I18n>
        <>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
          <ShowMessages />
        </>
      </I18n>
    </Suspense>
  </React.StrictMode>
);
